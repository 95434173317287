import { useFormContext } from 'react-hook-form';
import {
  EscalationFormTypes,
  EscalationFormTypesLabels,
  OutgoingFormTypes,
  OutgoingFormTypesLabels,
  SessionCallStatus,
  SessionCallTypes,
} from '../../../shared/constants/Session';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import FormSection from '../../components/formSection/FormSection';
import { Flex, Grid, IconButton, Label } from '../../styledComponents';
import { ChipButton } from '../../styledComponents/ChipButton';
import { GridItem } from '../../styledComponents/GridItem';
import { InputBase, InputContainer } from '../../styledComponents/Input';
import { useFormConfigsContext } from '../FormConfigsProvider/FormConfigsProvider';
import LabelWithAsterik from '../../components/LabelWithAsterik/LabelWithAsterik';

const CallInformation = () => {
  const { watch, setValue } = useFormContext<CaseNoteFormTypesV2>();
  const { isEditing } = useFormConfigsContext();

  const {
    callType,
    callStatus,
    callAttempts,
    escalationCallType,
    outgoingCallType,
  } = watch();

  return (
    <FormSection
      title="Call information"
      subtitle="Enter your client information to get started with the session"
      css={{ my: '0', zIndex: '$1' }}
      defaultOpen
    >
      <Grid columns={8} gap="2">
        <GridItem xs={2} sm={2} md={2} lg={2}>
          <Label htmlFor="organisation">
            <LabelWithAsterik label="Call status" />

            <Flex gap="2" wrap="wrap" css={{ marginTop: '$2' }}>
              <ChipButton
                key="call-status-not-answered"
                selected={callStatus === SessionCallStatus.NOT_ANSWERED}
                onClick={() =>
                  setValue('callStatus', SessionCallStatus.NOT_ANSWERED)
                }
                size="sm"
                disabled={isEditing}
              >
                Not answered
              </ChipButton>
              <ChipButton
                key="call-status-answered"
                selected={callStatus === SessionCallStatus.ANSWERED}
                onClick={() =>
                  setValue('callStatus', SessionCallStatus.ANSWERED)
                }
                size="sm"
                disabled={isEditing}
              >
                Answered
              </ChipButton>
            </Flex>
          </Label>
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          <Label htmlFor="organisation">
            No. of attempts
            <Flex gap="2" css={{ marginTop: '$2' }}>
              <IconButton
                size="sm"
                variant="outlined"
                onClick={() =>
                  setValue(
                    'callAttempts',
                    callAttempts && callAttempts > 1 ? callAttempts - 1 : 1,
                  )
                }
                disabled={callAttempts === 1}
              >
                -
              </IconButton>

              <InputContainer
                css={{
                  height: '28px',
                  width: '82px',
                  margin: 0,
                }}
              >
                <InputBase
                  defaultValue={callAttempts}
                  value={
                    callAttempts && callAttempts <= 9
                      ? `0${callAttempts}`
                      : callAttempts
                  }
                  css={{ textAlign: 'center', pointerEvents: 'none' }}
                />
              </InputContainer>
              <IconButton
                size="sm"
                variant="outlined"
                onClick={() =>
                  setValue('callAttempts', callAttempts ? callAttempts + 1 : 1)
                }
              >
                +
              </IconButton>
            </Flex>
          </Label>
        </GridItem>
        {callStatus === SessionCallStatus.ANSWERED ? (
          <>
            <GridItem xs={8} sm={8} md={8} lg={8}>
              <Label htmlFor="organisation">
                <LabelWithAsterik label="Escalation type" />
                <Flex gap="2" wrap="wrap" css={{ marginTop: '$2' }}>
                  {(
                    Object.entries(EscalationFormTypesLabels) as [
                      EscalationFormTypes,
                      string,
                    ][]
                  )?.map((type, idx) => {
                    const key = `escalation-type-${idx}`;

                    const [labelValue, labelKey] = type;

                    return (
                      <ChipButton
                        key={key}
                        selected={escalationCallType === labelValue}
                        onClick={() =>
                          setValue('escalationCallType', labelValue)
                        }
                        size="sm"
                      >
                        {labelKey}
                      </ChipButton>
                    );
                  })}
                </Flex>
              </Label>
            </GridItem>
            {callType === SessionCallTypes.OUTGOING_CALL ? (
              <GridItem xs={8} sm={8} md={8} lg={8}>
                <Label htmlFor="organisation">
                  <LabelWithAsterik label="Outgoing type" />
                  <Flex gap="2" wrap="wrap" css={{ marginTop: '$2' }}>
                    {(
                      Object.entries(OutgoingFormTypesLabels) as [
                        OutgoingFormTypes,
                        string,
                      ][]
                    )?.map((type, idx) => {
                      const key = `outgoing-type-${idx}`;

                      const [labelValue, labelKey] = type;

                      return (
                        <ChipButton
                          key={key}
                          selected={outgoingCallType === labelValue}
                          onClick={() =>
                            setValue('outgoingCallType', labelValue)
                          }
                          size="sm"
                        >
                          {labelKey}
                        </ChipButton>
                      );
                    })}
                  </Flex>
                </Label>
              </GridItem>
            ) : null}
          </>
        ) : null}
      </Grid>
    </FormSection>
  );
};

export default CallInformation;
