import { format } from 'date-fns';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PathConfig } from '../../../config/PathConfig';
import { RiskColorVariants, RiskLabels } from '../../../shared/constants/Risk';
import {
  SessionCallTypesColorVariants,
  SessionCallTypesLabels,
  SessionStatus,
  SessionStatusColorVariants,
  SessionStatusLabels,
} from '../../../shared/constants/Session';
import { SessionListItem } from '../../../shared/types/Session';
import { SessionsListResponse } from '../../../utilities/hooks/queryHooks/callLogs/useGetSessionsListV2';
import { useGetOrganisationsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import LoadingView from '../../components/loadingView/LoadingView';
import {
  Button,
  Table,
  Tag,
  Tbody,
  Td,
  Tooltip,
  Tr,
  Typography,
} from '../../styledComponents';

const TooltipTypography = ({ label }: { label: string }) => (
  <Tooltip label={label} selectable>
    <Typography
      css={{
        fontSize: 'small',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
    >
      {label}
    </Typography>
  </Tooltip>
);

export const CallLogList = ({
  sessionsData,
}: {
  sessionsData: SessionsListResponse;
}) => {
  const { data: orgsList, isLoading: isOrgsListLoading } =
    useGetOrganisationsList();

  const OrgNameMap = useMemo(
    () =>
      orgsList?.reduce((result: Record<number, string>, org) => {
        // eslint-disable-next-line no-param-reassign
        result[org.id] = org.name;

        return result;
      }, {}),
    [orgsList],
  );

  if (isOrgsListLoading || !orgsList) {
    return <LoadingView />;
  }

  return sessionsData.sessions.length ? (
    <Table>
      <Tbody>
        {sessionsData.sessions.map((item: SessionListItem) => (
          <Tr key={item.id}>
            <Td>
              <TooltipTypography
                label={
                  item?.anonymous
                    ? 'Anonymous'
                    : (item.client?.name ?? 'Anonymous')
                }
              />

              <Typography css={{ mt: '$1' }} size="xs" color="gray600">
                {OrgNameMap?.[
                  parseInt(item.client?.organisation || '-1', 10)
                ] ?? 'Organisation unavailable'}
              </Typography>
            </Td>
            <Td
              css={{
                fontSize: 'small',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <TooltipTypography
                label={item.client?.phone ? `+${item.client.phone}` : 'N/A'}
              />
            </Td>

            <Td>
              <TooltipTypography label={item.client?.email || 'N/A'} />
            </Td>

            <Td>{format(new Date(item.startTime), 'MMM dd, yyyy')}</Td>
            <Td>
              <Tag variant={RiskColorVariants[item.risk]}>
                {RiskLabels[item.risk]}
              </Tag>
            </Td>
            <Td>
              {item?.status ? (
                <Tag
                  variant={
                    SessionStatusColorVariants[item.status as SessionStatus]
                  }
                >
                  {SessionStatusLabels[item.status as SessionStatus]}
                </Tag>
              ) : (
                '-'
              )}
            </Td>
            <Td>
              {item.callType ? (
                <Tag variant={SessionCallTypesColorVariants[item.callType]}>
                  {SessionCallTypesLabels[item.callType]}
                </Tag>
              ) : (
                <Tag variant="lavender">Incoming call (OLD)</Tag>
              )}
            </Td>
            <Td align="end">
              <Link
                to={
                  item.callType
                    ? `${PathConfig.sessionDetailV2.base}/${item.id}`
                    : `/${PathConfig.callLogs}/${item.id}`
                }
              >
                <Button
                  id={`view-session-${item.id}`}
                  key={item.id}
                  variant="outlined"
                  size="md"
                  color="darkMetal"
                >
                  View
                </Button>
              </Link>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  ) : (
    <Typography size="md" css={{ fontWeight: 500 }}>
      No call logs were found.
    </Typography>
  );
};
