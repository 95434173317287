import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CaseNoteFormTypesV2 } from '../../../../shared/types/CaseNoteFormV2';
import { GenerateUserActivePlanSummary } from '../../../../utilities/common/Credits';
import { useClientCredits } from '../../../../utilities/hooks/queryHooks/client/UseClientCredits';
import { Flex, Typography } from '../../../styledComponents';
import CreditLineItem from './CreditLineItem';

const ClientCreditsCard = () => {
  const { watch } = useFormContext<CaseNoteFormTypesV2>();
  const { userId } = watch();

  // --------------- NETWORK CALLS ---------------
  const { data: credits } = useClientCredits(userId);

  // --------------- DATA SETTERS & LISTENERS ---------------
  const CreditSummary = useMemo(
    () => GenerateUserActivePlanSummary(credits ? Object.values(credits) : []),
    [credits],
  );

  if (!userId || userId === 'null') return null;

  return (
    <Flex direction="column" gap="2">
      <Typography size="md">Credits left</Typography>
      {CreditSummary?.map((credit, idx) => {
        const key = `user-${userId}-credit-line-${idx}`;
        return (
          <CreditLineItem
            key={key}
            label={credit?.label}
            value={credit?.credits}
          />
        );
      })}
    </Flex>
  );
};

export default ClientCreditsCard;
