export const PathConfig = {
  login: 'login',
  newCaseNote: 'new-case-note',
  callLogs: 'sessions',
  sessionDetail: '/sessions/:sessionId',
  sessionDetailV2: {
    base: '/sessions-v2',
    details: '/sessions-v2/:sessionId',
  },
  responder: '/responder',
  responderV2: {
    base: '/responder-v2',
    incomingCall: 'incoming-call',
    incomingCallEdit: 'incoming-call/:sessionId/edit',
    outgoingCall: 'outgoing-call',
    outgoingCallEdit: 'outgoing-call/:sessionId/edit',
    esclationsCheckings: 'escalations-checkins',
    esclationsCheckingsEdit: 'escalations-checkins/:sessionId/edit',
    cispCall: 'cisp-call',
    cispCallEdit: 'cisp-call/:sessionId/edit',
    testCall: 'test-call',
    testCallEdit: 'test-call/:sessionId/edit',
  },
  careNavigator: '/care-navigator',
  editSession: '/edit',
};
