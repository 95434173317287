import { IoCheckmarkOutline } from 'react-icons/io5';
import { OrgMessageStatuses } from '../../../../shared/constants/Session';
import { OrgMessage } from '../../../../shared/types/FetchByEmailUserData';
import { theme } from '../../../../stitches.config';
import { Box, Flex, Typography } from '../../../styledComponents';

export const OrgMessageItemV2 = ({
  email,
  status,
  orgName,
  onSelect,
}: {
  email?: string;
  status: OrgMessage['status'];
  orgName: string;
  onSelect: () => void;
}) => (
  <Box
    css={{
      width: '100%',
      backgroundColor: 'White',
      boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
      borderRadius: '$3',
    }}
  >
    <Typography css={{ p: '$2 $3', wordBreak: 'break-word' }}>
      {orgName === 'Did not disclose' ? (
        'User does not want to disclose organisation name'
      ) : (
        <>
          {OrgMessageStatuses[status]} - <strong>{orgName}</strong>
        </>
      )}
    </Typography>
    <Flex
      role="button"
      tabIndex={0}
      css={{
        p: '$2 $3',
        backgroundColor: status === 'NO_ORG' ? '#E1FAFF' : '#E1FFF1',
        borderBottomLeftRadius: '$3',
        borderBottomRightRadius: '$3',
        transition: 'all linear 0.1s',
        cursor: 'pointer',
        '&:hover': {
          filter: 'brightness(90%)',
        },
        maxWidth: '100%',
      }}
      gap="4"
      align="center"
      justify="between"
      onClick={() => onSelect()}
    >
      <Typography css={{ wordWrap: 'break-word', maxWidth: '80%' }}>
        {status === 'NO_ORG' ? `Create "${email}"` : email}
      </Typography>
      {status === 'NO_ORG' ? null : (
        <IoCheckmarkOutline color={theme.colors.darkGreen.value} size="18" />
      )}
    </Flex>
  </Box>
);
