import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PathConfig } from '../../../config/PathConfig';
import { SessionEvent } from '../../../shared/constants/events/SessionDetail';
import { QueryKeys } from '../../../shared/constants/QueryKeys';
import { useCreateComment } from '../../../utilities/hooks/queryHooks/session/UseCreateComment';
import { useGetSessionComments } from '../../../utilities/hooks/queryHooks/session/UseGetSessionComments';
import useAuth from '../../../utilities/hooks/UseAuth';

import useTracking from '../../../utilities/hooks/UseTracking';
import LoadingView from '../../components/loadingView/LoadingView';
import {
  Box,
  Button,
  Flex,
  TextArea,
  Typography,
} from '../../styledComponents';
import NotesCard from './NotesCard';

const Comments = ({
  showCommentButton,
  handleSuccess,
}: {
  showCommentButton: boolean;
  handleSuccess: () => void;
}) => {
  const [showCommentBox, setShowCommentBox] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>('');

  const { sessionId } = useParams();
  const { track } = useTracking<SessionEvent>();
  const { user } = useAuth();

  const {
    data: comments,
    isSuccess,
    isLoading,
    isRefetching,
  } = useGetSessionComments(sessionId);

  const queryClient = useQueryClient();

  const onCommentCreateSuccess = () => {
    track('save_comment', {
      eventAction: 'click',
      eventCategory: 'save_comment',
      eventLabel: 'save_comment',
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.getSessionComments, sessionId],
      exact: true,
    });
    setCommentText('');
    setShowCommentBox(false);
    handleSuccess();
  };

  const handleShowCommentBox = () => {
    track('add_comment', {
      eventAction: 'click',
      eventCategory: 'add_comment',
      eventLabel: 'add_comment',
    });
    setShowCommentBox(true);
  };

  const { isLoading: isCreateCommentLoading, mutate: mutateComment } =
    useCreateComment(onCommentCreateSuccess);

  return (
    <Box css={{ mt: '$6' }}>
      {isLoading || isRefetching ? (
        <LoadingView size="md" />
      ) : isSuccess && comments?.length ? (
        <Box css={{ px: '$1' }}>
          <Typography size="2xl" css={{ m: 0, p: 0 }}>
            Comments
          </Typography>
          <Box css={{ mt: '$2', px: '$1' }}>
            {comments?.map((comment) => {
              const key = `session-${sessionId}-comments`;
              console.log('inside comments');
              return <NotesCard caseNote={comment} key={key} />;
            })}
          </Box>
        </Box>
      ) : null}

      {showCommentBox ? (
        <Box>
          <TextArea
            size="lg"
            css={{ mb: '$2', minHeight: '10rem' }}
            placeholder="Add your comment here..."
            onChange={(event) => setCommentText(event.target.value)}
            value={commentText}
            maxLength={5000}
          />
          <Flex justify="end" gap="3">
            <Button
              id="hide-comment-box"
              onClick={() => {
                setShowCommentBox(false);
                setCommentText('');
              }}
              size="lg"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              id="confirm-add-comment"
              onClick={() =>
                mutateComment({
                  sessionId: sessionId ?? '',
                  note: commentText.trim(),
                })
              }
              size="lg"
              isLoading={isCreateCommentLoading}
              disabled={!commentText.trim()}
              loadingText="Submitting..."
            >
              Save
            </Button>
          </Flex>
        </Box>
      ) : (
        <Flex justify="end" css={{ mt: '$2' }} gap="3">
          {user.addOns?.isCareNavigator ? null : (
            <Link
              to={`/${PathConfig.callLogs}/${sessionId}${PathConfig.editSession}`}
              onClick={() =>
                track('view_detail', {
                  eventAction: 'click',
                  eventCategory: 'edit_information',
                  eventLabel: 'edit_information',
                })
              }
            >
              <Button id="edit-session-button" size="lg" variant="outlined">
                Edit information
              </Button>
            </Link>
          )}

          {showCommentButton ? (
            <Button
              id="show-comment-box"
              onClick={handleShowCommentBox}
              size="lg"
              variant="outlined"
            >
              Comment
            </Button>
          ) : null}
        </Flex>
      )}
    </Box>
  );
};

export default Comments;
