enum EnvType {
  Localhost = 'localhost',
  Staging = 'staging',
  UAT = 'uat',
  Production = 'production',
}

const EnvironmentVariables = {
  [EnvType.Localhost]: {
    baseUrl: 'http://localhost:5005/api/',
    talkyUrl: 'http://localhost:5001/api/',
    contentUrl: 'http://localhost:5000/api/',
    authUrl: 'http://localhost:5003/api/',
    imageServerUrl: 'https://d3imeoj536zhqj.cloudfront.net/images/',
    maverickURL: 'http://maverick.internal.intellect.co',
    providerTeamId: 4,
  },
  [EnvType.Staging]: {
    baseUrl: 'https://stage.internal.intellect.co/api/notebook/',
    talkyUrl: 'https://stage.internal.intellect.co/api/',
    contentUrl: 'https://stage.internal.intellect.co/api/',
    authUrl: 'https://stage.internal.intellect.co/api/',
    imageServerUrl: 'https://d3imeoj536zhqj.cloudfront.net/images/',
    maverickURL: 'http://maverick.internal.intellect.co',
    providerTeamId: 4,
  },
  [EnvType.UAT]: {
    baseUrl: 'https://internal.intellect.co/api/notebook/',
    talkyUrl: 'https://internal.intellect.co/api/',
    contentUrl: 'https://internal.intellect.co/api/',
    authUrl: 'https://internal.intellect.co/api/',
    imageServerUrl: 'https://d3imeoj536zhqj.cloudfront.net/images/',
    maverickURL: 'https://care.internal.intellect.co',
    providerTeamId: 89,
  },
  [EnvType.Production]: {
    baseUrl: 'https://internal.intellect.co/api/notebook/',
    talkyUrl: 'https://internal.intellect.co/api/',
    contentUrl: 'https://internal.intellect.co/api/',
    authUrl: 'https://internal.intellect.co/api/',
    imageServerUrl: 'https://d3imeoj536zhqj.cloudfront.net/images/',
    maverickURL: 'https://care.intellect.co',
    providerTeamId: 89,
  },
};
const { hostname } = window.location;
const Environment =
  EnvironmentVariables[
    hostname.includes('stage') ||
    hostname.includes('localhost') ||
    hostname.includes('127.0.0.1')
      ? EnvType.Staging
      : hostname.includes('.internal.')
        ? EnvType.UAT
        : EnvType.Production
  ];

const ApiConfig = {
  base: {
    notebook: Environment.baseUrl,
    auth: Environment.authUrl,
    talky: Environment.talkyUrl,
    content: Environment.contentUrl,
    maverick: Environment.maverickURL,
  },
  api: {
    auth: {
      login: `${Environment.authUrl}user/auth/provider/login`,
    },
    clients: {
      list: '/clients',
      add: '/clients',
      addV2: '/v2/clients',
      detail: '/clients/',
      getDetailFromNumberorEmail: '/clients?',
      getDetailFromEmail: '/clients/users/email',
      getDetailFromEmailV2: '/clients/user/email',
      getEmailSearchResults: '/clients/email?email=',
      getOrganisationsList: '/clients/organisations',
      getProviderConfig: `${Environment.talkyUrl}coach/config`,
      getClientRegions: `${Environment.talkyUrl}user/dependent/regions`,
      getCountryCities: '/clients/regions/{{countryCode}}/cities',
      clientECP: '/clients/user/ecp/{{userId}}',
      clientUserCredits: '/clients/user/credits/{{userId}}',
      clientUserNotes: '/clients/provider-notes/{{userId}}',
      clientUserRiskAssessments: '/clients/risk-assessments/{{userId}}',
      clientUserPresentingIssue: '/clients/presenting-issue/{{userId}}',
    },
    sessions: {
      list: '/sessions',
      getSessionData: '/meetings/',
      getSessionDataV2: '/sessions/',
      getAssessmentQuestions: '/sessions/questions',
      getSessionQuestonsByCallType: '/sessions/questions/{{callType}}',
      updateNote: '/notes/',
      add: '/sessions',
      update: '/sessions/',
      edit: '/sessions/{{sessionId}}/update',
      cancel: '/sessions/{{sessionId}}/cancel',
      addNote: '/sessions/{{sessionId}}/notes',
      addNoteBulk: 'v2/sessions/{{sessionId}}/notes',
      getNotes: '/sessions/{{sessionId}}/notes',
      getNoteAttachment:
        '/sessions/{{sessionId}}/notes/download-attachment/{{attachmentId}}',
      getProviderSessionDetails: `${Environment.talkyUrl}maverick/user-info/{{userId}}`,
      cancelMeeting: `${Environment.talkyUrl}meeting/{{meetingId}}/cancel`,
      getCispTypesList: '/sessions/cisp-types/list',
    },
    system: {
      supportRequest: '/system/support-request',
    },
  },
};

export default ApiConfig;
