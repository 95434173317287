import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NoOrganisationCode } from '../../../shared/constants/Session';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import { convertIntoComboboxOptions } from '../../../utilities/common/ConvertIntoOptions';
import { useGetOrganisationsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import FormSection from '../../components/formSection/FormSection';
import { Combobox, Flex, Label, Typography } from '../../styledComponents';
import { GenerateActivePlanSummary } from '../../../utilities/common/Credits';
import { theme } from '../../../stitches.config';
import { ReactComponent as RedirectIcon } from '../../../assets/images/redirect-icon.svg';
import { useFormConfigsContext } from '../FormConfigsProvider/FormConfigsProvider';
import LabelWithAsterik from '../../components/LabelWithAsterik/LabelWithAsterik';

const OrganisationDetails = ({
  defaultOpen = true,
}: {
  defaultOpen?: boolean;
}) => {
  const {
    control,
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useFormContext<CaseNoteFormTypesV2>();
  const { isEditing } = useFormConfigsContext();

  const { data: organisationsList, dataUpdatedAt } = useGetOrganisationsList();

  const orgId = watch('organisation');

  const SelectedOrg = watch('selectedOrgDetails');

  useEffect(() => {
    const organisation = organisationsList?.find((org) => org.id === orgId);
    if (organisation) {
      setValue('selectedOrgDetails', organisation);
      clearErrors('organisation');
    } else if (orgId && !organisation) {
      setValue('selectedOrgDetails', undefined);
      setValue('organisation', undefined);
      setError('organisation', {
        message:
          'The selected organization has no active plans, so you cannot proceed further. Please contact support for assistance.',
      });
    }
  }, [setValue, orgId, organisationsList, clearErrors, setError]);

  const CreditSummary = useMemo(
    () => GenerateActivePlanSummary(SelectedOrg?.credits ?? []),
    [SelectedOrg],
  );

  const eapNote = SelectedOrg?.eapNote?.[0];
  const eapNoteCheatSheet = SelectedOrg?.eapNoteCheatSheet?.[0];

  return (
    <FormSection
      title="Organisation details"
      subtitle="Enter your client information to get started with the session"
      css={{ my: '0', zIndex: '$4' }}
      defaultOpen={defaultOpen}
    >
      <Flex direction="column">
        <Label htmlFor="organisation">
          <LabelWithAsterik label="Organisation" />
          <Controller
            name="organisation"
            defaultValue={watch('organisation')}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Combobox
                key={`${dataUpdatedAt}-${watch('organisation')}`}
                options={convertIntoComboboxOptions(
                  organisationsList ?? [],
                  'name',
                  'id',
                )}
                selectedValue={value?.toString()}
                onOptionSelect={(newOption) =>
                  onChange(newOption ? parseInt(newOption, 10) : newOption)
                }
                disabled={
                  !!watch('id') ||
                  !organisationsList ||
                  watch('orgMessageHandlerActive') ||
                  isEditing
                }
                {...rest}
              />
            )}
            rules={{
              required: {
                value: true,
                message: 'This is required.',
              },
            }}
          />
          <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
            {errors.organisation?.message?.toString()}
          </Typography>
          {watch('organisation') === NoOrganisationCode && (
            <Typography color="darkRed" size="xs" css={{ mb: '$3' }}>
              Warning: A session cannot be booked for a user without an
              organisation.
            </Typography>
          )}
        </Label>

        {SelectedOrg && watch('organisation') !== NoOrganisationCode ? (
          <Flex
            direction="column"
            gap="4"
            css={{
              background: '#E5ECFC',
              border: '1px solid $gray150',
              br: '0.6rem',
              padding: '$4',
            }}
          >
            {eapNote && (
              <Typography
                size="md"
                css={{ color: '$gray750', lineHeight: '24px' }}
              >
                {eapNote || 'N/A'}
              </Typography>
            )}

            {eapNoteCheatSheet && (
              <Typography size="md" css={{ color: '$cyan' }}>
                <a // eslint-disable-line react/jsx-no-target-blank
                  id="eap-cheet-sheet-resource-link"
                  href={eapNoteCheatSheet || '#'}
                  target="_blank"
                  style={{
                    color: theme.colors.cyan.value,
                    fontWeight: 500,
                  }}
                >
                  <Flex align="center" gap="1">
                    Learn more about escalations and support <RedirectIcon />
                  </Flex>
                </a>
              </Typography>
            )}

            <Flex wrap="wrap" css={{ pl: 0 }}>
              {CreditSummary?.map((item, idx) => {
                const key = `org-${orgId}-credit-summary-${idx}`;
                const isLast = idx === CreditSummary.length - 1;

                return (
                  <Flex
                    gap="1"
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      '&::after': !isLast
                        ? {
                            content: '"|"',
                            color: 'gray',
                            display: 'inline-block',
                            mx: '$1',
                          }
                        : {},
                    }}
                    key={key}
                  >
                    <Typography css={{ fontWeight: 500 }}>
                      {item.credits}
                    </Typography>
                    <Typography color="gray">{item.label}</Typography>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </FormSection>
  );
};

export default OrganisationDetails;
