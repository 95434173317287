import { Flex, Typography } from '../../../styledComponents';

const CreditLineItem = ({
  label,
  value,
}: {
  label: string | JSX.Element;
  value: string | JSX.Element;
}) => (
  <Flex justify="between">
    <Typography size="sm" color="gray">
      {label}
    </Typography>
    <Typography size="sm" css={{ fontWeight: 600 }}>
      {value}
    </Typography>
  </Flex>
);

export default CreditLineItem;
