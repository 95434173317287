import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PathConfig } from '../../../config/PathConfig';
import { CaseNoteFormEvent } from '../../../shared/constants/events/CaseNoteForm';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import { useEditSessionV2 } from '../../../utilities/hooks/queryHooks/caseNotes/UseEditSession';
import useTracking from '../../../utilities/hooks/UseTracking';
import { ConfirmDiscardModal } from '../../screens/EditSession/ConfirmDiscardModal';
import { Box, Button, Flex } from '../../styledComponents';
import { useFormConfigsContext } from '../FormConfigsProvider/FormConfigsProvider';
import CancellationModalV2 from './CancellationModalV2';
import ConfirmationModalV2 from './ConfirmationModalV2';

const FormConfirmationV2 = () => {
  const {
    setValue,
    watch,
    formState: { isDirty },
  } = useFormContext<CaseNoteFormTypesV2>();
  const {
    isEditing,
    setIsConfirmationModalShowing,
    confirmButtonEnabled,
    updateButtonEnabled,
  } = useFormConfigsContext();

  const navigate = useNavigate();

  const { track } = useTracking<CaseNoteFormEvent>();
  const { mutate: editSession, isLoading: isEditSessionLoading } =
    useEditSessionV2();

  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [showCancellationModal, setShowCancellationModal] =
    useState<boolean>(false);
  const [showConfirmDiscardModal, setShowConfirmDiscardModal] = useState(false);

  useEffect(() => {
    const anyModalShowing =
      showConfirmationModal || showCancellationModal || false;

    setIsConfirmationModalShowing?.(anyModalShowing);
  }, [
    showConfirmationModal,
    showCancellationModal,
    setValue,
    setIsConfirmationModalShowing,
  ]);

  const handleOpenConfirmationModal = () => {
    setValue('endTime', new Date());
    track('mark_complete', {
      eventAction: 'click',
      eventCategory: 'mark_complete',
      eventLabel: 'mark_complete',
    });
    setShowConfirmationModal(true);
  };

  const handleOpenCancellationModal = () => {
    track('cancel_session', {
      eventAction: 'click',
      eventCategory: 'cancel_session',
      eventLabel: 'cancel_session',
    });

    setShowCancellationModal(true);
  };

  const handleEditSessionGoBack = () => {
    if (isDirty) {
      setShowConfirmDiscardModal(true);
    } else {
      navigate(`/${PathConfig.sessionDetailV2.base}/${watch('sessionId')}`);
    }
  };

  const handleEditSessionSubmit = () => {
    editSession(watch());
  };

  const handleEditConfirationClose = () => {
    setShowConfirmDiscardModal(false);
  };

  const handleEditConfirmationDiscard = () => {
    navigate(`/${PathConfig.sessionDetailV2.base}/${watch('sessionId')}`);
  };

  return (
    <>
      <Box css={{ zIndex: '$1' }}>
        {!isEditing ? (
          <Flex
            direction="column"
            align="center"
            css={{
              width: '100%',
              backgroundColor: 'white',
            }}
            gap="2"
          >
            <Button
              id="show-confirmation-modal"
              size="lg"
              css={{
                minWidth: '20rem',
                maxWidth: '40rem',
                width: '100%',
              }}
              onClick={handleOpenConfirmationModal}
              disabled={!confirmButtonEnabled}
            >
              Mark as complete
            </Button>
            <Button
              id="show-cancellation-modal"
              size="lg"
              variant="ghost"
              css={{ minWidth: '20rem', maxWidth: '40rem', width: '100%' }}
              disabled={!watch('phone')}
              onClick={handleOpenCancellationModal}
            >
              Cancel session
            </Button>
          </Flex>
        ) : (
          <Flex justify="between" css={{ mt: '$6' }} gap="3">
            <Button
              type="button"
              id="edit-session-button"
              onClick={handleEditSessionGoBack}
              size="lg"
              variant="outlined"
            >
              Cancel and Exit
            </Button>
            <Button
              type="button"
              isLoading={isEditSessionLoading}
              onClick={handleEditSessionSubmit}
              size="lg"
              disabled={!updateButtonEnabled}
            >
              Save Information
            </Button>
          </Flex>
        )}
        {/* <CancellationModal
        open={showCancellationModal}
        handleClose={() => setShowCancellationModal(false)}
      /> */}
      </Box>
      <Box css={{ zIndex: '$5' }}>
        <CancellationModalV2
          open={showCancellationModal}
          handleClose={() => setShowCancellationModal(false)}
        />
        <ConfirmationModalV2
          open={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
        />
        <ConfirmDiscardModal
          open={showConfirmDiscardModal}
          handleClose={handleEditConfirationClose}
          handleSubmit={handleEditConfirmationDiscard}
        />
      </Box>
    </>
  );
};

export default FormConfirmationV2;
