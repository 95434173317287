import { usePreventNavigation } from '../../../utilities/hooks/UsePreventNavigation';
import DiscardUnsavedChangesModal from '../../components/DiscardUnsavedChangesModal/DiscardUnsavedChangesModal';
import { Box } from '../../styledComponents';

const PreventNavigationWrapper = ({
  defaultValues,
}: {
  defaultValues: Record<string, any>;
}) => {
  const { showDraftModal, setShowDraftModal, handleDiscardChanges } =
    usePreventNavigation(defaultValues);

  return (
    <Box css={{ zIndex: '$max' }}>
      <DiscardUnsavedChangesModal
        open={showDraftModal}
        handleClose={() => setShowDraftModal(false)}
        handleDiscard={() => handleDiscardChanges()}
      />
    </Box>
  );
};

export default PreventNavigationWrapper;
