import {
  RiskColorsForTypography,
  RiskLabels,
} from '../../../../shared/constants/Risk';
import { SessionListItem } from '../../../../shared/types/Session';
import { getDisplayDateTime } from '../../../../utilities/common/Date';
import InfoCard from '../../../components/InfoCard/InfoCard';
import UnOrderedList from '../../../components/UnOrderedList/UnOrderedList';
import { Box, Tooltip, Typography } from '../../../styledComponents';
import { PathConfig } from '../../../../config/PathConfig';

const CallLogInfoCard = ({ session }: { session: SessionListItem }) => {
  const clientName =
    session?.anonymous || !session?.client?.name
      ? 'Anonymous'
      : session?.client?.name;

  return (
    <Tooltip label="Click to view details">
      <Box>
        <InfoCard
          content={
            <UnOrderedList
              items={[
                { label: clientName },
                {
                  label: (
                    <Typography
                      size="xs"
                      css={{
                        color: RiskColorsForTypography[session.risk],
                        fontWeight: 600,
                      }}
                    >
                      {RiskLabels[session.risk]}
                    </Typography>
                  ),
                },
              ]}
              liCss={{ maxWidth: '50%' }}
            />
          }
          subContent={
            session?.startTime
              ? getDisplayDateTime(new Date(session?.startTime))
              : ''
          }
          clickable
          onClick={() =>
            window.open(`${PathConfig.sessionDetailV2.base}/${session.id}`)
          }
        />
      </Box>
    </Tooltip>
  );
};

export default CallLogInfoCard;
