import { useQueryClient, useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doPatch } from '../../../../shared/service/NetworkClient';
import {
  AssessmentPayload,
  CreateBulkCaseNotesPayloadType,
} from '../../../../shared/types/Session';
import { useCreateBulkNotes, useCreateNoteV2 } from './UseCreateNoteV2';
import {
  CaseNoteTypes,
  EscalationFormTypes,
  OutgoingFormTypes,
  SessionCallStatus,
  TestFormTypes,
} from '../../../../shared/constants/Session';

export const useUpdateSession = (onSuccess?: () => void) => {
  const createNote = useCreateNoteV2();

  const queryClient = useQueryClient();

  return useMutation(
    (body: {
      sessionId: string;
      clientId?: string;
      startTime?: string;
      endTime?: string;
      risk?: number;
      caseNotes: string;
      issue?: string;
      assessment?: Omit<AssessmentPayload, 'isRequired'>[];
      meetingConsent?: 'yes' | 'no';
      sessionType: '0' | '1';
      status: '0' | '1' | '2';
      file?: File;
    }) => {
      const { sessionId, file, ...rest } = body;
      return doPatch(ApiConfig.api.sessions.update + sessionId, rest).then(
        (res) => res.data.data,
      );
    },
    {
      onSuccess: (_data, { caseNotes, sessionId, file }) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.getSessionData, sessionId],
        });
        createNote.mutate({
          note: caseNotes,
          sessionId,
          file,
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onSuccess && onSuccess();
      },
      onError: () => {
        toast.error('An error occurred while completing this session.');
      },
    },
  );
};

export const useUpdateSessionV2 = ({
  onSuccess,
  skipNoteCreation = false,
}: {
  onSuccess?: () => void;
  skipNoteCreation?: boolean;
}) => {
  const createBlukNote = useCreateBulkNotes();

  const queryClient = useQueryClient();

  return useMutation(
    (body: {
      sessionId: string;
      clientId?: string;
      startTime?: string;
      endTime?: string;
      risk?: number;
      callReason: string;
      callDescription: string;
      rangerIntervention: string;
      caseNotes: string;
      issue?: string;
      secondaryIssue?: string[]; // used in V2 Forms
      assessment?: Omit<AssessmentPayload, 'isRequired'>[];
      meetingConsent?: 'yes' | 'no';
      sessionType: '0' | '1';
      status: '0' | '1' | '2';
      file?: File;
      callStatus?: SessionCallStatus;
      callAttempts?: number;
      escalationCallType?: EscalationFormTypes;
      outgoingCallType?: OutgoingFormTypes;
      cispCallType?: string;
      clientPosition?: string;
      testCallType?: TestFormTypes;
    }) => {
      const { sessionId, file, ...rest } = body;
      return doPatch(ApiConfig.api.sessions.update + sessionId, rest).then(
        (res) => res.data.data,
      );
    },
    {
      onSuccess: (
        _data,
        {
          callDescription,
          callReason,
          rangerIntervention,
          caseNotes,
          sessionId,
        },
      ) => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.getSessionData, sessionId],
        });
        if (!skipNoteCreation) {
          const payload: { notes: CreateBulkCaseNotesPayloadType[] } = {
            notes: [],
          };

          if (callReason) {
            payload.notes.push({
              note: callReason.trim(),
              noteType: CaseNoteTypes.CALL_REASON,
            });
          }

          if (callDescription) {
            payload.notes.push({
              note: callDescription.trim(),
              noteType: CaseNoteTypes.CALL_DESCRIPTION,
            });
          }

          if (rangerIntervention) {
            payload.notes.push({
              note: rangerIntervention.trim(),
              noteType: CaseNoteTypes.RANGER_INTERVENTION,
            });
          }

          if (caseNotes) {
            payload.notes.push({
              note: caseNotes.trim(),
              noteType: CaseNoteTypes.NOTE,
            });
          }

          if (payload?.notes?.length) {
            createBlukNote.mutate({
              sessionId,
              payload,
            });
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        onSuccess && onSuccess();
      },
      onError: () => {
        toast.error('An error occurred while completing this session.');
      },
    },
  );
};
