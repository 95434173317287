import { CSS } from '@stitches/react';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { IoArrowDown, IoArrowUp } from 'react-icons/io5';
import { Box, Button, Flex, Heading } from '../../styledComponents';
import FormSectionAnimatedBox from './FormSectionAnimatedBox';
import MotionBox from '../MotionBox/MotionBox';

const FormSection = ({
  title,
  subtitle,
  children,
  defaultOpen = false,
  statusTag,
  css,
}: {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  statusTag?: JSX.Element;
  css?: CSS;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  return (
    <MotionBox
      css={{
        my: '$5',
        br: '0.5rem',
        p: '$4',
        border: '1px solid $gray200',
        ...(css ?? {}),
      }}
    >
      <Flex justify="between">
        <Box>
          <Flex gap="3" align="start">
            <Heading size="md" css={{ mb: '$2' }}>
              {title}
            </Heading>
            {statusTag}
          </Flex>
          <Heading size="sm" color="gray500" css={{ fontWeight: 'normal' }}>
            {subtitle}
          </Heading>
        </Box>
        <Button
          id={`expand-collapse-${title}`}
          size="sm"
          type="button"
          variant="ghost"
          css={{ backgroundColor: '$gray100', p: '$1 $2' }}
          onClick={() => setIsOpen(!isOpen)}
          endIcon={isOpen ? <IoArrowUp /> : <IoArrowDown />}
        >
          {isOpen ? 'COLLAPSE' : 'EXPAND'}
        </Button>
      </Flex>
      <AnimatePresence>
        {isOpen && <FormSectionAnimatedBox>{children}</FormSectionAnimatedBox>}
      </AnimatePresence>
    </MotionBox>
  );
};

export default FormSection;
