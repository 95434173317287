import { CSS, styled } from '@stitches/react';

const List = styled('ul', {
  fontSize: '$1',
  color: '$gray750',
  fontWeight: '500',
  display: 'inline-flex',
  width: '100%',
  listStyleType: 'disc',
  padding: 0,
  margin: 0,
});

const ListItem = styled('li', {
  listStyleType: 'none',
});

const ListSection = styled('section', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
});

const ItemContent = styled('section', {
  display: 'flex',
  alignItems: 'center',
  gap: '$1',
  minWidth: 0,
});

const Truncate = styled('section', {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const Icon = styled('span', {
  color: '$gray750',
});

export interface UnorderedListProps {
  items: {
    label: string | JSX.Element;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
  }[];
  ulCss?: CSS;
  liCss?: CSS;
}

const UnOrderedList = ({ items, ulCss, liCss }: UnorderedListProps) => (
  <List css={ulCss}>
    {items.map(({ label, startIcon, endIcon }, idx) => {
      const key = `custom-ul-${idx}`;

      return (
        <ListItem key={key} css={liCss}>
          <ListSection>
            {idx !== 0 ? <Icon>・</Icon> : null}
            <ItemContent>
              {startIcon}
              <Truncate>{label}</Truncate>
              {endIcon}
            </ItemContent>
          </ListSection>
        </ListItem>
      );
    })}
  </List>
);

export default UnOrderedList;
