import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { doPost } from '../../../../shared/service/NetworkClient';
import useAuth from '../../UseAuth';
import { SessionCallTypes } from '../../../../shared/constants/Session';

export const useCreateSession = (onSuccess: (sessionId: string) => void) => {
  const { user } = useAuth();
  return useMutation(
    ({
      clientId,
      startTime,
      callType,
    }: {
      clientId: string;
      startTime: Date;
      callType?: SessionCallTypes;
    }) =>
      doPost(ApiConfig.api.sessions.add, {
        clientId,
        startTime: startTime.toISOString(),
        sessionType: user?.addOns?.isCareNavigator ? '1' : '0',
        callType,
      }).then((res) => res.data.data),
    {
      onSuccess,
      onError: () => {
        toast.error('An error occurred while creating the session.');
      },
    },
  );
};
