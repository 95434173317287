import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  CallFilterType,
  CallLogsFilterTypes,
  RiskFilterType,
} from '../../../shared/types/CallLogs';
import { useGetSessionsListV2 } from '../../../utilities/hooks/queryHooks/callLogs/useGetSessionsListV2';
import useDebounce from '../../../utilities/hooks/UseDebounce';
import {
  convertQueryStringToArray,
  encodeQueryStrings,
  useQueryString,
} from '../../../utilities/hooks/UseQueryString';
import Container from '../../components/container/Container';
import LoadingView from '../../components/loadingView/LoadingView';
import { Heading } from '../../styledComponents';
import { CallLogFilters } from './CallLogFilters';
import { CallLogList } from './CallLogList';

const CallLogs = () => {
  const queryString = useQueryString({
    arrayFormat: 'separator',
    arrayFormatSeparator: ',',
  });

  const navigate = useNavigate();

  const methods = useForm<CallLogsFilterTypes>({
    defaultValues: {
      organisation: queryString?.organisation
        ? +queryString.organisation
        : null,
      anonymous: queryString?.anonymous === '1',
      email: queryString?.email?.toString() || '',
      phone: queryString?.phone?.toString() || '',
      risk: queryString?.risk
        ? (convertQueryStringToArray(queryString?.risk)?.map(
            (item) => +item,
          ) as unknown as RiskFilterType)
        : null,
      page: +(queryString?.page ?? 1),
      pageSize: +(queryString?.pageSize ?? 20),
      callType:
        queryString?.callType && queryString?.callType !== 'all'
          ? +(queryString?.callType as CallFilterType)
          : 'all',
    },
    mode: 'onTouched',
  });

  const { callType, phone, page, pageSize, organisation, risk, anonymous } =
    methods.watch();

  const callTypeQuery = callType !== 'all' ? callType : undefined;

  const debouncedEmail = useDebounce(methods.watch('email'), 500);
  const debouncedPhone = useDebounce(
    phone && phone?.length >= 8 ? phone : '',
    700,
  );

  const { data, isLoading: isSessionsListLoading } = useGetSessionsListV2({
    risk,
    organisation,
    pageSize,
    page,
    email: debouncedEmail,
    phone: debouncedPhone,
    callType: callTypeQuery,
    anonymous,
  });

  useEffect(() => {
    const params = {
      email: debouncedEmail,
      phone: debouncedPhone,
      ...(callType ? { callType } : {}),
      page,
      pageSize,
      ...(risk ? { risk: risk as any } : {}),
      ...(organisation ? { organisation } : {}),
      anonymous: anonymous ? 1 : 0,
    };
    navigate({ search: encodeQueryStrings(params) }, { replace: true });
  }, [
    callType,
    debouncedEmail,
    debouncedPhone,
    page,
    pageSize,
    risk,
    organisation,
    anonymous,
    navigate,
  ]);

  return (
    <FormProvider {...methods}>
      <Container>
        <Heading size="xl" css={{ fontWeight: '600', mb: '$5' }}>
          Call logs
        </Heading>

        <CallLogFilters sessionsData={data} />

        {isSessionsListLoading || !data ? (
          <LoadingView />
        ) : (
          <CallLogList sessionsData={data} />
        )}
      </Container>
    </FormProvider>
  );
};

export default CallLogs;
