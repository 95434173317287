import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CaseNoteFormTypesV2 } from '../../shared/types/CaseNoteFormV2';
import {
  domainReplacementRegex,
  emailRegex,
  validDomainRegex,
} from '../common/FormValidatorRegex';

/**
 * Common hook for validating email domains
 * @returns
 */
export const useEmailDomainValidator = () => {
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { touchedFields },
  } = useFormContext<CaseNoteFormTypesV2>();
  const [selectedDomain, setSelectedDomain] = useState<string | null>(null);

  const { selectedOrgDetails, email, organisation, dependentId } = watch();

  // Filtering out invalid domains
  const ValidOrgDomains = useMemo(
    () => selectedOrgDetails?.domain?.filter((dm) => validDomainRegex.test(dm)),
    [selectedOrgDetails],
  );

  const validateEmail = useCallback(
    (touched = false) => {
      if (!email) {
        if (touchedFields.email || touched) {
          setError('email', {
            message: 'This is required.',
          });
        }
        return;
      }
      if (!emailRegex.test(email)) {
        setError('email', {
          message: 'Please enter a valid email.',
        });
      } else {
        clearErrors('email');
      }

      // if org is not having any restriction then return
      if (!ValidOrgDomains?.length) return;

      // if searched user is dependent then skip domain check
      if (dependentId) return;

      const findMatchingDomain = ValidOrgDomains?.find((domain) => {
        const regex = new RegExp(`${domain}$`, 'i');
        return regex.test(email);
      });
      if (findMatchingDomain) {
        setSelectedDomain(findMatchingDomain);
        clearErrors('email');
      } else {
        setSelectedDomain(null);
        if (emailRegex.test(email)) {
          setError('email', {
            message:
              'Domain not supported by organisation, please enter supported domain only',
          });
        }
      }
    },
    [
      email,
      ValidOrgDomains,
      dependentId,
      touchedFields.email,
      setError,
      clearErrors,
    ],
  );

  useEffect(() => {
    validateEmail();
  }, [validateEmail, organisation, ValidOrgDomains]);

  const changeEmailDomain = useCallback(
    (domain: string) => {
      if (!domain || !email || !email.includes('@')) {
        return;
      }

      const updatedEmail = email.replace(domainReplacementRegex, `@${domain}`);

      setSelectedDomain(domain);

      if (updatedEmail !== email) {
        setValue('email', updatedEmail, { shouldValidate: true });
      }
    },
    [email, setValue, setSelectedDomain],
  );

  return { selectedDomain, changeEmailDomain, validateEmail, ValidOrgDomains };
};
