import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { NoOrganisationCode } from '../../../../shared/constants/Session';
import { doGet } from '../../../../shared/service/NetworkClient';
import { ClientInfoV2 } from '../../../../shared/types/Client';
import { getOrganisation } from '../../../common/FormValidatorRegex';

export const useGetUserFromNumber = ({
  phone,
  organisation,
  onComplete,
  retry,
  onError,
}: {
  phone: string;
  organisation: number | undefined;
  onComplete: (data: ClientInfoV2) => void;
  retry?: boolean;
  onError?: () => void;
}) =>
  useQuery<ClientInfoV2>(
    [QueryKeys.getUserFromNotebook, phone],
    () =>
      doGet(
        `${ApiConfig.api.clients.getDetailFromNumberorEmail}phone=${phone}${
          getOrganisation(organisation) ? `&organisation=${organisation}` : ''
        }`,
      ).then((res) => res.data.data),
    {
      select: (data) => ({
        ...data,
        organisation: Number(data.organisation) || NoOrganisationCode,
        userId: String(data.userId),
      }),
      enabled: false,
      onSuccess: onComplete,
      onError: () => {
        toast.error(
          `No client with that phone number ${organisation ? 'and organisation' : ''} exists. Please try again.`,
        );
        onError?.();
      },
      retry,
    },
  );

/**
 * This hook searches in the system by email to check whether user has called with another phone
 * @param param0
 * @returns
 */
export const useSearchUserDetailsByEmail = ({
  email,
  onComplete,
  retry,
}: {
  email: string;
  onComplete?: (data: ClientInfoV2) => void;
  retry?: boolean;
}) =>
  useQuery<ClientInfoV2>(
    [QueryKeys.getUserFromNotebookByEmail, email],
    () =>
      doGet(
        ApiConfig.api.clients.getDetailFromNumberorEmail,
        {},
        { email },
      ).then((res) => res.data.data),
    {
      select: (data) => ({
        ...data,
        organisation: Number(data.organisation) || NoOrganisationCode,
        userId: String(data.userId),
      }),
      enabled: false,
      onSuccess: onComplete,
      retry,
    },
  );

/**
 * This hook is to search client in all the orgs to check whether selected org is different than the stored one
 * @param param0
 * @returns
 */
export const useSearchUserDetailsByPhone = ({
  phone,
  onComplete,
  retry,
  onError,
}: {
  phone: string;
  onComplete: (data: ClientInfoV2) => void;
  retry?: boolean;
  onError?: () => void;
}) =>
  useQuery<ClientInfoV2>(
    [QueryKeys.searchClientWithinAllOrgs, phone],
    () =>
      doGet(
        `${ApiConfig.api.clients.getDetailFromNumberorEmail}phone=${phone}`,
      ).then((res) => res.data.data),
    {
      select: (data) => ({
        ...data,
        organisation: Number(data.organisation) || NoOrganisationCode,
        userId: String(data.userId),
      }),
      enabled: false,
      onSuccess: onComplete,
      onError: () => {
        onError?.();
      },
      retry,
    },
  );
