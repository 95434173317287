import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IoArrowForward } from 'react-icons/io5';
import { AgeOptions, TenureOptions } from '../../../shared/constants/Session';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import { ComboboxOption } from '../../../shared/types/SelectTypes';
import { convertIntoOptions } from '../../../utilities/common/ConvertIntoOptions';
import {
  emailRegex,
  noWhiteSpaceRegex,
} from '../../../utilities/common/FormValidatorRegex';
import { redirectToMaverick } from '../../../utilities/common/Redirection';
import {
  useGetCountryCities,
  useGetRegionsList,
} from '../../../utilities/hooks/queryHooks/caseNotes/UseGetRegionsList';
import { useUpdateSessionV2 } from '../../../utilities/hooks/queryHooks/caseNotes/UseUpdateSession';
import { useCreateClientAndSession } from '../../../utilities/hooks/queryHooks/client/UseCreateClient';
import { useEmailDomainValidator } from '../../../utilities/hooks/UseEmailDomainValidator';
import FormSectionAnimatedBox from '../../components/formSection/FormSectionAnimatedBox';
import LabelWithAsterik from '../../components/LabelWithAsterik/LabelWithAsterik';
import MotionBox from '../../components/MotionBox/MotionBox';
import {
  Box,
  Button,
  Checkbox,
  Combobox,
  Flex,
  Grid,
  Heading,
  Input,
  Label,
  Select,
  Switch,
  Tooltip,
  Typography,
} from '../../styledComponents';
import { GridItem } from '../../styledComponents/GridItem';
import EmergencyContactsInline from '../ClientDetails/EmergencyContacts/EmergencyContactsInline';
import { useFormConfigsContext } from '../FormConfigsProvider/FormConfigsProvider';

const FollowUpSessionDetails = () => {
  const {
    register,
    control,
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<CaseNoteFormTypesV2>();
  const { isEditing, bookNowButtonEnabled, bookNowButtonTooltipLabel } =
    useFormConfigsContext();

  const {
    id,
    startTime,
    sessionId,
    tenure,
    age,
    location,
    city,
    landmark,
    orgAnonymous,
    email,
    name,
    userId,
    employeeId,
    orgMessageHandlerActive,
    orgSelected,
    dependentId,
  } = watch();
  const { changeEmailDomain, validateEmail, selectedDomain, ValidOrgDomains } =
    useEmailDomainValidator();

  const { data: regionsList } = useGetRegionsList({
    keepDidNotDisclose: false,
  });
  const { data: citiesList } = useGetCountryCities(location ?? '');

  const { isLoading: isUpdateSessionLoading, mutate: mutateUpdateSession } =
    useUpdateSessionV2({
      onSuccess: () => {
        redirectToMaverick({
          id: getValues('userId'),
          email: getValues('email'),
          sessionId,
          phoneNumber: getValues('phone'),
          name: getValues('name') ?? '',
          location: getValues('location') ?? '',
        });
      },
      skipNoteCreation: true,
    });

  const {
    isLoading: isClientAndSessionCreateLoading,
    mutate: mutateClientAndSession,
  } = useCreateClientAndSession({
    onSessionCreateSuccess: (sessionIdFromResponse) =>
      mutateUpdateSession({
        sessionId: sessionIdFromResponse,
        clientId: id,
        startTime: startTime.toISOString(),
        issue: watch('issue'),
        secondaryIssue: watch('secondaryIssue'),
        risk: watch('risk'),
        callReason: '',
        callDescription: '',
        rangerIntervention: '',
        caseNotes: '',
        sessionType: '0',
        status: '0',
      }),
    sessionCallType: watch('callType'),
    apiVersion: 2,
  });

  useEffect(() => {
    if (age && age !== 'NA') {
      const numAge = parseInt(age, 10);
      const regionToFind = regionsList?.find(
        (region) => region.region === location,
      );

      if (regionToFind) {
        if (numAge >= regionToFind.ageMin && numAge <= regionToFind.ageMax) {
          setValue('isMinor', true);
        } else if (watch('isMinor')) {
          setValue('isMinor', false);
        }
      }
    }
  }, [age, location, regionsList, setValue, watch]);

  const onSessionBookClick = () => {
    const { sessionId: sessionIdLatest, phone } = getValues();

    if (userId && email && id && sessionId) {
      mutateUpdateSession({
        sessionId: sessionIdLatest,
        clientId: id,
        startTime: startTime.toISOString(),
        issue: watch('issue'),
        secondaryIssue: watch('secondaryIssue'),
        risk: watch('risk'),
        callReason: '',
        callDescription: '',
        rangerIntervention: '',
        caseNotes: '', // TODO
        sessionType: '0',
        status: '0',
      });
    } else {
      // User does not exist, will create client and session
      const { organisation } = getValues();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const nonNullValues = Object.fromEntries(
        Object.entries({
          name,
          email,
          phone,
          location,
          city,
          landmark,
          organisation,
          tenure,
          age,
          employeeId,
        }).filter(([_, v]) => !!v),
      );

      mutateClientAndSession(nonNullValues);
    }
  };

  const handleSessionBookClick = () => {
    // TODO tracking
    onSessionBookClick();
  };

  const CitiesOptions = (citiesList ?? [])?.map((item) => ({
    label: item?.city,
    value: item?.city,
  })) as ComboboxOption[];

  const isOrgMemberCreated = Boolean(id && userId && userId !== 'null');

  return (
    <MotionBox
      css={{
        my: '$5',
        br: '0.5rem',
        p: '$4',
        border: '1px solid $gray200',
        zIndex: '$1',
      }}
    >
      <Flex justify="between">
        <Box>
          <Flex gap="3" align="start">
            <Heading size="md" css={{ mb: '$2' }}>
              Follow up session
            </Heading>
          </Flex>
          <Heading size="sm" color="gray500" css={{ fontWeight: 'normal' }}>
            Enter your client information to get started with the booking a
            session for them
          </Heading>
        </Box>
        <Flex direction="column" align="end" justify="end" gap="2">
          <Typography size="md" color="gray">
            Caller wants a follow up session?
          </Typography>
          <Switch
            checked={watch('followUpSession')}
            onCheckedChange={(newValue) =>
              setValue('followUpSession', newValue)
            }
          />
        </Flex>
      </Flex>

      <AnimatePresence>
        {watch('followUpSession') && (
          <FormSectionAnimatedBox>
            <Grid columns={8} gapX="4">
              <GridItem xs={8} sm={8} md={8} lg={8}>
                <Label htmlFor="follow-up-email" css={{ width: '100%' }}>
                  <LabelWithAsterik label="Email" />
                  <Input
                    defaultValue={watch('email')}
                    css={{ margin: 0, flexGrow: 1, marginBottom: '$2' }}
                    data-cy="follow-up-email-input"
                    id="follow-up-email"
                    disabled={
                      [orgMessageHandlerActive, orgSelected].some(Boolean) ||
                      isOrgMemberCreated ||
                      isEditing
                    }
                    {...register('email', {
                      required: !watch('clientAnonymous'),
                      pattern: {
                        value: emailRegex,
                        message: 'Please enter a valid email.',
                      },
                    })}
                    onChange={(e) => {
                      setValue('email', e.target.value, { shouldTouch: true });
                    }}
                    onBlur={() => validateEmail(true)}
                    helperText={
                      errors.email?.type === 'required'
                        ? 'This is required.'
                        : errors.email?.message?.toString()
                    }
                  />
                </Label>

                {!isOrgMemberCreated && !dependentId ? (
                  <Flex gap="2" css={{ mb: '$2' }}>
                    {ValidOrgDomains?.map((dm) => (
                      <Button
                        key={dm}
                        variant={selectedDomain === dm ? 'filled' : 'outlined'}
                        onClick={() => changeEmailDomain(dm)}
                        size="sm"
                      >
                        {dm}
                      </Button>
                    ))}
                  </Flex>
                ) : null}
              </GridItem>
              <GridItem xs={4} sm={4} md={4} lg={4}>
                <Label htmlFor="name" css={{ width: '100%' }}>
                  <LabelWithAsterik label="Name" />
                  <Input
                    defaultValue={watch('name')}
                    data-cy="name-input"
                    id="name"
                    disabled={isOrgMemberCreated}
                    {...register('name', {
                      required: !watch('clientAnonymous'),
                      pattern: {
                        value: noWhiteSpaceRegex,
                        message: 'No trailing or leading spaces.',
                      },
                    })}
                    helperText={
                      errors.name?.type === 'required'
                        ? 'This is required.'
                        : errors.name?.message?.toString()
                    }
                  />
                </Label>
              </GridItem>

              <GridItem xs={4} sm={4} md={4} lg={4}>
                <Label htmlFor="tenure" css={{ width: '100%' }}>
                  Tenure (Yrs)
                  <Controller
                    name="tenure"
                    defaultValue={tenure}
                    control={control}
                    render={({ field: { onChange, value, ...rest } }) => (
                      <Select
                        data-cy="tenure-select"
                        options={TenureOptions}
                        selected={value}
                        onChange={onChange}
                        disabled={isOrgMemberCreated}
                        {...rest}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: 'This is required.',
                      },
                    }}
                  />
                  <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
                    {errors.tenure?.message?.toString()}
                  </Typography>
                </Label>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} lg={4}>
                <Label htmlFor="employeeId" css={{ width: '100%' }}>
                  Employee ID
                  <Input
                    id="employeeId"
                    disabled={isOrgMemberCreated}
                    {...register('employeeId')}
                  />
                </Label>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} lg={4}>
                <Label htmlFor="age" css={{ width: '100%' }}>
                  Age
                  <Controller
                    data-cy="age-input"
                    name="age"
                    defaultValue={watch('age')}
                    control={control}
                    render={({ field: { onChange, value, ...rest } }) => (
                      <Select
                        options={AgeOptions}
                        selected={value}
                        onChange={onChange}
                        disabled={isOrgMemberCreated || !!watch('isDependent')}
                        {...rest}
                        css={{ height: '44px' }}
                      />
                    )}
                  />
                  <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
                    {errors.age?.message?.toString()}
                  </Typography>
                </Label>
              </GridItem>
              {userId && userId !== 'null' ? (
                <GridItem xs={8} sm={8} md={8} lg={8}>
                  <EmergencyContactsInline
                    headerSize="sm"
                    css={{ mb: '$4' }}
                    cardCss={{ backgroundColor: '$gray100' }}
                  />
                </GridItem>
              ) : null}
              <GridItem xs={4} sm={4} md={4} lg={4}>
                <Label htmlFor="location" css={{ width: '100%' }}>
                  <LabelWithAsterik
                    label="Country"
                    hideAsterik={Boolean(watch('isDependent'))}
                  />
                  <Controller
                    name="location"
                    defaultValue={location}
                    control={control}
                    render={({ field: { onChange, value, ...rest } }) => (
                      <Select
                        data-cy="location-select"
                        options={convertIntoOptions(
                          regionsList ?? [],
                          'translation',
                          'translation',
                        )}
                        selected={value}
                        onChange={(e) => {
                          setValue('location', e.toString(), {
                            shouldValidate: true,
                          });
                          setValue('city', '');
                          setValue('landmark', '');
                        }}
                        disabled={
                          Boolean(watch('clientDetails.location')) ||
                          Boolean(watch('isDependent'))
                        }
                        {...rest}
                        css={{ height: '44px' }}
                      />
                    )}
                    rules={{
                      required: {
                        value: !orgAnonymous,
                        message: 'This is required.',
                      },
                    }}
                  />
                  <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
                    {errors.location?.message?.toString()}
                  </Typography>
                </Label>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} lg={4}>
                <Label htmlFor="city" css={{ width: '100%' }}>
                  <LabelWithAsterik
                    label="City"
                    hideAsterik={Boolean(watch('isDependent'))}
                  />
                  <Controller
                    name="city"
                    defaultValue={city}
                    control={control}
                    render={({ field: { onChange, value, ...rest } }) => (
                      <Combobox
                        key="cities"
                        options={[...CitiesOptions]}
                        selectedValue={value}
                        onOptionSelect={(newValue?: string) => {
                          onChange(newValue);
                        }}
                        shouldClearOnChange
                        createable
                        onCreate={(e) => setValue('city', e.toString())}
                        createablePlaceholder="Add new city - "
                        disabled={
                          Boolean(watch('isDependent')) ||
                          !watch('location') ||
                          Boolean(
                            watch('location') && watch('clientDetails.city'),
                          )
                        }
                        shouldOverrideOnChange
                        {...rest}
                      />
                    )}
                    rules={{
                      required: {
                        value: !orgAnonymous,
                        message: 'This is required.',
                      },
                    }}
                  />
                  <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
                    {errors.city?.message?.toString()}
                  </Typography>
                </Label>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} lg={4}>
                <Label htmlFor="landmark" css={{ width: '100%' }}>
                  Landmark
                  <Input
                    defaultValue={landmark}
                    id="landmark"
                    {...register('landmark')}
                  />
                </Label>
              </GridItem>
              {!isEditing && (
                <GridItem xs={8} sm={8} md={8} lg={8}>
                  <Controller
                    name="isDependent"
                    render={({ field: { value, onBlur, onChange } }) => (
                      <Flex gap="2" align="center" css={{ mt: '$2' }}>
                        <Checkbox
                          onClick={() => onChange(!value)}
                          onBlur={onBlur}
                          checked={value}
                          disabled={
                            isOrgMemberCreated || !!watch('dependentId')
                          }
                          id="isDependent"
                        />
                        <Label htmlFor="isDependent">
                          Client is a dependent
                        </Label>
                      </Flex>
                    )}
                  />
                </GridItem>
              )}
              <GridItem xs={8} sm={8} md={8} lg={8}>
                <Controller
                  name="isMinor"
                  render={({ field: { value, onBlur, onChange } }) => (
                    <Flex gap="2" align="center" css={{ mt: '$2', mb: '$6' }}>
                      <Checkbox
                        onClick={() => onChange(!value)}
                        onBlur={onBlur}
                        checked={value}
                        disabled={!!id}
                        id="isMinor"
                      />
                      <Label htmlFor="isMinor">Client is a minor</Label>
                    </Flex>
                  )}
                />
              </GridItem>
              {!isEditing && (
                <GridItem xs={8} sm={8} md={8} lg={8}>
                  <Tooltip label={bookNowButtonTooltipLabel}>
                    <Button
                      endIcon={<IoArrowForward style={{ rotate: '-45deg' }} />}
                      css={{ width: '100%' }}
                      onClick={handleSessionBookClick}
                      disabled={!bookNowButtonEnabled}
                      isLoading={
                        isClientAndSessionCreateLoading ||
                        isUpdateSessionLoading
                      }
                    >
                      Book a session
                    </Button>
                  </Tooltip>
                </GridItem>
              )}
            </Grid>
          </FormSectionAnimatedBox>
        )}
      </AnimatePresence>
    </MotionBox>
  );
};

export default FollowUpSessionDetails;
