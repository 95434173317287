import { useFormContext } from 'react-hook-form';
import { RiskContentData, RiskLevels } from '../../../../shared/constants/Risk';
import { CaseNoteFormTypes } from '../../../../shared/types/CaseNoteForm';
import { Box, Flex, Heading, Typography } from '../../../styledComponents';
import { ECPViewer } from '../../../screens/caseNotesForm/enterSessionNotes/riskLevelsView/emergencyContacts/ECPViewer';
import { AssessmentQuestionViewer } from '../../../screens/caseNotesForm/enterSessionNotes/riskLevelsView/AssessmentQuestionViewer';
import { HandHoldingHeartIcon } from '../../../screens/caseNotesForm/enterSessionNotes/riskLevelsView/bookASession/HandHoldingHeartIcon';
import { theme } from '../../../../stitches.config';
import { LearnMoreRiskAssessmentLink } from '../../../../shared/constants/Resources';

const RiskLevelDetails = () => {
  const { watch } = useFormContext<CaseNoteFormTypes>();

  const riskId = watch('risk');

  return (
    <Box
      css={{
        border: '1px solid $gray300',
        borderRadius: '$3',
        backgroundColor: 'white',
        padding: '$5',
        marginTop: '$5',
      }}
    >
      <Flex justify="between" align="start" css={{ mb: '$1' }}>
        <Box>
          <Heading size="md" css={{ fontWeight: 'bold', mb: '$1' }}>
            {RiskContentData[riskId].title}
          </Heading>
          <Typography size="lg" color="gray500">
            Complete the following action
          </Typography>
        </Box>
      </Flex>
      <Flex
        css={{
          border: '1px solid $gray300',
          borderRadius: '$3',
          backgroundColor: 'white',
          padding: '$5',
          marginTop: '$5',
        }}
        align="center"
        gap="6"
      >
        <HandHoldingHeartIcon
          color={theme.colors[RiskContentData[riskId].color.inner].value}
          fill={theme.colors[RiskContentData[riskId].color.outer].value}
        />
        <Typography size="md">
          Get the advice you need. Check the latest Intellect resources for next
          steps.{' '}
          <a // eslint-disable-line react/jsx-no-target-blank
            id={`${RiskContentData[riskId].title.replace(' ', '-')}-resource-link`}
            href={LearnMoreRiskAssessmentLink}
            target="_blank"
            style={{
              color: theme.colors.lightBlue.value,
              fontWeight: 500,
            }}
          >
            Learn more
          </a>
        </Typography>
      </Flex>

      {[RiskLevels.High, RiskLevels.HighCritical].some((r) => r === riskId) ? (
        <>
          <ECPViewer />
          <AssessmentQuestionViewer />
        </>
      ) : null}
    </Box>
  );
};

export default RiskLevelDetails;
