// AKA Billing Plan Types
export enum ProviderRole {
  COACHING = '0',
  CLINICAL = '1',
  HELPLINE = '2',
  COACH_AND_CLINICAL = '3',
  CARENAVIGATOR = '4',
  NUTRITIONIST = '5',
  FITNESS = '6',
  FINANCIAL = '7',
  EXECUTIVE = '8',
  LEGAL = '9',
  CAREOFFSITE = '10',
  COACHOFFSTE = '11',
  ONSITE = '12',
}

// AKA Billing Plan Categories
export const ProviderRoleCategories: Record<string, string[]> = {
  coaching: [ProviderRole.COACHING],
  holistic: [
    ProviderRole.NUTRITIONIST,
    ProviderRole.FITNESS,
    ProviderRole.FINANCIAL,
    ProviderRole.EXECUTIVE,
    ProviderRole.LEGAL,
  ],
  clinical: [
    ProviderRole.CLINICAL,
    ProviderRole.CAREOFFSITE,
    ProviderRole.ONSITE,
  ],
};

export const CreditPoolTypes = [
  { type: ProviderRole.COACHING, label: 'Coaching (Virtual)' },
  { type: ProviderRole.CLINICAL, label: 'Care (Virtual)' },
  { type: ProviderRole.HELPLINE, label: 'Webinar' },
  { type: ProviderRole.NUTRITIONIST, label: 'Nutritionist' },
  { type: ProviderRole.FITNESS, label: 'Fitness' },
  { type: ProviderRole.FINANCIAL, label: 'Financial' },
  // { type: ProviderRole.EXECUTIVE, label: 'Executive' },
  { type: ProviderRole.LEGAL, label: 'Legal' },
  { type: ProviderRole.CAREOFFSITE, label: 'Care (Offsite)' },
  { type: ProviderRole.COACHOFFSTE, label: 'Coaching (Offsite)' },
  { type: ProviderRole.ONSITE, label: 'Care (Onsite)' },
];
