import Modal from '../modal/Modal';

interface IDiscardUnsavedChangesModalProps {
  open: boolean;
  handleClose: () => void;
  handleDiscard: () => void;
}

const DiscardUnsavedChangesModal = ({
  open,
  handleClose,
  handleDiscard,
}: IDiscardUnsavedChangesModalProps) => (
  <Modal
    open={open}
    handleClose={handleClose}
    title="Leave page without saving?"
    subtitle="Any unsaved changes will be lost. This action cannot be undone"
    confirmButtonColor="red"
    confirmButtonText="Leave without saving"
    handleSubmit={handleDiscard}
  />
);

export default DiscardUnsavedChangesModal;
