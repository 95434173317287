import { styled } from '../../stitches.config';

export const GridItem = styled('div', {
  variants: {
    xs: {
      1: { '@xs': { gridColumn: 'span 1' } },
      2: { '@xs': { gridColumn: 'span 2' } },
      3: { '@xs': { gridColumn: 'span 3' } },
      4: { '@xs': { gridColumn: 'span 4' } },
      5: { '@xs': { gridColumn: 'span 5' } },
      6: { '@xs': { gridColumn: 'span 6' } },
      7: { '@xs': { gridColumn: 'span 7' } },
      8: { '@xs': { gridColumn: 'span 8' } },
      9: { '@xs': { gridColumn: 'span 9' } },
    },
    sm: {
      1: { '@sm': { gridColumn: 'span 1' } },
      2: { '@sm': { gridColumn: 'span 2' } },
      3: { '@sm': { gridColumn: 'span 3' } },
      4: { '@sm': { gridColumn: 'span 4' } },
      5: { '@sm': { gridColumn: 'span 5' } },
      6: { '@sm': { gridColumn: 'span 6' } },
      7: { '@sm': { gridColumn: 'span 7' } },
      8: { '@sm': { gridColumn: 'span 8' } },
      9: { '@sm': { gridColumn: 'span 9' } },
    },
    md: {
      1: { '@md': { gridColumn: 'span 1' } },
      2: { '@md': { gridColumn: 'span 2' } },
      3: { '@md': { gridColumn: 'span 3' } },
      4: { '@md': { gridColumn: 'span 4' } },
      5: { '@md': { gridColumn: 'span 5' } },
      6: { '@md': { gridColumn: 'span 6' } },
      7: { '@md': { gridColumn: 'span 7' } },
      8: { '@md': { gridColumn: 'span 8' } },
      9: { '@md': { gridColumn: 'span 9' } },
    },
    lg: {
      1: { '@lg': { gridColumn: 'span 1' } },
      2: { '@lg': { gridColumn: 'span 2' } },
      3: { '@lg': { gridColumn: 'span 3' } },
      4: { '@lg': { gridColumn: 'span 4' } },
      5: { '@lg': { gridColumn: 'span 5' } },
      6: { '@lg': { gridColumn: 'span 6' } },
      7: { '@lg': { gridColumn: 'span 7' } },
      8: { '@lg': { gridColumn: 'span 8' } },
      9: { '@lg': { gridColumn: 'span 9' } },
    },
  },
});
