import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';
import { SessionListItem } from '../../../../shared/types/Session';
import { SessionCallTypes } from '../../../../shared/constants/Session';

export type SessionsListResponse = {
  sessions: SessionListItem[];
  page: string;
  pageSize: string;
  total: number;
  pages: number;
};

export const useGetSessionsListV2 = ({
  sessionType = 0,
  page = 1,
  pageSize,
  risk,
  email,
  organisation,
  phone,
  callType,
  anonymous,
}: {
  sessionType?: number;
  page?: number;
  pageSize: number;
  risk?: number[] | null;
  email?: string | null;
  organisation?: number | null;
  phone?: string | null;
  callType?: SessionCallTypes | -1;
  anonymous?: boolean;
}) =>
  useQuery<SessionsListResponse>(
    [
      QueryKeys.getSessionsList,
      sessionType,
      page,
      pageSize,
      risk,
      email,
      phone,
      organisation,
      anonymous,
      callType,
    ],
    () =>
      doGet(ApiConfig.api.sessions.list, undefined, {
        sessionType,
        page,
        pageSize,
        risk,
        email,
        organisation,
        phone,
        callType,
        anonymous,
      }).then((res) => res.data.data),
    {
      onError: () => {
        toast.error('Unable to get list of sessions.');
      },
    },
  );
