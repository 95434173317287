import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ApiConfig from '../../../../config/ApiConfig';
import { PathConfig } from '../../../../config/PathConfig';
import { StorageItems } from '../../../../shared/constants/App';
import { doPost } from '../../../../shared/service/NetworkClient';
import { addItemToLocalStorage } from '../../../common/Storage';

export const useLogin = (onSuccess: () => void) => {
  const navigate = useNavigate();
  return useMutation(
    (body: { email: string; password: string }) =>
      doPost(ApiConfig.api.auth.login, body).then((res) => res.data.data),
    {
      onSuccess: (data) => {
        if (data.id) {
          onSuccess();
          addItemToLocalStorage(StorageItems.UserInfo, data);
          if (data?.addOns?.isCareNavigator) {
            navigate(PathConfig.careNavigator);
          } else {
            navigate(PathConfig.responderV2.base);
          }
        }
      },
      onError: () => {
        toast.error(
          'The email or password you’ve entered is invalid. Please try again or contact support.',
        );
      },
    },
  );
};
