import { Navigate, Outlet } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import useAuth from '../utilities/hooks/UseAuth';
import Navbar from './components/navbar/Navbar';
import { Box } from './styledComponents';
import Sidebar from './components/sidebar/Sidebar';
import useTracking from '../utilities/hooks/UseTracking';
import { PathConfig } from '../config/PathConfig';

const ProtectedLayout = () => {
  const { isLoggedIn, user: userDetails } = useAuth();
  const queryClient = useQueryClient();

  const { user } = useTracking();

  React.useEffect(() => {
    if (isLoggedIn) {
      const { id, createdAt, updatedAt } = userDetails;

      user.initialize(id);
      user.setProperties({
        userId: id,
        created: createdAt,
        updated: updatedAt,
      });
    }
  }, [isLoggedIn, user, userDetails]);

  if (isLoggedIn)
    return (
      <Box>
        <Sidebar />
        <Box
          css={{
            marginLeft: '250px',
            width: 'calc(100% - var(--sidebar-width))',
            '@xs': {
              marginLeft: '0',
              width: '100%',
            },
            '@sm': {
              marginLeft: '0',
              width: '100%',
            },
            '@lg': {
              marginLeft: '250px',
              width: 'calc(100% - var(--sidebar-width))',
            },
          }}
        >
          <Navbar />
          <Outlet />
        </Box>
      </Box>
    );

  queryClient.clear();
  return <Navigate to={PathConfig.login} />;
};

export default ProtectedLayout;
