import { useQuery } from '@tanstack/react-query';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { RiskLevels } from '../../../../shared/constants/Risk';
import { doGet } from '../../../../shared/service/NetworkClient';
import {
  AssessmentQuestion,
  CaseNoteSession,
} from '../../../../shared/types/Session';
import { SessionCallTypes } from '../../../../shared/constants/Session';

export const useGetAssessmentQuestions = (riskId?: CaseNoteSession['risk']) =>
  useQuery<AssessmentQuestion[]>(
    [QueryKeys.getAssessmentQuestions],
    () =>
      doGet(ApiConfig.api.sessions.getAssessmentQuestions).then(
        (res) => res.data.data,
      ),
    {
      select(data) {
        return data.filter((question) => question.risk === String(riskId));
      },
      enabled: Boolean(
        riskId &&
          [RiskLevels.High, RiskLevels.HighCritical].some((r) => r === riskId),
      ),
    },
  );

export const useGetAssessmentQuestionsByCallType = (
  callType: SessionCallTypes,
) =>
  useQuery<AssessmentQuestion[]>(
    [QueryKeys.getSessionQuestionsByCallType, callType],
    () =>
      doGet(ApiConfig.api.sessions.getSessionQuestonsByCallType).then(
        (res) => res.data.data,
      ),
    {
      select(data) {
        return data;
      },
      // Questions lists do not change frequently, this helps when the request is fetched multiple times
      cacheTime: 25 * 60 * 1000, // 25 minutes
      staleTime: 20 * 60 * 1000, // 20 minutes
    },
  );
