import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import wordmark from '../../../assets/images/wordmark.svg';
import { CommonEvent } from '../../../shared/constants/events/Common';
import {
  careNavSidebarItems,
  responderSidebarItems,
} from '../../../shared/constants/SidebarItems';
import { SidebarItem } from '../../../shared/types/SidebarItem';
import useAuth from '../../../utilities/hooks/UseAuth';
import useTracking from '../../../utilities/hooks/UseTracking';
import SupportRequest from '../../screens/SupportRequest/SupportRequest';
import { Box, Flex, Image, Typography } from '../../styledComponents';
import { SidebarLink } from './SidebarLink';

const Sidebar = () => {
  const location = useLocation();
  const { user } = useAuth();

  const { track } = useTracking<CommonEvent>();

  const [supportRefElement, setSupportRefElement] =
    useState<HTMLElement | null>(null);

  const eventTrackOnClick = (eventString?: SidebarItem['eventString']) => {
    if (eventString) {
      track(eventString, {
        eventAction: 'click',
        eventCategory: eventString,
        eventLabel: eventString,
      });
    }
  };

  return (
    <>
      <Box
        css={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          width: 'var(--sidebar-width)',
          backgroundColor: '$ghostwhite',
          '@xs': {
            display: 'none',
            width: 0,
          },
          '@sm': {
            display: 'none',
            width: 0,
          },
          '@lg': {
            display: 'block',
            width: 'var(--sidebar-width)',
          },
        }}
      >
        <Flex direction="column" justify="between" css={{ height: '100vh' }}>
          <Box>
            <Box
              css={{
                height: '8vh',
                borderBottom: '1px solid',
                borderColor: '$gray100',
                display: 'flex',
                pl: '$6',
              }}
            >
              <Image
                alt="Intellect Wordmark"
                src={wordmark}
                css={{
                  '@sm': {
                    width: '90px',
                    maxWidth: '90px',
                  },
                  '@xl': {
                    width: '120px',
                    maxWidth: '120px',
                  },
                }}
              />
            </Box>
            <Box css={{ padding: '$4', mt: '$7' }}>
              {(user?.addOns?.isCareNavigator
                ? careNavSidebarItems
                : responderSidebarItems
              ).map((item: SidebarItem) => (
                <SidebarLink
                  key={item.title}
                  onClick={() => eventTrackOnClick(item.eventString)}
                  item={item}
                  isCurrentPath={location.pathname === item.path}
                />
              ))}
            </Box>
          </Box>
          <Flex
            direction="column"
            gap="2"
            css={{
              width: '80%',
              backgroundColor: '#fff',
              mx: 'auto',
              mb: '6rem',
              p: '$4',
            }}
          >
            <Typography css={{ fontWeight: '600' }}>
              Need to report issues?
            </Typography>
            <Typography color="gray">
              Please tell us your problem with the experience
            </Typography>
            <Typography
              role="button"
              onClick={(e) => setSupportRefElement(e.currentTarget)}
              css={{
                color: '$blue',
                fontWeight: '600',
                '@hover': {
                  '&:hover': {
                    filter: 'brightness(85%)',
                    cursor: 'pointer',
                  },
                },
              }}
            >
              Contact support
            </Typography>
          </Flex>
        </Flex>
      </Box>
      <SupportRequest
        anchorEl={supportRefElement}
        handleClose={() => setSupportRefElement(null)}
      />
    </>
  );
};

export default Sidebar;
