import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';
import ApiConfig from '../../../../config/ApiConfig';
import { ProviderRole } from '../../../../shared/constants/Credits';
import { UserCreditPool } from '../../../../shared/types/Credits';

export const useClientCredits = (userId?: string) =>
  useQuery<Record<ProviderRole, UserCreditPool>>(
    [QueryKeys.getClientCredits, userId],
    () =>
      doGet(
        ApiConfig.api.clients.clientUserCredits.replace(
          '{{userId}}',
          userId || '',
        ),
      ).then((res) => res.data.data),
    { enabled: !!userId && userId !== 'null' },
  );
