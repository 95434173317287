import { ComponentProps, forwardRef } from 'react';
import { CSS, styled, VariantProps } from '../../stitches.config';
import { Spinner } from './Spinner';

const ChipButtonBase = styled('button', {
  all: 'unset',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  display: 'inline-flex',
  flexShrink: 0,
  lineHeight: '1',
  height: '$5',
  px: '$2',
  fontSize: '$2',
  fontWeight: 500,
  borderRadius: '$full',
  transition: 'all 0.2s linear',
  '@hover': {
    '&:hover:not([disabled])': {
      filter: 'brightness(85%)',
      cursor: 'pointer',
    },
  },
  '&:disabled': {
    cursor: 'not-allowed',
  },
  variants: {
    size: {
      sm: {
        height: '$5',
        px: '$2',
        fontSize: '$1',
        lineHeight: '$sizes$5',
      },
      md: {
        height: '$6',
        px: '$3',
        fontSize: '$3',
        lineHeight: '$sizes$6',
      },
      lg: {
        height: '$7',
        px: '$4',
        fontSize: '$4',
        lineHeight: '$sizes$7',
      },
    },
  },
});

export type ChipButtonProps = ComponentProps<typeof ChipButtonBase> &
  VariantProps<typeof ChipButtonBase> & {
    children: React.ReactNode;
    startIcon?: JSX.Element;
    endIcon?: JSX.Element;
    isLoading?: boolean;
    loadingText?: string;
    css?: CSS;
    selected?: boolean;
  };

export const ChipButton = forwardRef<HTMLButtonElement, ChipButtonProps>(
  (
    {
      children,
      startIcon,
      endIcon,
      disabled,
      isLoading,
      loadingText,
      css,
      key,
      selected,
      ...otherProps
    },
    ref,
  ) => (
    <ChipButtonBase
      ref={ref}
      css={{
        ...(selected
          ? {
              backgroundColor: '$gray500',
              color: '#fff',
              border: '1px solid $gray500',
            }
          : {
              color: '$gray400',
              border: '1px solid $gray400',
            }),
        ...css,
      }}
      disabled={disabled || isLoading}
      data-cy={`button-${key || children}`}
      {...otherProps}
    >
      {isLoading ? (
        <Spinner color="gray" size="2" css={{ mr: '$3' }} />
      ) : (
        startIcon
      )}
      <span
        style={{
          marginRight: endIcon ? '0.5rem' : 0,
          marginLeft: startIcon ? '0.5rem' : 0,
        }}
      >
        {isLoading ? (loadingText ?? 'Loading...') : children}
      </span>
      {isLoading ? null : endIcon}
    </ChipButtonBase>
  ),
);
