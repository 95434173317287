import { useMemo } from 'react';
import { ClientInfoV2 } from '../../../../shared/types/Client';
import { useGetOrganisationsList } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import InfoCard from '../../../components/InfoCard/InfoCard';
import { Typography } from '../../../styledComponents';

const ClientNewPhoneHandler = ({
  userDetails,
}: {
  userDetails?: ClientInfoV2;
}) => {
  const { data: organisationsList } = useGetOrganisationsList();

  const OrganisationName = useMemo(
    () =>
      organisationsList?.find((org) => org.id === userDetails?.organisation)
        ?.name ?? '',
    [organisationsList, userDetails?.organisation],
  );

  return (
    <InfoCard
      css={{
        width: '100%',
        backgroundColor: '#FFE1E1',
        p: '$3',
        br: '$3',
        wordWrap: 'break-word',
        border: 'none',
        color: '#000',
      }}
      content={
        <Typography>
          🚨 This client has previously called from <b>+{userDetails?.phone}</b>{' '}
          and is associated with <b>{OrganisationName}</b> organisation. Entered
          new phone number will not be saved.
        </Typography>
      }
    />
  );
};

export default ClientNewPhoneHandler;
