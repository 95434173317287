import { useFormContext } from 'react-hook-form';
import { CaseNoteFormTypesV2 } from '../../../../shared/types/CaseNoteFormV2';
import { getDisplayDateTime } from '../../../../utilities/common/Date';
import { useClientPresentingIssue } from '../../../../utilities/hooks/queryHooks/client/UseClientPresentingIssue';
import { Flex, Typography } from '../../../styledComponents';

const ClientPresentingIssueCard = () => {
  const { watch } = useFormContext<CaseNoteFormTypesV2>();
  const { userId } = watch();

  // --------------- NETWORK CALLS ---------------
  const { data: presentingIssue } = useClientPresentingIssue(userId);

  if (!userId || userId === 'null') return null;

  return (
    <Flex direction="column" gap="2">
      <Flex justify="between">
        <Typography size="md">Presenting issue</Typography>
        {presentingIssue?.createdAt ? (
          <Typography size="sm" color="gray">
            {getDisplayDateTime(new Date(presentingIssue?.createdAt))}
          </Typography>
        ) : null}
      </Flex>

      <Typography size="sm" css={{ lineHeight: '20px' }}>
        {presentingIssue?.issue ?? ''}
      </Typography>
    </Flex>
  );
};

export default ClientPresentingIssueCard;
