export const QueryKeys = {
  getSessionsList: 'sessions-list',
  getUserProviderData: 'user-provider-data',
  getUserFromNotebook: 'get-details',
  searchClientWithinAllOrgs: 'search-client-in-all-orgs',
  getUserFromNotebookByEmail: 'get-details-by-email',
  getUserFromEmail: 'get-details-email',
  getOrganisationsList: 'orgs-list',
  getRegionsList: 'regions-list',
  getCountryCitiesList: 'regions-cities-list',
  getCispTypesList: 'cisp-types-list',
  getSimilarEmails: 'email-search',
  getProviderConfig: 'provider-config',
  getAssessmentQuestions: 'assessment-questions',
  getSessionQuestionsByCallType: 'session-questions-by-call-type',
  getSessionData: 'session-detail',
  getSessionComments: 'session-case-notes',
  getSessionCommentsV2: 'session-case-notes-v2',
  getClientECP: 'client-ecp',
  getClientCredits: 'client-credits',
  getClientNotes: 'client-notes',
  getClientRisks: 'client-risks',
  getClientPresentingIssue: 'client-pi',
  downloadCaseNoteAttachment: 'download-case-note-attachment',
};
