import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AnimatePresence } from 'framer-motion';
import { Toaster } from 'react-hot-toast';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import './App.css';
import { PathConfig } from './config/PathConfig';
import { ToasterStyles } from './config/ToastTheme';
import { SessionCallTypes } from './shared/constants/Session';
import { User } from './shared/types/User';
import useAuth from './utilities/hooks/UseAuth';
import ProtectedLayout from './view/ProtectedLayout';
import CallLogs from './view/screens/callLogs/CallLogs';
import CareNavUserDetail from './view/screens/careNav/careNavForm/CareNavFormBase';
import CareNavOverview from './view/screens/careNav/overview/CareNavOverview';
import CaseNoteFormBase from './view/screens/caseNotesForm/CaseNoteFormBase';
import { EditSession } from './view/screens/EditSession/EditSessionFormBase';
import EscalationsCheckinsFormBase from './view/screens/EscalationsCheckins/EscalationsCheckinsFormBase';
import IncomingCallFormBase from './view/screens/IncomingCall/IncomingCallFormBase';
import Login from './view/screens/login/Login';
import MultiPurposeCallFormBase from './view/screens/MultiPurposeCallForm/MultiPurposeCallForm';
import OutgoingCallFormBase from './view/screens/OutgoingCall/OutgoingCallFormBase';
import { ResponderHome } from './view/screens/responderHome/ResponderHome';
import { ResponderHomeV2 } from './view/screens/ResponderHomeV2/ResponderHomeV2';
import SessionDetail from './view/screens/sessionDetail/SessionDetail';
import SessionDetailsV2 from './view/screens/SessionDetailsV2/SessionDetailsV2';

const ResponderRoutes = ({ user }: { user: User }) =>
  user?.addOns?.isCareNavigator ? <Navigate to="/" /> : <Outlet />;

const CareNavigatorRoutes = ({ user }: { user: User }) =>
  user?.addOns?.isCareNavigator ? <Outlet /> : <Navigate to="/" />;

const HomeRedirect = ({ user }: { user: User }) =>
  user?.addOns?.isCareNavigator ? (
    <Navigate to={PathConfig.careNavigator} />
  ) : (
    <Navigate to={PathConfig.responderV2.base} />
  );

const AppRoutes = () => {
  const { user } = useAuth(); // Get user from auth hook

  const router = createBrowserRouter([
    {
      element: <ProtectedLayout />,
      children: [
        {
          path: `${PathConfig.responder}/*`,
          element: <ResponderRoutes user={user} />,
          children: [
            { path: '', element: <ResponderHome /> },
            { path: PathConfig.callLogs, element: <CallLogs /> },
            { path: PathConfig.newCaseNote, element: <CaseNoteFormBase /> },
          ],
        },
        {
          path: `${PathConfig.responderV2.base}/*`,
          element: <ResponderRoutes user={user} />,
          children: [
            { path: '', element: <ResponderHomeV2 /> },
            {
              path: PathConfig.responderV2.incomingCall,
              element: <IncomingCallFormBase />,
            },
            {
              path: PathConfig.responderV2.incomingCallEdit,
              element: <IncomingCallFormBase />,
            },
            {
              path: PathConfig.responderV2.outgoingCall,
              element: <OutgoingCallFormBase />,
            },
            {
              path: PathConfig.responderV2.outgoingCallEdit,
              element: <OutgoingCallFormBase />,
            },
            {
              path: PathConfig.responderV2.esclationsCheckings,
              element: <EscalationsCheckinsFormBase />,
            },
            {
              path: PathConfig.responderV2.esclationsCheckingsEdit,
              element: <EscalationsCheckinsFormBase />,
            },
            {
              path: PathConfig.responderV2.cispCall,
              element: (
                <MultiPurposeCallFormBase
                  callType={SessionCallTypes.CISP_CALL}
                />
              ),
            },
            {
              path: PathConfig.responderV2.cispCallEdit,
              element: (
                <MultiPurposeCallFormBase
                  callType={SessionCallTypes.CISP_CALL}
                />
              ),
            },
            {
              path: PathConfig.responderV2.testCall,
              element: (
                <MultiPurposeCallFormBase
                  callType={SessionCallTypes.TEST_CALL}
                />
              ),
            },
            {
              path: PathConfig.responderV2.testCallEdit,
              element: (
                <MultiPurposeCallFormBase
                  callType={SessionCallTypes.TEST_CALL}
                />
              ),
            },
          ],
        },
        { path: PathConfig.sessionDetail, element: <SessionDetail /> },
        {
          path: PathConfig.sessionDetailV2.details,
          element: <SessionDetailsV2 />,
        },
        {
          path: `${PathConfig.sessionDetail}${PathConfig.editSession}`,
          element: <EditSession />,
        },
        {
          path: `${PathConfig.careNavigator}/*`,
          element: <CareNavigatorRoutes user={user} />,
          children: [
            { path: '', element: <CareNavOverview /> },
            { path: PathConfig.newCaseNote, element: <CareNavUserDetail /> },
          ],
        },
        {
          path: '/',
          element: <HomeRedirect user={user} />,
        },
      ],
    },
    { path: PathConfig.login, element: <Login /> },
    { path: '*', element: <Navigate to={PathConfig.login} /> },
  ]);

  return (
    <>
      <Toaster position="top-right" gutter={8} toastOptions={ToasterStyles} />
      <ReactQueryDevtools initialIsOpen={false} />

      <AnimatePresence mode="wait" initial={false}>
        <RouterProvider router={router} />;
      </AnimatePresence>
    </>
  );
};

export default AppRoutes;
