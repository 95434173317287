import { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PrsentingIssues } from '../../../shared/constants/PresentingIssues';
import { RiskLabels, RiskLevels } from '../../../shared/constants/Risk';
import {
  CaseNoteMinimumCharacters,
  NotesMaxCharacters,
  NotesMinCharacters,
} from '../../../shared/constants/Session';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import FormSection from '../../components/formSection/FormSection';
import {
  Box,
  Combobox,
  Flex,
  Label,
  RadioGroup,
  RadioGroupItem,
  TextArea,
  Typography,
} from '../../styledComponents';
import RiskLevelDetails from './RiskLevelDetails/RiskLevelDetails';
import { MultiSelectCombobox } from '../../styledComponents/MultiSelectCombobox';
import { useFormConfigsContext } from '../FormConfigsProvider/FormConfigsProvider';
import { onlyWhiteSpaceRegex } from '../../../utilities/common/FormValidatorRegex';
import LabelWithAsterik from '../../components/LabelWithAsterik/LabelWithAsterik';

const NotesValidations = {
  required: {
    value: true,
    message: 'This is required.',
  },
  minLength: {
    value: NotesMinCharacters,
    message: `Please enter a minimum of ${NotesMinCharacters} characters.`,
  },
  maxLength: {
    value: NotesMaxCharacters,
    message: `Characters should not exceed more than ${NotesMaxCharacters} characters.`,
  },
  pattern: {
    value: onlyWhiteSpaceRegex,
    message: 'This cannot be only whitespace..',
  },
};

const RiskAssessmentCaseNotesDetails = ({
  defaultOpen = true,
}: {
  defaultOpen?: boolean;
}) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<CaseNoteFormTypesV2>();
  const { isEditing } = useFormConfigsContext();

  return (
    <FormSection
      title="Risk Assessment and Case Notes"
      subtitle="Record session notes and recommend coaches for your client"
      css={{ my: '0', zIndex: '$2' }}
      defaultOpen={defaultOpen}
    >
      <Flex direction="column" gap="2">
        {!isEditing && (
          <>
            <Box>
              <Flex justify="between" css={{ mb: '$2' }}>
                <Label htmlFor="case-note-text">
                  <LabelWithAsterik label="Reason for calling" />
                </Label>
                <Typography size="sm">
                  {watch('callReason')?.length}/{NotesMaxCharacters}
                </Typography>
              </Flex>
              <TextArea
                data-cy="text-area-call-reason"
                size="md"
                {...register('callReason', NotesValidations)}
              />
              <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
                {errors.callReason?.message?.toString()}
              </Typography>
            </Box>
            <Box>
              <Flex justify="between" css={{ mb: '$2' }}>
                <Label htmlFor="case-note-text">
                  <LabelWithAsterik label="Descriptions of the call" />
                </Label>
                <Typography size="sm">
                  {watch('callDescription')?.length}/{NotesMaxCharacters}
                </Typography>
              </Flex>
              <TextArea
                data-cy="text-area-call-description"
                size="md"
                {...register('callDescription', NotesValidations)}
              />
              <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
                {errors.callDescription?.message?.toString()}
              </Typography>
            </Box>
            <Box>
              <Flex justify="between" css={{ mb: '$2' }}>
                <Label htmlFor="case-note-text">
                  <LabelWithAsterik label="Intervention conducted by ranger" />
                </Label>
                <Typography size="sm">
                  {watch('rangerIntervention')?.length}/{NotesMaxCharacters}
                </Typography>
              </Flex>
              <TextArea
                data-cy="text-area-ranger-intervention"
                size="md"
                {...register('rangerIntervention', NotesValidations)}
              />
              <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
                {errors.rangerIntervention?.message?.toString()}
              </Typography>
            </Box>
            <Box>
              <Flex justify="between" css={{ mb: '$2' }}>
                <Label htmlFor="case-note-text">Remarks</Label>
                <Typography size="sm">
                  {watch('caseNotes')?.length}/{CaseNoteMinimumCharacters}
                </Typography>
              </Flex>
              <TextArea
                data-cy="text-area-case-notes"
                size="md"
                {...register('caseNotes', {
                  ...NotesValidations,
                  required: undefined,
                })}
              />
              <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
                {errors.caseNotes?.message?.toString()}
              </Typography>
            </Box>
          </>
        )}
        <Box>
          <Label
            htmlFor="issue"
            css={{
              width: '100%',
            }}
          >
            <LabelWithAsterik label="Primary presenting issue" />
            <Controller
              name="issue"
              defaultValue={watch('issue')}
              control={control}
              render={({ field: { onChange, value, ...rest } }) => (
                <Combobox
                  categorized
                  options={PrsentingIssues}
                  selectedValue={value}
                  onOptionSelect={onChange}
                  {...rest}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: 'This is required.',
                },
              }}
            />
            {errors.issue?.message && (
              <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
                {errors.issue?.message?.toString()}
              </Typography>
            )}
          </Label>
        </Box>
        <Box>
          <Label
            htmlFor="secondaryIssue"
            css={{
              width: '100%',
            }}
          >
            <LabelWithAsterik label="Secondary presenting issue (max 3)" />
            <Controller
              name="secondaryIssue"
              defaultValue={watch('secondaryIssue')}
              control={control}
              render={({ field: { onChange, value, ...rest } }) => (
                <MultiSelectCombobox
                  options={
                    PrsentingIssues?.flatMap((section) => section?.options) ??
                    []
                  }
                  selectedOptions={value}
                  onChange={onChange}
                  limitTags={3}
                  {...rest}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: 'This is required.',
                },
              }}
            />
            {errors?.secondaryIssue?.message && (
              <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
                {errors.secondaryIssue?.message?.toString()}
              </Typography>
            )}
          </Label>
        </Box>
        <Box>
          <Label
            htmlFor="issue"
            css={{
              width: '100%',
              mb: '$2',
            }}
          >
            <LabelWithAsterik label="Risk Assessment Category" />
          </Label>
          <Controller
            name="risk"
            render={({ field: { onChange, value } }) => (
              <RadioGroup value={value}>
                <Flex wrap="wrap" align="center" gap="2">
                  {Object.values(RiskLevels).map((riskValue) => (
                    <Fragment key={RiskLabels[riskValue]}>
                      <RadioGroupItem
                        value={String(riskValue)}
                        checked={riskValue === value}
                        id={RiskLabels[riskValue]}
                        onClick={() => onChange(riskValue)}
                      />
                      <Label css={{ pr: '$4' }} htmlFor={RiskLabels[riskValue]}>
                        {RiskLabels[riskValue]}
                      </Label>
                    </Fragment>
                  ))}
                </Flex>
              </RadioGroup>
            )}
          />
        </Box>
        {watch('risk') !== undefined && <RiskLevelDetails />}
      </Flex>
    </FormSection>
  );
};

export default RiskAssessmentCaseNotesDetails;
