import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';
import { CaseNote } from '../../../../shared/types/Session';
import { CaseNoteTypes } from '../../../../shared/constants/Session';

/**
 * Used in old form incomin-call-old
 * @param sessionId
 * @returns
 */
export const useGetSessionComments = (sessionId?: string) =>
  useQuery<CaseNote[]>(
    [QueryKeys.getSessionComments, sessionId],
    () =>
      doGet(
        ApiConfig.api.sessions.getNotes.replace(
          '{{sessionId}}',
          String(sessionId),
        ),
      ).then((res) => res?.data?.data?.data),
    {
      enabled: !!sessionId,
      select: (data) => data?.slice(1) ?? [],
      onError: () => {
        toast.error("An error occurred while getting the session's comments.");
      },
    },
  );

/**
 * Used in new forms
 * @param sessionId
 * @param noteType
 * @returns
 */
export const useGetSessionCommentsV2 = (
  sessionId?: string,
  noteType?: CaseNoteTypes,
) =>
  useQuery<CaseNote[]>(
    [QueryKeys.getSessionCommentsV2, sessionId, noteType],
    () =>
      doGet(
        ApiConfig.api.sessions.getNotes.replace(
          '{{sessionId}}',
          String(sessionId),
        ),
        {},
        { noteType },
      ).then((res) => res?.data?.data?.data),
    {
      enabled: !!sessionId,
      select: (data) => data ?? [],
      onError: () => {
        toast.error("An error occurred while getting the session's comments.");
      },
    },
  );
