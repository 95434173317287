import { Controller, useFormContext } from 'react-hook-form';
import { IoSearchOutline } from 'react-icons/io5';
import PhoneInput from 'react-phone-input-2';
import { rowsPerPageOptions } from '../../../shared/constants/CallLogs';
import { RiskLabels, RiskLevels } from '../../../shared/constants/Risk';
import { SessionCallTypesFilterValues } from '../../../shared/constants/Session';
import { CallLogsFilterTypes } from '../../../shared/types/CallLogs';
import { convertIntoComboboxOptions } from '../../../utilities/common/ConvertIntoOptions';
import { SessionsListResponse } from '../../../utilities/hooks/queryHooks/callLogs/useGetSessionsListV2';
import { useGetOrganisationsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import LoadingView from '../../components/loadingView/LoadingView';
import Pagination from '../../components/pagination/Pagination';
import {
  Box,
  Combobox,
  Flex,
  Grid,
  Input,
  Label,
  Select,
  Switch,
  Typography,
} from '../../styledComponents';
import { GridItem } from '../../styledComponents/GridItem';

export const CallLogFilters = ({
  sessionsData,
}: {
  sessionsData?: SessionsListResponse;
}) => {
  const {
    data: orgsList,
    dataUpdatedAt,
    isLoading,
  } = useGetOrganisationsList();
  const { watch, control, register, reset, setValue } =
    useFormContext<CallLogsFilterTypes>();

  const handleAnonymousChange = (newValue: boolean) => {
    reset({
      ...watch(),
      anonymous: newValue,
      organisation: newValue ? -1 : undefined,
      email: '',
      page: 1,
    });
  };

  const handleFilterChange = (name: keyof CallLogsFilterTypes, value: any) => {
    setValue(name, value);
    setValue('page', 1);
  };

  return (
    <Grid columns="9" gapX="3" css={{ mb: '$3' }}>
      <GridItem sm={3} md={3} lg={2}>
        <Label htmlFor="organisation" css={{ width: '100%' }}>
          Organisation
          {isLoading || orgsList ? (
            <Controller
              name="organisation"
              defaultValue={watch('organisation')}
              control={control}
              render={({ field: { onChange, value, ...rest } }) => (
                <Combobox
                  /**  Temporary solution to re render once value changes
                   *  Have to find out way to clear inputValue of
                   * combobox when value changes
                   */
                  key={`${dataUpdatedAt} ${watch('anonymous')}`}
                  placeholder="Select organisation"
                  options={convertIntoComboboxOptions(
                    orgsList ?? [],
                    'name',
                    'id',
                  )}
                  selectedValue={value?.toString()}
                  onOptionSelect={(newOption) =>
                    handleFilterChange(
                      'organisation',
                      newOption ? parseInt(newOption, 10) : newOption,
                    )
                  }
                  disabled={watch('anonymous')}
                  {...rest}
                />
              )}
            />
          ) : (
            <LoadingView />
          )}
        </Label>
      </GridItem>
      <GridItem sm={4} md={4} lg={2}>
        <Label htmlFor="email" css={{ width: '100%' }}>
          Email
          <Input
            {...register('email')}
            placeholder="Search emails"
            startElement={<IoSearchOutline size={20} />}
            defaultValue={watch('email') || ''}
            disabled={watch('anonymous')}
            onChange={(e) => handleFilterChange('email', e.target.value)}
          />
        </Label>
      </GridItem>
      <GridItem sm={3} md={3} lg={2}>
        <Label htmlFor="email" css={{ width: '100%' }}>
          Phone
          <Flex
            justify="between"
            align="center"
            gap={3}
            css={{
              width: '100%',
              flexGrow: 1,
              mt: '8px',
              border: '1px solid $gray300',
              borderRadius: '0.5rem',
              backgroundColor: 'inherit',
            }}
          >
            <Box css={{ flexGrow: 1 }}>
              <Controller
                name="phone"
                control={control}
                render={({ field: { ref, onChange, ...rest } }) => (
                  <PhoneInput
                    placeholder=""
                    inputProps={{
                      ref,
                      required: true,
                    }}
                    inputClass="phone-input"
                    country="sg"
                    inputStyle={{ height: '42px' }}
                    onChange={(value) => handleFilterChange('phone', value)}
                    {...rest}
                  />
                )}
              />
            </Box>
          </Flex>
        </Label>
      </GridItem>
      <GridItem sm={2} md={2} lg={2}>
        <Label
          htmlFor="risk"
          css={{
            width: '100%',
            height: '100%',
          }}
        >
          Risk level
          <Controller
            name="risk"
            defaultValue={watch('risk')}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                multiple
                options={Object.entries(RiskLevels).map(
                  ([_label, riskValue]) => ({
                    label: RiskLabels[riskValue],
                    value: riskValue,
                  }),
                )}
                selected={value || []}
                onChange={(risk) => handleFilterChange('risk', risk)}
                css={{
                  height: '44px',
                  marginBottom: '0.5rem',
                }}
                {...rest}
              />
            )}
          />
        </Label>
      </GridItem>
      <GridItem sm={2} md={2} lg={2}>
        <Label htmlFor="risk" css={{ width: '100%', height: '100%' }}>
          Call type
          <Controller
            name="callType"
            defaultValue={watch('callType') ?? 'all'}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Select
                options={SessionCallTypesFilterValues}
                selected={value}
                onChange={(callType) =>
                  handleFilterChange('callType', callType)
                }
                css={{
                  height: '44px',
                  marginBottom: '0.5rem',
                }}
                {...rest}
              />
            )}
          />
        </Label>
      </GridItem>
      <GridItem sm={2} lg={2}>
        <Label>
          Anonymous
          <Controller
            name="anonymous"
            render={({ field: { value, onBlur } }) => (
              <Switch
                css={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0.5rem 0 1rem',
                  height: '44px',
                }}
                onBlur={onBlur}
                checked={value}
                onCheckedChange={(newValue) => handleAnonymousChange(newValue)}
              />
            )}
          />
        </Label>
      </GridItem>
      <Flex
        align="center"
        justify="between"
        gap="1"
        css={{ gridColumn: '8 / span 1' }}
      >
        <Typography css={{ width: 'fit-content' }}>Rows per page</Typography>
        <Controller
          name="pageSize"
          defaultValue={watch('pageSize')}
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              options={rowsPerPageOptions.map((row) => ({
                label: row.toString(),
                value: row,
              }))}
              css={{ width: '64px' }}
              selected={value}
              onChange={(e) => {
                onChange(e);
                setValue('page', 1);
              }}
              fullWidth={false}
              {...rest}
            />
          )}
        />
      </Flex>
      <Flex align="center" style={{ gridColumn: '9 / span 3' }}>
        {sessionsData ? (
          <Pagination
            currentPage={parseInt(sessionsData.page, 10)}
            onPageChange={(newPage: number) => setValue('page', newPage)}
            pageSize={parseInt(sessionsData.pageSize, 10)}
            totalCount={sessionsData.total}
            siblingCount={1}
          />
        ) : null}
      </Flex>
    </Grid>
  );
};
