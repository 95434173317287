import { format } from 'date-fns';

export const getDisplayDate = (date: Date | number) =>
  format(date, 'dd MMM yyyy');

export const getDisplayTime = (date: Date | number) =>
  format(date, 'hh:mm aaa');

export const getDisplayDateTime = (date: Date | number) =>
  format(date, 'dd MMM yyyy, hh:mm aaa');
