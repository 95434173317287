import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useBlocker } from 'react-router-dom';
import { CaseNoteFormTypesV2 } from '../../shared/types/CaseNoteFormV2';
import { useFormConfigsContext } from '../../view/app-components/FormConfigsProvider/FormConfigsProvider';

/**
 * Common hook for preventing users from refreshing or navigating away
 * @returns
 */
export const usePreventNavigation = (defaultValues: Record<string, any>) => {
  const { watch } = useFormContext<CaseNoteFormTypesV2>();
  const { isEditing } = useFormConfigsContext();
  const { isConfirmationModalShowing } = useFormConfigsContext();

  const [showDraftModal, setShowDraftModal] = useState(false);

  const keys = Object.keys(defaultValues) as (keyof CaseNoteFormTypesV2)[];

  const valuesToWatch = keys.reduce(
    (acc, key) => ({ ...acc, [key]: watch(key) }),
    {} as Partial<Record<keyof CaseNoteFormTypesV2, any>>,
  );

  const isFormDirty = !_.isEqual(defaultValues, valuesToWatch);

  useEffect(() => {
    const handleBeforeUnload = (e: Event) => {
      if (isFormDirty) {
        e.preventDefault();
        return true; // block closing tab or reloading
      }
      return null;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);

  const shouldBlockNavigation = () => {
    if (isFormDirty) {
      setShowDraftModal(true);
      return true; // block navigation, show confirmation prompt
    }
    return false;
  };

  // if confirmation modal is not on screen and values are different then only block
  const blocker = useBlocker(
    !isConfirmationModalShowing && !isEditing && shouldBlockNavigation,
  );

  const handleDiscardChanges = () => {
    setShowDraftModal(false);
    blocker.proceed?.();
  };

  return {
    handleDiscardChanges,
    showDraftModal,
    setShowDraftModal,
    isFormDirty,
  };
};
