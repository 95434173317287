import { CSS } from '@stitches/react';
import { useEffect, useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  IoAddOutline,
  IoCloseOutline,
  IoPencilSharp,
  IoSaveOutline,
} from 'react-icons/io5';
import 'react-phone-input-2/lib/bootstrap.css';
import { CaseNoteFormEvent } from '../../../../shared/constants/events/CaseNoteForm';
import { CaseNoteFormTypesV2 } from '../../../../shared/types/CaseNoteFormV2';
import { ClientECP } from '../../../../shared/types/Client';
import {
  useCreateClientECP,
  useGetClientECP,
} from '../../../../utilities/hooks/queryHooks/client/UseGetAndCreateECP';
import useTracking from '../../../../utilities/hooks/UseTracking';
import {
  Button,
  Flex,
  IconButton,
  Typography,
} from '../../../styledComponents';
import Divider from '../../../styledComponents/Divider';
import NoDataFoundCard from '../../NoDataFoundCard/NoDataFoundCard';
import EmergencyContactCard from './EmergencyContactCard';
import EmergencyContactsInlineForm from './EmergencyContactsInlineForm';
import { HeadingSizeVariants } from '../../../styledComponents/Heading';

type ECPModalTypes = {
  contacts: ClientECP[];
};

const defaultFormValues = [
  { name: '', relation: '', contact: '', consent: false },
];

interface EmergencyContactsInlineProps {
  css?: CSS;
  cardCss?: CSS;
  headerSize?: HeadingSizeVariants;
}

const EmergencyContactsInline = ({
  css,
  cardCss,
  headerSize = 'md',
}: EmergencyContactsInlineProps) => {
  const { watch: formWatch } = useFormContext<CaseNoteFormTypesV2>();
  const { userId } = formWatch();

  const [isEcpEditing, setIsEcpEditing] = useState(false);

  const { data: ecpData } = useGetClientECP(userId);

  const { track } = useTracking<CaseNoteFormEvent>();

  const methods = useForm<ECPModalTypes>({
    defaultValues: {
      contacts: ecpData?.length ? [...ecpData] : defaultFormValues,
    },
  });

  useEffect(() => {
    methods.setValue(
      'contacts',
      ecpData?.length ? [...ecpData] : defaultFormValues,
    );
  }, [ecpData, methods]);

  const { append } = useFieldArray({
    control: methods.control,
    name: 'contacts',
  });

  const { contacts } = methods.watch();

  const handleEditCancel = () => {
    methods.reset();
    setIsEcpEditing(false);
  };

  const handleECPSuccess = () => {
    setIsEcpEditing(false);
  };

  const { mutate: mutateECP, isLoading: isECPMutating } = useCreateClientECP(
    formWatch('userId'),
    handleECPSuccess,
  );

  const handleSubmit = () => {
    track('add_emergency_contact', {
      eventAction: 'click',
      eventCategory: 'save_information',
      eventLabel: 'save_information',
    });
    mutateECP(methods.watch('contacts'));
  };

  const shouldAllowSavingECP =
    !Object.keys(methods.formState.errors).length && methods.formState.isValid;

  if (!userId || userId === 'null') return null;

  return (
    <FormProvider {...methods}>
      <Flex direction="column" gap="2" css={css}>
        <Flex justify="between" align="center">
          <Typography size={headerSize}>Emergency contact</Typography>
          {!isEcpEditing ? (
            <IconButton
              type="button"
              size="sm"
              onClick={() => setIsEcpEditing(true)}
            >
              <IoPencilSharp />
            </IconButton>
          ) : (
            <IconButton
              type="button"
              size="sm"
              onClick={() =>
                append({ name: '', relation: '', contact: '', consent: false })
              }
              disabled={contacts?.length >= 2}
            >
              <IoAddOutline />
            </IconButton>
          )}
        </Flex>

        {!isEcpEditing ? (
          ecpData?.length ? (
            ecpData?.map((ecp, idx) => {
              const key = `view-ecp-${idx}`;
              return (
                <EmergencyContactCard
                  key={key}
                  name={ecp?.name ?? '-'}
                  relation={ecp?.relation ?? '-'}
                  phone={ecp?.contact ?? '-'}
                  css={cardCss}
                />
              );
            })
          ) : (
            <NoDataFoundCard msg="No emergency contact found!" css={cardCss} />
          )
        ) : (
          <>
            {contacts?.map((_, idx) => {
              const key = `edit-ecp-${idx}`;
              return (
                <>
                  <EmergencyContactsInlineForm key={key} index={idx} />
                  <Divider />
                </>
              );
            })}

            <Flex css={{ width: '80%' }} gap="2">
              <Button
                variant="ghost"
                startIcon={<IoSaveOutline />}
                css={{ flex: 1 }}
                disabled={!shouldAllowSavingECP || isECPMutating}
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button
                variant="ghost"
                startIcon={<IoCloseOutline />}
                css={{ flex: 1, color: '$gray' }}
                onClick={handleEditCancel}
              >
                Cancel
              </Button>
            </Flex>
          </>
        )}
      </Flex>
    </FormProvider>
  );
};

export default EmergencyContactsInline;
