import { NoOrganisationCode } from '../../shared/constants/Session';

export const numberWithHyphensRegex: RegExp = /^[\d -]+$/;

export const emailRegex: RegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const noWhiteSpaceRegex: RegExp = /^[^\s]+(?:$|.*[^\s]+$)/;

export const onlyWhiteSpaceRegex: RegExp = /^(?!\s*$).+/;

export const validDomainRegex: RegExp =
  /^(?!www\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;

export const domainReplacementRegex: RegExp =
  /@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

export const getOrganisation = (organisation: number | undefined) =>
  organisation === NoOrganisationCode || organisation === undefined
    ? null
    : String(organisation);
