import { Controller, useFormContext } from 'react-hook-form';
import {
  AgeOptions,
  NoOrganisationCode,
} from '../../../../shared/constants/Session';
import { CaseNoteFormTypes } from '../../../../shared/types/CaseNoteForm';
import { convertIntoComboboxOptions } from '../../../../utilities/common/ConvertIntoOptions';
import { noWhiteSpaceRegex } from '../../../../utilities/common/FormValidatorRegex';
import { useGetOrganisationsList } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import FormSection from '../../../components/formSection/FormSection';
import {
  Checkbox,
  Flex,
  Grid,
  Input,
  Label,
  Tag,
  Typography,
  Select,
  Combobox,
} from '../../../styledComponents';
import EmailInputV2 from './EmailInputV2/EmailInputV2';
import PhoneNumberInput from './PhoneInput';

const EnterClientInformation = () => {
  const {
    control,
    clearErrors,
    register,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useFormContext<CaseNoteFormTypes>();

  console.log('errors-', errors);

  const handleAnonymousChange = (value: boolean) => {
    if (!value) {
      clearErrors(['name', 'email', 'age']);
    }
    setValue('clientAnonymous', !value);
  };

  // const doesNotWantToDiscloseClientData = watch('age') === 'NA';
  const allDataAdded = watch(['name', 'email', 'phone', 'age']).every(Boolean);

  const { data: organisationsList, dataUpdatedAt } = useGetOrganisationsList();

  const handleReset = () => {
    reset((formValues) => ({
      ...formValues,
      phone: '',
      email: '',
      id: undefined,
      userId: '',
      name: '',
      location: undefined,
      organisation: undefined,
      meetingData: null,
      sessionId: '',
      caseNotes: '',
      tenure: '',
      age: '',
      employeeId: '',
      emailInputString: '',
      orgSelected: false,
    }));
  };

  // useEffect(() => {
  //   setValue('clientAnonymous', !!doesNotWantToDiscloseClientData);
  // }, [doesNotWantToDiscloseClientData, setValue]);

  return (
    <FormSection
      title="Enter client information"
      subtitle="Enter your client information to get started with the session"
      css={{ zIndex: '$2', position: 'relative' }}
      defaultOpen
      statusTag={
        watch('clientAnonymous') ? (
          <Tag variant="gray" shape="pill">
            Anonymous
          </Tag>
        ) : allDataAdded && // If some fields are not filled OR an error exists for a field.
          !Object.keys(errors).some((key) =>
            ['name', 'email', 'phone', 'age'].includes(key),
          ) ? (
          <Tag variant="success" shape="pill">
            Done
          </Tag>
        ) : (
          <Tag variant="warning" shape="pill">
            Pending
          </Tag>
        )
      }
    >
      <Flex direction="column">
        <Label htmlFor="organisation">
          Organisation *
          <Controller
            name="organisation"
            defaultValue={watch('organisation')}
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Combobox
                key={`${dataUpdatedAt}-${watch('organisation')}`}
                options={convertIntoComboboxOptions(
                  organisationsList ?? [],
                  'name',
                  'id',
                )}
                selectedValue={value?.toString()}
                onOptionSelect={(newOption) =>
                  onChange(newOption ? parseInt(newOption, 10) : newOption)
                }
                disabled={
                  !!watch('id') ||
                  !organisationsList ||
                  watch('orgMessageHandlerActive')
                }
                {...rest}
              />
            )}
            rules={{
              required: {
                value: !watch('orgAnonymous'),
                message: 'This is required.',
              },
            }}
          />
          <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
            {errors.organisation?.message?.toString()}
          </Typography>
          {watch('organisation') === NoOrganisationCode && (
            <Typography color="darkRed" size="xs" css={{ mb: '$3' }}>
              Warning: A session cannot be booked for a user without an
              organisation.
            </Typography>
          )}
        </Label>
        <PhoneNumberInput handleReset={handleReset} />

        <EmailInputV2 handleReset={handleReset} />
        <Grid align="start" columns={2} gap="5">
          <Label htmlFor="name">
            Name{!watch('clientAnonymous') && ' *'}
            <Input
              defaultValue={watch('name')}
              data-cy="name-input"
              id="name"
              disabled={!!watch('id')}
              {...register('name', {
                required: !watch('clientAnonymous'),
                pattern: {
                  value: noWhiteSpaceRegex,
                  message: 'No trailing or leading spaces.',
                },
              })}
              helperText={
                errors.name?.type === 'required'
                  ? 'This is required.'
                  : errors.name?.message?.toString()
              }
            />
          </Label>
          <Label htmlFor="age">
            Age{!watch('clientAnonymous') && ' *'}
            <Controller
              data-cy="age-input"
              name="age"
              defaultValue={watch('age')}
              control={control}
              render={({ field: { onChange, value, ...rest } }) => (
                <Select
                  options={AgeOptions}
                  selected={value}
                  onChange={onChange}
                  disabled={!!watch('id') || !!watch('isDependent')}
                  {...rest}
                />
              )}
              rules={{
                required: {
                  value: !watch('clientAnonymous'),
                  message: 'This is required.',
                },
              }}
            />
            <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
              {errors.age?.message?.toString()}
            </Typography>
          </Label>
        </Grid>
      </Flex>
      <Controller
        name="clientAnonymous"
        render={({ field: { value, onBlur } }) => (
          <Flex gap="2" align="center">
            <Checkbox
              onClick={() => handleAnonymousChange(value)}
              onBlur={onBlur}
              checked={value}
              disabled={!!watch('id')}
              id="clientAnonymous"
            />
            <Label htmlFor="clientAnonymous">
              Client doesn’t want to share further information
            </Label>
          </Flex>
        )}
      />
    </FormSection>
  );
};

export default EnterClientInformation;
