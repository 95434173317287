import { Typography } from '../../../styledComponents';

export const DomainWarningV2 = ({ domain }: { domain: string[] }) =>
  domain.length ? (
    <Typography
      css={{
        backgroundColor: '#FFE1E1',
        p: '$3',
        br: '$3',
        wordWrap: 'break-word',
      }}
    >
      Organisation selected has domain restriction on {domain.join(', ')} -
      Please check before proceeding
    </Typography>
  ) : null;
