import { CSS } from '@stitches/react';
import { useNavigate } from 'react-router-dom';
import { PathConfig } from '../../../config/PathConfig';
import Container from '../../components/container/Container';
import { Flex, Grid, Typography } from '../../styledComponents';

const CommonModuleBoxStyles: CSS = {
  height: '120px',
  transition: 'all linear 0.1s',
  cursor: 'pointer',
  '@hover': {
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    },
  },
};

const ResponderHomeTypography = ({ title }: { title: string }) => (
  <Typography size="2xl" css={{ color: '#ffff', fontWeight: 550 }}>
    {title}
  </Typography>
);

export const ResponderHomeV2 = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Grid columns={3} gap="4">
        <Flex
          justify="center"
          align="center"
          role="button"
          tabIndex={0}
          onClick={() =>
            navigate(
              `${PathConfig.responderV2.base}/${PathConfig.responderV2.incomingCall}`,
            )
          }
          css={{
            background: 'linear-gradient(to right, #e96443, #904e95)',
            ...CommonModuleBoxStyles,
          }}
        >
          <ResponderHomeTypography title="Incoming Calls" />
        </Flex>
        <Flex
          justify="center"
          align="center"
          role="button"
          tabIndex={0}
          onClick={() =>
            navigate(
              `${PathConfig.responderV2.base}/${PathConfig.responderV2.outgoingCall}`,
            )
          }
          css={{
            backgroundImage: 'linear-gradient(to right, #649173, #dbd5a4)',
            ...CommonModuleBoxStyles,
          }}
        >
          <ResponderHomeTypography title="Outgoing Calls" />
        </Flex>
        <Flex
          justify="center"
          align="center"
          role="button"
          tabIndex={0}
          onClick={() =>
            navigate(
              `${PathConfig.responderV2.base}/${PathConfig.responderV2.esclationsCheckings}`,
            )
          }
          css={{
            backgroundImage: 'linear-gradient(to right, #00d2ff, #3a7bd5)',
            ...CommonModuleBoxStyles,
          }}
        >
          <ResponderHomeTypography title="Escalations & Checkins" />
        </Flex>
        <Flex
          justify="center"
          align="center"
          role="button"
          tabIndex={0}
          onClick={() =>
            navigate(
              `${PathConfig.responderV2.base}/${PathConfig.responderV2.cispCall}`,
            )
          }
          css={{
            background: 'linear-gradient(to right, #43cea2, #185a9d)',
            ...CommonModuleBoxStyles,
          }}
        >
          <ResponderHomeTypography title="CISP Call" />
        </Flex>
        <Flex
          justify="center"
          align="center"
          role="button"
          tabIndex={0}
          onClick={() =>
            navigate(
              `${PathConfig.responderV2.base}/${PathConfig.responderV2.testCall}`,
            )
          }
          css={{
            background: 'linear-gradient(to right, #1d4350, #a43931)',
            ...CommonModuleBoxStyles,
          }}
        >
          <ResponderHomeTypography title="Test Call" />
        </Flex>
        <Flex
          justify="center"
          align="center"
          role="button"
          tabIndex={0}
          onClick={() =>
            navigate(`${PathConfig.responder}/${PathConfig.newCaseNote}`)
          }
          css={{
            backgroundImage: 'linear-gradient(to right, #134e5e, #71b280)',
            ...CommonModuleBoxStyles,
          }}
        >
          <ResponderHomeTypography title="Incoming Calls (OLD)" />
        </Flex>
      </Grid>
      <Flex
        justify="center"
        css={{
          width: '60%',
          mx: 'auto',
          mt: '$4',
          textAlign: 'center',
        }}
      >
        <Typography size="xl">
          A&nbsp;
          <span
            style={{
              fontSize: '1.5em',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
            }}
          >
            helpline
          </span>
          &nbsp;dashboard isn’t just a screen—it's a bridge between hope and
          help, connecting people when they need it most.
        </Typography>
      </Flex>
    </Container>
  );
};
