import { format } from 'date-fns';
import { Fragment } from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { UserECPRelations } from '../../../shared/constants/ECP';
import { RiskLevels } from '../../../shared/constants/Risk';

import {
  NoOrganisationCode,
  SessionCallStatus,
  SessionCallTypes,
} from '../../../shared/constants/Session';
import {
  AssessmentPayload,
  CaseNoteFormMeetingData,
} from '../../../shared/types/Session';
import { useGetClientECP } from '../../../utilities/hooks/queryHooks/client/UseGetAndCreateECP';
import { useGetAssessmentQuestions } from '../../../utilities/hooks/queryHooks/session/UseGetAssessmentQuestions';
import useAuth from '../../../utilities/hooks/UseAuth';
import LoadingView from '../../components/loadingView/LoadingView';
import {
  Box,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Label,
  Typography,
} from '../../styledComponents';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import { shouldShowField } from '../../../utilities/common/Session';

export interface IRiskAssessmentDetailsProps {
  risk?: (typeof RiskLevels)[keyof typeof RiskLevels];
  userId?: string;
  organisation?: number;
  clientAnonymous: boolean;
  followUpSession: boolean;
  meetingData: CaseNoteFormMeetingData | null;
  callType?: SessionCallTypes;
  sessionQuestionResponses?: AssessmentPayload[];
  callStatus?: SessionCallStatus;
}

const RiskAssessmentDetails = ({
  sessionData,
}: {
  sessionData: IRiskAssessmentDetailsProps;
}) => {
  const {
    data: assessmentQuestions,
    isLoading: areAssessmentQuestionsLoading,
  } = useGetAssessmentQuestions(sessionData?.risk);

  const {
    organisation,
    clientAnonymous,
    followUpSession,
    meetingData,
    callStatus,
    callType,
  } = sessionData;

  const { user } = useAuth();

  const { data: ecpData, isLoading: isECPDataLoading } = useGetClientECP(
    sessionData?.userId,
  );

  const getBookingStatusText = () => {
    if (meetingData) {
      if (meetingData.type === 'virtual') {
        return 'You have booked a session for your client';
      }
      return 'You have raised a request for a F2F session for the client';
    }
    if (organisation === NoOrganisationCode) {
      return 'Cannot book session as user is not a part of an organisation';
    }
    if (clientAnonymous) {
      return "Client didn't share further information";
    }
    if (!followUpSession) {
      return 'Client didn’t want responder to book a coaching session';
    }

    return 'You have not booked a session for your client';
  };

  // const getMeetingStatusText = () => {
  //   if (sessionData.meeting) {
  //     return 'You have booked a session for your client.';
  //   }
  //   if (!sessionData?.userId) {
  //     return 'A session was not booked as the client was not a part of an organisation.';
  //   }
  //   if (!sessionData.meetingType) {
  //     return 'You have not booked a session for your client.';
  //   }
  //   if (sessionData.meetingType === 'f2f') {
  //     return 'You have raised a request for a F2F session for the client.';
  //   }
  //   return 'You have not booked a session for your client.';
  // };

  const shouldShow = (field: keyof CaseNoteFormTypesV2) =>
    shouldShowField({ field, callStatus, callType });

  // Return null in case of CISP & Test Call
  if (shouldShow('risk')) return null;

  return (
    <>
      <Heading size="sm" css={{ fontWeight: '600' }}>
        Risk assessment details
      </Heading>
      {[RiskLevels.High, RiskLevels.HighCritical].some(
        (r) => r === sessionData.risk,
      ) ? (
        areAssessmentQuestionsLoading || !assessmentQuestions ? (
          <LoadingView size="md" />
        ) : (
          <Grid columns={2} gap="3" css={{ my: '$3' }}>
            {assessmentQuestions.map((assessment) => {
              const questionToFind =
                sessionData?.sessionQuestionResponses?.find(
                  (question) => question.questionId === assessment.id,
                );
              return (
                <Flex key={assessment.id} gap="2" align="center">
                  <Checkbox
                    disabled
                    checked={questionToFind?.response === '1'}
                  />
                  <Label>
                    {questionToFind ? (
                      <>
                        {assessment.question}
                        {assessment.isRequired ? (
                          <span style={{ color: 'red' }}>*</span>
                        ) : null}
                      </>
                    ) : (
                      'Assessment question unavailable'
                    )}
                  </Label>
                </Flex>
              );
            })}
          </Grid>
        )
      ) : null}

      {[
        RiskLevels.Low,
        RiskLevels.Medium,
        RiskLevels.High,
        RiskLevels.Other,
      ].some((r) => r === sessionData.risk) ? (
        <>
          <Flex
            align="center"
            gap="3"
            css={{
              backgroundColor: '$gray75',
              br: '$2',
              p: '$2',
              border: '1px solid $gray200',
              color: '$gray600',
              mt: '$2',
              fontSize: '$2',
            }}
          >
            <IoInformationCircleOutline size={22} />
            {getBookingStatusText()}
          </Flex>
          {sessionData?.meetingData && (
            <Grid gap="6" columns={3} css={{ p: '$4' }}>
              <Box>
                <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                  Coach Name
                </Typography>
                <Typography size="xs" css={{ fontWeight: '500' }}>
                  {sessionData?.meetingData?.data?.providerName ??
                    'Provider name unavailable'}
                </Typography>
              </Box>
              <Box>
                <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                  Booking date
                </Typography>

                {sessionData?.meetingData?.data?.meeting.map(
                  ({ scheduledStartTime, scheduledEndTime }) => (
                    <Typography size="xs" css={{ fontWeight: '500' }}>
                      {format(
                        new Date(Number(scheduledStartTime) * 1000),
                        'dd MMM, yy',
                      )}
                      {' - '}
                      {format(
                        new Date(Number(scheduledStartTime) * 1000),
                        'h:mm aaa',
                      )}
                      {' - '}
                      {format(
                        new Date(Number(scheduledEndTime) * 1000),
                        'h:mm aaa',
                      )}
                    </Typography>
                  ),
                )}
              </Box>
            </Grid>
          )}
        </>
      ) : null}

      {isECPDataLoading || !ecpData || user?.addOns?.isCareNavigator ? null : (
        <>
          <Heading size="sm" css={{ fontWeight: '600', mb: '$3', mt: '$5' }}>
            Emergency contact details
          </Heading>
          <Grid columns={3} align="center" gap="3">
            {ecpData.length ? (
              ecpData.map((contact, index) => (
                <Fragment key={contact.userId}>
                  <Typography
                    size="sm"
                    css={{
                      gridColumn: '1 / span 3',
                      fontWeight: 500,
                    }}
                  >
                    Contact {index + 1}
                  </Typography>
                  <div>
                    <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                      Name
                    </Typography>
                    <Typography size="xs" css={{ fontWeight: '500' }}>
                      {contact.name}
                    </Typography>
                  </div>
                  <div>
                    <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                      Phone number
                    </Typography>
                    <Typography size="xs" css={{ fontWeight: '500' }}>
                      {contact.contact}
                    </Typography>
                  </div>
                  <div>
                    <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                      Relationship
                    </Typography>
                    <Typography size="xs" css={{ fontWeight: '500' }}>
                      {Object.entries(UserECPRelations).find(
                        ([_name, id]) => id === contact.relation,
                      )?.[0] || 'N/A'}
                    </Typography>
                  </div>
                </Fragment>
              ))
            ) : (
              <Typography>N/A</Typography>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default RiskAssessmentDetails;
