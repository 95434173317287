import * as PopoverPrimitive from '@radix-ui/react-popover';
import { ReactNode } from 'react';
import { IoCloseOutline } from 'react-icons/io5';

import { keyframes, styled, theme } from '../../stitches.config';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const PopoverContent = styled(PopoverPrimitive.Content, {
  borderRadius: '$2',
  padding: 20,
  minWidth: 260,
  backgroundColor: 'white',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
  '&:focus': {
    boxShadow: `hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px ${theme.colors.gray100.value}`,
  },
  zIndex: '$max',
});

const PopoverArrow = styled(PopoverPrimitive.Arrow, {
  fill: 'white',
});

const PopoverClose = styled(PopoverPrimitive.Close, {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 20,
  width: 20,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$gray500',
  position: 'absolute',
  top: 5,
  right: 5,

  '&:hover': { backgroundColor: '$gray200' },
  '&:focus': { boxShadow: '0 0 0 2px $gray500' },
});

export const Popover = ({
  render,
  children,
}: {
  render: ReactNode;
  children: ReactNode;
}) => (
  <PopoverPrimitive.Root>
    <PopoverPrimitive.Trigger asChild>{render}</PopoverPrimitive.Trigger>
    <PopoverPrimitive.Portal>
      <PopoverContent sideOffset={5}>
        {children}
        <PopoverClose aria-label="Close">
          <IoCloseOutline />
        </PopoverClose>
        <PopoverArrow />
      </PopoverContent>
    </PopoverPrimitive.Portal>
  </PopoverPrimitive.Root>
);
