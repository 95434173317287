/* eslint-disable react/no-array-index-key */
import { useCombobox } from 'downshift';
import * as React from 'react';
import { Noop } from 'react-hook-form';
import {
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoCloseOutline,
} from 'react-icons/io5';

import { IconButton } from './IconButton';
import { Input } from './Input';
import { Menu, MenuItem } from './Menu';
import { Typography } from './Typography';
import {
  ComboboxCategorizedOptions,
  ComboboxOption,
} from '../../shared/types/SelectTypes';

const defaultFilterFunction = (
  items: ComboboxOption[],
  newInputString: string,
) =>
  items.filter(
    (item) =>
      item.label.toLowerCase().includes(newInputString) ||
      item.value.toLowerCase().includes(newInputString),
  );

type ComboboxProps = {
  selectedValue?: string;
  onOptionSelect: (newValue?: string) => void;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  onBlur?: Noop;
  shouldClearOnChange?: boolean;
  shouldOverrideOnChange?: boolean;
} & (
  | {
      async: true;
      onInputChange: (newValue?: string) => void;
      inputValue: string;
      filterFunction?: never;
    }
  | {
      async?: undefined;
      filterFunction?: (
        items: ComboboxOption[],
        newInputString: string,
      ) => ComboboxOption[];
      onInputChange?: never;
      inputValue?: never;
    }
) &
  (
    | {
        createable: true;
        onCreate: (selectedOption: string) => void;
        createablePlaceholder: string;
      }
    | {
        createable?: undefined;
        onCreate?: never;
        createablePlaceholder?: never;
      }
  ) &
  (
    | {
        categorized: true;
        options: ComboboxCategorizedOptions;
      }
    | {
        categorized?: undefined;
        options?: ComboboxOption[];
      }
  );

export const Combobox = React.forwardRef<HTMLInputElement, ComboboxProps>(
  (
    {
      selectedValue,
      async,
      onOptionSelect,
      inputValue: inputStringFromProps,
      onInputChange,
      filterFunction = defaultFilterFunction,
      placeholder,
      createable,
      onCreate,
      categorized,
      options,
      disabled,
      name,
      onBlur,
      shouldClearOnChange = false,
      shouldOverrideOnChange = false,
    },
    ref,
  ) => {
    const [originalOptions, setOriginalOptions] = React.useState<
      ComboboxOption[]
    >(
      (categorized ? options.flatMap((section) => section.options) : options) ??
        [],
    );
    const [isCreating, setIsCreating] = React.useState<boolean>(false);
    const [inputValue, setInputValue] = React.useState<string>(
      inputStringFromProps ?? '',
    );
    const [items, setItems] = React.useState<ComboboxOption[]>(originalOptions);

    const {
      isOpen,
      getToggleButtonProps,
      getMenuProps,
      getInputProps,
      getItemProps,
      highlightedIndex,
      selectItem,
    } = useCombobox<ComboboxOption>({
      onInputValueChange({ inputValue: newInputValue }) {
        // Allow edits to input value only when there is no selected value.
        if (!selectedValue) {
          setInputValue(newInputValue || '');
          if (async) {
            onInputChange(newInputValue);
          } else {
            const filteredItems = filterFunction(
              originalOptions,
              newInputValue?.toLowerCase() || '',
            );

            if (isCreating && filteredItems.length > 0) {
              setIsCreating(false);
            }
            setItems(filteredItems);
          }
        }
      },
      items,
      itemToString(item) {
        return item ? item.label : '';
      },
      onStateChange: ({ type, selectedItem }) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            if (selectedItem) {
              if (createable && isCreating) {
                onCreate(selectedItem.value);
                setIsCreating(false);
              } else {
                onOptionSelect(selectedItem.value);
              }
            }
            break;
          default:
            break;
        }
      },
      inputId: `${name}-input`,
      menuId: `${name}-menu`,
      getItemId(index) {
        return `${name}-menu-item-${index}`;
      },
    });

    // If options changes on runtime
    React.useEffect(() => {
      setOriginalOptions(
        (categorized
          ? options.flatMap((section) => section.options)
          : options) ?? [],
      );
    }, [categorized, options]);

    React.useEffect(() => {
      setItems(originalOptions);
    }, [originalOptions]);

    // If createable, show the 'Create new...' option if no options are left
    React.useEffect(() => {
      if (createable && items?.length === 0 && inputValue?.length > 0) {
        setIsCreating(true);
        setItems([
          {
            label: inputValue,
            value: inputValue,
          },
        ]);
      }
    }, [createable, inputValue, items?.length]);

    // If async=true, refresh items for each update of options.
    React.useEffect(() => {
      if (async) {
        setItems(
          options
            ? categorized
              ? options.flatMap((section) => section.options)
              : options
            : [],
        );
        setIsCreating(false);
      }
      //   else {
      //     setOrigOptions(
      //       categorized
      //         ? Object.values(options).flatMap((option) => option)
      //         : options,
      //     );
      //   }
    }, [async, categorized, options]);

    // If async=true, update inputValue for each update of options.
    React.useEffect(() => {
      if (async) {
        setInputValue(inputStringFromProps);
      }
    }, [async, inputStringFromProps]);

    /**
     * Ensures the text input value is cleared when `selectedValue` is manually cleared.
     * Previously, the text input retained its value even after `selectedValue` was cleared.
     * but only applies if `shouldClearOnChange` is passed in props to avoid affecting existing usages.
     */
    React.useEffect(() => {
      if (!selectedValue && shouldClearOnChange) {
        setInputValue('');
      }

      if (selectedValue && shouldOverrideOnChange) {
        setInputValue(selectedValue);
      }
    }, [selectedValue, shouldClearOnChange, shouldOverrideOnChange]);

    const clearSelected = () => {
      onOptionSelect('');
      setInputValue('');
      selectItem(null);
      if (async) {
        onInputChange('');
      }
      setItems(originalOptions);
    };

    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <Input
          data-cy={placeholder}
          ref={ref}
          name={name}
          onBlur={onBlur}
          placeholder={placeholder ?? 'Select an item...'}
          endElement={
            <>
              <div {...getToggleButtonProps({ disabled })}>
                {isOpen ? (
                  <IoChevronUpOutline size={18} />
                ) : (
                  <IoChevronDownOutline size={18} />
                )}
              </div>
              {!!selectedValue && !disabled && (
                <IconButton
                  id={`clear-selected-${name}`}
                  type="button"
                  onClick={clearSelected}
                  size="sm"
                >
                  <IoCloseOutline />
                </IconButton>
              )}
            </>
          }
          {...getInputProps({
            value:
              (
                inputValue ||
                items?.find((item) => item.value === selectedValue)?.label
              )?.replace('@bytedance.com.care', '@bytedance.com') ?? '',
            disabled,
            name,
            onBlur,
          })}
        />
        <Menu
          css={{
            position: 'absolute',
            width: '100%',
            marginTop: '-0.5rem',
            maxHeight: '35rem',
            overflowY: 'auto',
            ...(!(isOpen && items?.length) && {
              display: 'none',
            }),
          }}
          {...getMenuProps()}
        >
          {isOpen &&
            (categorized
              ? // If categorized=true, show categories along with the
                // options under that category which exist in the items array.

                options.reduce(
                  (results, section, sectionIndex) => {
                    if (
                      section.options.some((option) =>
                        items.find((item) => item.value === option.value),
                      )
                    ) {
                      const filteredOptions = section.options.filter(
                        (sectionOption) =>
                          items.find(
                            (item) => item.value === sectionOption.value,
                          ),
                      );
                      results.sections.push(
                        <div key={sectionIndex}>
                          <Typography
                            color="gray900"
                            size="lg"
                            css={{ fontWeight: '500', p: '$3' }}
                          >
                            {section.title}
                          </Typography>
                          {filteredOptions.map((option, optionIndex) => {
                            // eslint-disable-next-line no-plusplus, no-param-reassign
                            const resultIndex = results.itemIndex++;

                            return (
                              <MenuItem
                                key={optionIndex}
                                css={{
                                  pl: '$6',
                                  '& > span': {
                                    fontSize: '$3',
                                    lineHeight: 1.5,
                                  },
                                  ...(selectedValue === option.value && {
                                    backgroundColor: '$gray100',
                                  }),
                                  ...(highlightedIndex === resultIndex && {
                                    backgroundColor: '$gray50',
                                  }),
                                }}
                                {...getItemProps({
                                  item: option,
                                  index: resultIndex,
                                })}
                              >
                                {isCreating ? (
                                  <Typography>
                                    Create "{option.value}"
                                  </Typography>
                                ) : (
                                  <Typography>{option.label}</Typography>
                                )}
                              </MenuItem>
                            );
                          })}
                        </div>,
                      );
                    }
                    return results;
                  },
                  { sections: [] as JSX.Element[], itemIndex: 0 },
                ).sections
              : // Else show options normally.
                items?.map((item, index) => (
                  <MenuItem
                    css={{
                      '& > span': {
                        fontSize: '$3',
                        lineHeight: 1.5,
                      },
                      ...(selectedValue === item.value && {
                        backgroundColor: '$gray100',
                      }),
                      ...(highlightedIndex === index && {
                        backgroundColor: '$gray50',
                      }),
                    }}
                    data-cy={`DropDown-Item-${index}`}
                    key={`${item.value}${index}`} // eslint-disable-line react/no-array-index-key
                    {...getItemProps({ item, index })}
                  >
                    {isCreating ? (
                      <Typography>Create "{item.value}"</Typography>
                    ) : (
                      <Typography>
                        {item.label.replace(
                          '@bytedance.com.care',
                          '@bytedance.com',
                        )}
                      </Typography>
                    )}
                  </MenuItem>
                )))}
        </Menu>
      </div>
    );
  },
);
