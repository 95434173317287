import { FormProvider, useForm } from 'react-hook-form';
import { HiOutlineClock } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import {
  NoOrganisationCode,
  SessionCallTypes,
} from '../../../shared/constants/Session';
import {
  CaseNoteFormTypesV2,
  MultiPurposeCallDefaultValues,
} from '../../../shared/types/CaseNoteFormV2';
import { CaseNoteSession } from '../../../shared/types/Session';
import { useGetSessionData } from '../../../utilities/hooks/queryHooks/session/UseGetSessionData';
import CommonFormCard from '../../app-components/CommonForm/CommonFormCard';
import FormConfigsProvider from '../../app-components/FormConfigsProvider/FormConfigsProvider';
import FormConfirmationV2 from '../../app-components/FormConfirmationV2/FormConfirmationV2';
import PreventNavigationWrapper from '../../app-components/PreventNavigationWrapper/PreventNavigationWrapper';
import LoadingView from '../../components/loadingView/LoadingView';
import { Flex, Heading } from '../../styledComponents';
import CaseNoteTimer from '../caseNotesForm/CaseNoteTimer';

// Listening on these values, if any of these changes then navigation will be prevented
const preventNavigationDefaultValues = {
  userId: MultiPurposeCallDefaultValues.userId,
  phone: MultiPurposeCallDefaultValues.phone,
  name: MultiPurposeCallDefaultValues.name,
  organisation: undefined,
  cispCallType: undefined,
  testCallType: undefined,
};

const MultiPurposeCallFormBase = ({
  callType,
}: {
  callType: SessionCallTypes.CISP_CALL | SessionCallTypes.TEST_CALL;
}) => {
  const { sessionId } = useParams();
  const methods = useForm<CaseNoteFormTypesV2>({
    defaultValues: {
      ...MultiPurposeCallDefaultValues,
      startTime: new Date(),
      callType,
    },
    mode: 'onTouched',
  });

  const onSessionDetailFetchSuccess = (data: CaseNoteSession) => {
    methods.reset({
      ...data.client,
      originalPhone: data?.client?.phone ?? '',
      organisation: +(data.client.organisation || NoOrganisationCode),
      issue: data.issue,
      secondaryIssue: data.secondaryIssue,
      risk: data.risk,
      sessionId: data.id,
      assessment: data.sessionQuestionResponses,
      callStatus: data.callStatus,
      callAttempts: data.callAttempts,
      escalationCallType: data.escalationCallType,
      outgoingCallType: data.outgoingCallType,
      cispCallType: data.cispCallType,
      clientPosition: data.clientPosition,
      testCallType: data.testCallType,
      callType,
    });
  };
  const { data: sessionData, isLoading: isSessionDetailsLoading } =
    useGetSessionData(
      sessionId,
      Boolean(sessionId),
      onSessionDetailFetchSuccess,
    );

  if (sessionId && (isSessionDetailsLoading || !sessionData)) {
    return <LoadingView />;
  }

  const heading =
    callType === SessionCallTypes.CISP_CALL ? 'CISP call' : 'Test call';

  return (
    <FormProvider {...methods}>
      <FormConfigsProvider>
        <Flex
          direction="column"
          gap="4"
          css={{ padding: '$6', width: '90%', margin: 'auto' }}
        >
          <Flex justify="between" align="center">
            <Flex align="center" justify="center" gap="2">
              <Heading size="md">{heading}</Heading>
              {!sessionId && (
                <Flex align="center" gap="1">
                  <HiOutlineClock size={18} />
                  <CaseNoteTimer size="md" />
                </Flex>
              )}
            </Flex>
          </Flex>
          <CommonFormCard />
          <FormConfirmationV2 />
          <PreventNavigationWrapper
            defaultValues={preventNavigationDefaultValues}
          />
        </Flex>
      </FormConfigsProvider>
    </FormProvider>
  );
};

export default MultiPurposeCallFormBase;
