import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import 'react-phone-input-2/lib/bootstrap.css';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import { getDisplayDate } from '../../../utilities/common/Date';
import { useGetOrganisationsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import UnOrderedList from '../../components/UnOrderedList/UnOrderedList';
import { Box, Flex, Typography } from '../../styledComponents';
import { GridItem } from '../../styledComponents/GridItem';
import { useFormConfigsContext } from '../FormConfigsProvider/FormConfigsProvider';
import ClientCallLogsCard from './ClientCallLogs/ClientCallLogsCard';
import ClientCreditsCard from './ClientCredits/ClientCreditsCard';
import CreditLineItem from './ClientCredits/CreditLineItem';
import ClientNotesCard from './ClientNotes/ClientNotesCard';
import ClientPhoneNumberInput from './ClientPhoneNumberInput';
import ClientPresentingIssueCard from './ClientPresentingIssue/ClientPresentingIssueCard';
import ClientRiskAssessmentsCard from './ClientRiskAssessments/ClientRiskAssessmentsCard';
import EmergencyContactsInline from './EmergencyContacts/EmergencyContactsInline';

const ClientDetails = () => {
  const { watch } = useFormContext<CaseNoteFormTypesV2>();
  const { data: organisationsList } = useGetOrganisationsList();
  const { name, organisation, phone } = watch();
  const { isEditing } = useFormConfigsContext();

  // TODO can we get this from backend?
  const orgName = useMemo(
    () =>
      organisationsList?.find((item) => item?.id === organisation)?.name ??
      'N/A',
    [organisation, organisationsList],
  );

  const ClientProfileDetails = useMemo(() => {
    const items: { label: string }[] = [];
    const { clientCreatedOn, age, gender } = watch();

    if (clientCreatedOn) {
      items.push({
        label: `Created on ${getDisplayDate(new Date(clientCreatedOn))}`,
      });
    }
    if (age && age !== 'NA') {
      items.push({
        label: `${age} yrs`,
      });
    }
    if (gender) {
      items.push({
        label: gender,
      });
    }
    return items;
  }, [watch]);

  return (
    <GridItem xs={9} md={2} sm={2} lg={2}>
      <Box
        css={{
          padding: '$4',
          minHeight: '92vh',
          height: '100%',
          background: '$gray25',
          border: '1px solid $gray150',
        }}
      >
        <Flex direction="column" gap="6">
          <Flex direction="column" gap="2">
            <Typography size="xl">{name}</Typography>
            <UnOrderedList items={ClientProfileDetails} />
            <CreditLineItem label="Organisation" value={orgName} />

            {phone && !isEditing ? (
              <CreditLineItem label="Phone" value={`+${phone}`} />
            ) : (
              <ClientPhoneNumberInput />
            )}
          </Flex>

          <ClientCreditsCard />
          <ClientNotesCard />
          <ClientPresentingIssueCard />
          <ClientRiskAssessmentsCard />

          <EmergencyContactsInline />
          {phone ? <ClientCallLogsCard phone={phone} /> : null}
        </Flex>
      </Box>
    </GridItem>
  );
};

export default ClientDetails;
