import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { PathConfig } from '../../../config/PathConfig';
import { SessionEvent } from '../../../shared/constants/events/SessionDetail';
import { QueryKeys } from '../../../shared/constants/QueryKeys';
import { useCreateCommentV2 } from '../../../utilities/hooks/queryHooks/session/UseCreateComment';
import { useGetSessionCommentsV2 } from '../../../utilities/hooks/queryHooks/session/UseGetSessionComments';
import useAuth from '../../../utilities/hooks/UseAuth';

import useTracking from '../../../utilities/hooks/UseTracking';
// import LoadingView from '../../components/loadingView/LoadingView';
import {
  CaseNoteTypes,
  SessionCallTypes,
} from '../../../shared/constants/Session';
import NotesCard from '../../screens/sessionDetail/NotesCard';
import {
  Box,
  Button,
  Flex,
  TextArea,
  Typography,
} from '../../styledComponents';
import LoadingView from '../../components/loadingView/LoadingView';

const Comments = ({
  showCommentButton,
  callType,
}: {
  showCommentButton: boolean;
  callType?: SessionCallTypes;
}) => {
  const [showCommentBox, setShowCommentBox] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>('');

  const { sessionId } = useParams();
  const { track } = useTracking<SessionEvent>();
  const { user } = useAuth();

  const {
    data: comments,
    isSuccess,
    isLoading,
    isRefetching,
    refetch: refetchComments,
  } = useGetSessionCommentsV2(sessionId, CaseNoteTypes.COMMENT);

  const queryClient = useQueryClient();

  const onCommentCreateSuccess = () => {
    track('save_comment', {
      eventAction: 'click',
      eventCategory: 'save_comment',
      eventLabel: 'save_comment',
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKeys.getSessionCommentsV2, sessionId],
      exact: true,
    });
    setCommentText('');
    setShowCommentBox(false);
    refetchComments();
  };

  const handleShowCommentBox = () => {
    track('add_comment', {
      eventAction: 'click',
      eventCategory: 'add_comment',
      eventLabel: 'add_comment',
    });
    setShowCommentBox(true);
  };

  const { isLoading: isCreateCommentLoading, mutate: mutateComment } =
    useCreateCommentV2(onCommentCreateSuccess);

  const SessionEditURL = useMemo(() => {
    const midUrl = {
      [SessionCallTypes.INCOMING_CALL]: PathConfig.responderV2.incomingCall,
      [SessionCallTypes.OUTGOING_CALL]: PathConfig.responderV2.outgoingCall,
      [SessionCallTypes.ESCALATIONS_CHECKINS_CALL]:
        PathConfig.responderV2.esclationsCheckings,
      [SessionCallTypes.CISP_CALL]: PathConfig.responderV2.cispCall,
      [SessionCallTypes.TEST_CALL]: PathConfig.responderV2.testCall,
    };

    return `${PathConfig.responderV2.base}/${midUrl?.[callType ?? SessionCallTypes.INCOMING_CALL]}/${sessionId}${PathConfig.editSession}`;
  }, [sessionId, callType]);

  return (
    <Box css={{ mt: '$2' }}>
      {isLoading || isRefetching ? (
        <LoadingView size="md" />
      ) : isSuccess && comments?.length ? (
        <Box css={{ px: '$1' }}>
          <Typography size="2xl" css={{ m: 0, p: 0 }}>
            Comments
          </Typography>
          <Box css={{ mt: '$2', px: '$1' }}>
            {comments?.map((comment) => {
              const key = `session-${sessionId}-comments`;
              console.log('inside comments');
              return <NotesCard caseNote={comment} key={key} />;
            })}
          </Box>
        </Box>
      ) : null}

      {showCommentBox ? (
        <Box>
          <TextArea
            size="lg"
            css={{ mb: '$2', minHeight: '10rem' }}
            placeholder="Add your comment here..."
            onChange={(event) => setCommentText(event.target.value)}
            value={commentText}
          />
          <Flex justify="end" gap="3">
            <Button
              id="hide-comment-box"
              onClick={() => {
                setShowCommentBox(false);
                setCommentText('');
              }}
              size="lg"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              id="confirm-add-comment"
              onClick={() =>
                mutateComment({
                  sessionId: sessionId ?? '',
                  note: commentText.trim(),
                })
              }
              size="lg"
              isLoading={isCreateCommentLoading}
              disabled={!commentText.trim()}
              loadingText="Submitting..."
            >
              Save
            </Button>
          </Flex>
        </Box>
      ) : (
        <Flex justify="end" css={{ mt: '$2' }} gap="3">
          {user.addOns?.isCareNavigator ? null : (
            <Link
              to={SessionEditURL}
              onClick={() =>
                track('view_detail', {
                  eventAction: 'click',
                  eventCategory: 'edit_information',
                  eventLabel: 'edit_information',
                })
              }
            >
              <Button id="edit-session-button" size="lg" variant="outlined">
                Edit information
              </Button>
            </Link>
          )}

          {showCommentButton ? (
            <Button
              id="show-comment-box"
              onClick={handleShowCommentBox}
              size="lg"
              variant="outlined"
            >
              Comment
            </Button>
          ) : null}
        </Flex>
      )}
    </Box>
  );
};

export default Comments;
