export enum ClientRiskLevelsEnum {
  NO_RISK = '0',
  LOW_RISK = '1',
  MED_RISK = '2',
  HIGH_CRITICAL_RISK = '3',
  HIGH_NON_CRITICAL_RISK = '4',
  RISK_NOT_ASSESSED = '5',
  OTHER = '6',
}

export const ClientRiskLevelLabelMapping = {
  [ClientRiskLevelsEnum.NO_RISK]: 'No Risk',
  [ClientRiskLevelsEnum.LOW_RISK]: 'Low Risk',
  [ClientRiskLevelsEnum.MED_RISK]: 'Medium Risk',
  [ClientRiskLevelsEnum.HIGH_CRITICAL_RISK]: 'High Critical Risk',
  [ClientRiskLevelsEnum.HIGH_NON_CRITICAL_RISK]: 'High Non Critical Risk',
  [ClientRiskLevelsEnum.RISK_NOT_ASSESSED]: 'Risk Not Assessed',
  [ClientRiskLevelsEnum.OTHER]: 'Other',
};
