import { motion } from 'framer-motion';
import { ReactNode, useEffect } from 'react';

import { IoCloseOutline } from 'react-icons/io5';
import Backdrop from '../backdrop/Backdrop';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Typography,
} from '../../styledComponents';

const MotionFlex = motion(Flex);

const dropIn = {
  hidden: {
    y: '100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.25,
      type: 'tween',
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};

const Modal = ({
  title,
  subtitle,
  handleClose,
  handleSubmit,
  open,
  children,
  confirmButtonColor = 'metal',
  confirmButtonText = 'Submit',
  confirmButtonDisabled,
  confirmLoading,
  hideButtonRow,
  showXButtonToCloseInstead,
}: {
  title: string;
  subtitle?: string;
  handleClose: () => void;
  handleSubmit?: (params?: unknown) => void;
  open: boolean;
  children?: ReactNode;
  confirmButtonColor?: 'red' | 'green' | 'metal';
  confirmButtonText?: string;
  confirmButtonDisabled?: boolean;
  confirmLoading?: boolean;
  hideButtonRow?: boolean;
  /** Show the "X" button on top right to close the modal; hides the Go Back button */
  showXButtonToCloseInstead?: boolean;
}) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <Backdrop open={open} onClick={handleClose}>
      <MotionFlex
        direction="column"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        transition={{ type: 'tween' }}
        exit="exit"
        css={{
          backgroundColor: 'white',
          color: '$gray900',
          maxHeight: '90%',
          minWidth: '50%',
          maxWidth: '80%',
          opacity: 1,
          borderRadius: '0.5rem',
        }}
      >
        {showXButtonToCloseInstead ||
        (!showXButtonToCloseInstead && hideButtonRow) ? (
          <Flex align="start" justify="end" css={{ p: '$1' }}>
            <IconButton type="button" onClick={handleClose}>
              <IoCloseOutline size={26} />
            </IconButton>
          </Flex>
        ) : null}

        <Box
          css={{
            p: `$${showXButtonToCloseInstead || (!showXButtonToCloseInstead && hideButtonRow) ? 1 : 6} $6 $3`,
          }}
        >
          <Typography size="2xl" css={{ fontWeight: '600', mb: '$2' }}>
            {title}
          </Typography>
          <Typography size="lg" color="gray500">
            {subtitle}
          </Typography>
        </Box>
        {children ? (
          <Box
            css={{
              p: '$3 $6',
              flexGrow: 1,
              overflow: 'auto',
            }}
          >
            {children}
          </Box>
        ) : null}

        {hideButtonRow ? null : (
          <Flex
            align="center"
            justify="center"
            gap="5"
            css={{
              width: '100%',
              borderTop: '1px solid $gray200',
              p: '$5 $6',
            }}
          >
            {showXButtonToCloseInstead ? null : (
              <Button
                id={`modal-cancel-${title}`}
                size="lg"
                variant="outlined"
                css={{ width: '15rem' }}
                type="button"
                onClick={handleClose}
              >
                Go back
              </Button>
            )}

            <Button
              id={`modal-confirm-${title}`}
              color={confirmButtonColor}
              size="lg"
              type="button"
              css={{ width: '15rem' }}
              onClick={() => handleSubmit && handleSubmit()}
              isLoading={confirmLoading}
              disabled={confirmButtonDisabled}
            >
              {confirmButtonText}
            </Button>
          </Flex>
        )}
      </MotionFlex>
    </Backdrop>
  );
};

export default Modal;
