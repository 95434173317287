import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';

export const useGetCispTypesList = (enabled = true) =>
  useQuery<string[]>(
    [QueryKeys.getCispTypesList],
    () =>
      doGet(ApiConfig.api.sessions.getCispTypesList).then(
        (res) => res?.data?.data ?? [],
      ),
    {
      onError: () => {
        toast.error('An error occurred while getting the cisp types');
      },
      enabled,
      refetchOnWindowFocus: false,
      cacheTime: 10 * 60 * 1000, // 10 minutes
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  );
