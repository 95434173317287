import { range } from '../../utilities/common/Array';
import { ValueOf } from '../types/Common';

export const SessionMaxDurationBeforeWarning: number = 50; // In minutes

export const CaseNoteMinimumCharacters = 30;

export const NotesMinCharacters = 2;
export const NotesMaxCharacters = 5000;

export const AgeOptions = [
  { label: 'Did not disclose', value: 'NA' },

  // Generate options for an age range from 12-64
  ...range(53, 12).map((val) => ({ label: String(val), value: String(val) })),

  { label: '65+', value: '66' },
];

export const TenureNotDisclosedValue = 'no-disclose';

export const TenureOptions = [
  { label: 'Did not disclose', value: TenureNotDisclosedValue },
  { label: 'Under 1 year', value: '<1' },
  { label: '1-2 years', value: '1-2' },
  { label: '2-3 years', value: '2-3' },
  { label: '3-5 years', value: '3-5' },
  { label: '5+ years', value: '>=5' },
  { label: 'N/A', value: 'N/A' },
];

export const NoOrganisationCode = -1;
export const IntellectOrganisationId = 1;

export const OrgMessageStatusCodes = [
  'DIFFERENT_ORG',
  'SELECTED_ORG',
  'NO_ORG',
  'DEPENDENT',
] as const;

export const OrgMessageStatuses = {
  SELECTED_ORG: 'User Found in Selected Organisation',
  DIFFERENT_ORG: 'User Found in Different Organisation',
  NO_ORG: 'No User Found in Selected Organisation',
  DEPENDENT: 'User Found as dependent',
} satisfies Record<(typeof OrgMessageStatusCodes)[number], string>;

export const SessionTypes = {
  Responder: 0,
  CareNav: 1,
} as const;

export enum SessionCallTypes {
  INCOMING_CALL = 1,
  OUTGOING_CALL = 2,
  ESCALATIONS_CHECKINS_CALL = 3,
  CISP_CALL = 4,
  TEST_CALL = 5,
}

export const SessionCallTypesColorVariants = {
  [SessionCallTypes.INCOMING_CALL]: 'success',
  [SessionCallTypes.OUTGOING_CALL]: 'error',
  [SessionCallTypes.ESCALATIONS_CHECKINS_CALL]: 'warning',
  [SessionCallTypes.CISP_CALL]: 'purple',
  [SessionCallTypes.TEST_CALL]: 'gray',
} as const satisfies Record<ValueOf<typeof SessionCallTypes>, string>;

export const SessionCallTypesLabels = {
  [SessionCallTypes.INCOMING_CALL]: 'Incoming call (NEW)',
  [SessionCallTypes.OUTGOING_CALL]: 'Outgoing call',
  [SessionCallTypes.ESCALATIONS_CHECKINS_CALL]: 'Escalations & checkins',
  [SessionCallTypes.CISP_CALL]: 'CISP call',
  [SessionCallTypes.TEST_CALL]: 'Test call',
};

export const SessionCallTypesFilterValues = [
  {
    label: 'ALL',
    value: 'all',
  },
  {
    label: SessionCallTypesLabels[SessionCallTypes.INCOMING_CALL],
    value: SessionCallTypes.INCOMING_CALL,
  },
  {
    label: 'Incoming call (OLD)',
    value: -1,
  },
  {
    label: SessionCallTypesLabels[SessionCallTypes.OUTGOING_CALL],
    value: SessionCallTypes.OUTGOING_CALL,
  },
  {
    label: SessionCallTypesLabels[SessionCallTypes.ESCALATIONS_CHECKINS_CALL],
    value: SessionCallTypes.ESCALATIONS_CHECKINS_CALL,
  },
  {
    label: SessionCallTypesLabels[SessionCallTypes.CISP_CALL],
    value: SessionCallTypes.CISP_CALL,
  },
  {
    label: SessionCallTypesLabels[SessionCallTypes.TEST_CALL],
    value: SessionCallTypes.TEST_CALL,
  },
];

export enum SessionStatus {
  STARTED = '0',
  COMPLETED = '1',
  CANCELLED = '2',
}

export const SessionStatusLabels = {
  [SessionStatus.STARTED]: 'In-progress',
  [SessionStatus.COMPLETED]: 'Completed',
  [SessionStatus.CANCELLED]: 'Cancelled',
};

export const SessionStatusColorVariants = {
  [SessionStatus.STARTED]: 'warning',
  [SessionStatus.COMPLETED]: 'success',
  [SessionStatus.CANCELLED]: 'error',
} as const satisfies Record<ValueOf<typeof SessionStatus>, string>;

export enum SessionCallStatus {
  NOT_ANSWERED = 0,
  ANSWERED = 1,
}

export enum CaseNoteTypes {
  NOTE = 1,
  CALL_REASON = 2,
  CALL_DESCRIPTION = 3,
  RANGER_INTERVENTION = 4,
  COMMENT = 5,
}

export enum EscalationFormTypes {
  PROVIDER_ESCALATION = 'provider_escalation',
  HIGH_RISK_CHECKIN = 'high_risk_checkin',
  WELL_BEING_CHECKIN = 'well_being_checkin',
}

export const EscalationFormTypesLabels = {
  [EscalationFormTypes.PROVIDER_ESCALATION]: 'Provider escalation',
  [EscalationFormTypes.HIGH_RISK_CHECKIN]: 'High risk checkin',
  [EscalationFormTypes.WELL_BEING_CHECKIN]: 'Well being checkin',
};

export enum OutgoingFormTypes {
  MISSED_CALL = 'missed_call',
  RECONFIRM_USER_INFO = 'reconfirm_user_info',
  DISCONNECTED_CALL = 'disconnected_call',
  RECONFIRM_USER_REQUEST = 'reconfirm_user_request',
  LOCAL_RANGER_REQUEST = 'local_ranger_request',
}

export const OutgoingFormTypesLabels = {
  [OutgoingFormTypes.MISSED_CALL]: 'Missed call',
  [OutgoingFormTypes.RECONFIRM_USER_INFO]: 'Reconfirm user info',
  [OutgoingFormTypes.DISCONNECTED_CALL]: 'Disconnected call',
  [OutgoingFormTypes.RECONFIRM_USER_REQUEST]: 'Reconfirm user request',
  [OutgoingFormTypes.LOCAL_RANGER_REQUEST]: 'Local ranger request',
};

export enum TestFormTypes {
  WEEKLY_PLATFORM = 'weekly_platform',
  INTERNAL_QUALITY = 'internal_quality',
  EXTERNAL_QUALITY = 'external_quality',
}

// export const TestFormTypesLabels = [
//   { label: 'Weekly Platform', value: TestFormTypes.WEEKLY_PLATFORM },
//   { label: 'Internal Quality', value: TestFormTypes.INTERNAL_QUALITY },
//   { label: 'External Quality', value: TestFormTypes.EXTERNAL_QUALITY },
// ];

export const TestFormTypesLabels = {
  [TestFormTypes.WEEKLY_PLATFORM]: 'Weekly Platform',
  [TestFormTypes.INTERNAL_QUALITY]: 'Internal Quality',
  [TestFormTypes.EXTERNAL_QUALITY]: 'External Quality',
};

export const CispDefaultTypes = [
  'Retrenchment',
  'Physical assaults',
  'Employee Misconduct (Harassment or Discrimination, Bullying, Sexual Misconduct)',
  'Workplace accidents or Injuries',
  'Natural Disasters',
  'Death of a teammate / colleague',
  'Others',
];
