import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IoSearchOutline } from 'react-icons/io5';
import 'react-phone-input-2/lib/bootstrap.css';
import { AgeOptions } from '../../../shared/constants/Session';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import { ClientInfoV2 } from '../../../shared/types/Client';
import {
  ExistingEmailUser,
  NewEmailUser,
} from '../../../shared/types/FetchByEmailUserData';
import { emailRegex } from '../../../utilities/common/FormValidatorRegex';
import { useGetRegionsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetRegionsList';
import { useGetUserFromEmail } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetUserFromEmail';
import {
  useGetUserFromNumber,
  useSearchUserDetailsByEmail,
  useSearchUserDetailsByPhone,
} from '../../../utilities/hooks/queryHooks/caseNotes/UseGetUserFromNumber';
import {
  Box,
  Button,
  Flex,
  Input,
  Label,
  Typography,
} from '../../styledComponents';
import { GridItem } from '../../styledComponents/GridItem';
import { useFormConfigsContext } from '../FormConfigsProvider/FormConfigsProvider';
import ClientPhoneNumberInput from './ClientPhoneNumberInput';
import { OrganisationMessageHandlerV2 } from './OrganisationMessageHandler/OrganisationMessageHandlerV2';
import ClientNewPhoneHandler from './ClientDiscrepancies/ClientNewPhoneHandler';
import ClientDifferentOrganisationHandler from './ClientDiscrepancies/ClientDifferentOrganisationHandler';

const FetchClientDetailsForm = () => {
  const {
    watch,
    formState: { errors },
    getValues,
    setValue,
    reset,
  } = useFormContext<CaseNoteFormTypesV2>();
  const { isEditing } = useFormConfigsContext();

  const { phone } = watch();

  const { data: regionsList } = useGetRegionsList();

  // Maintaining separate email state here to avoid conflicts between actual email and search email
  const [emailToSearch, setEmailToSearch] = useState<{
    value: string;
    errors: boolean;
    errorMessage: string;
    touched: boolean;
  }>({
    value: '',
    errors: false,
    touched: false,
    errorMessage: '',
  });

  useEffect(() => {
    if (!emailToSearch.touched) return;

    const isPhoneEmpty = !phone || Boolean(errors.phone);
    const isEmailEmpty = !emailToSearch.value;

    let emailErrors = '';
    let phoneErrors = '';

    if (isEmailEmpty) {
      emailErrors = 'This is required';
    } else if (!emailRegex.test(emailToSearch.value)) {
      emailErrors = 'Please enter a valid email';
    }

    if (isPhoneEmpty) {
      phoneErrors = 'Please enter phone to proceed';
    }

    setEmailToSearch((prev) => ({
      ...prev,
      errors: Boolean(emailErrors || phoneErrors),
      errorMessage: emailErrors || phoneErrors,
    }));

    // If email is valid, set the value in actual form email value
    if (!emailErrors) {
      setValue('email', emailToSearch.value);
    }
  }, [
    emailToSearch.touched,
    emailToSearch.value,
    errors.phone,
    setValue,
    phone,
  ]);

  const { data: userDetails, refetch: checkUserWithDifferentNumber } =
    useSearchUserDetailsByEmail({
      email: emailToSearch.value,
      onComplete: () => {
        setValue('previousCallWithDifferentNumberHandlerActive', true);
      },
    });

  // If client not found in selected org in below hook then try searching client in other orgs
  const {
    data: userDifferentOrgDetails,
    refetch: searchClientAnywhereByPhone,
  } = useSearchUserDetailsByPhone({
    phone: watch('phone'),
    onComplete: (data: ClientInfoV2) => {
      // If org is different than show a warning msg
      if (data.organisation) {
        if (data.organisation !== getValues().organisation) {
          setValue('previousCallWithDifferentOrganisationHandlerActive', true);
        } else {
          setValue('previousCallWithDifferentOrganisationHandlerActive', false);
        }
      }
    },
    onError: () => {
      setValue('previousCallWithDifferentOrganisationHandlerActive', false);
    },
    retry: false,
  });

  const {
    refetch: searchByPhone,
    isRefetching,
    isInitialLoading,
  } = useGetUserFromNumber({
    phone: watch('phone'),
    organisation: getValues().organisation,
    onComplete: (data: ClientInfoV2) => {
      const { location, city, age, ...rest } = data;
      reset({
        ...getValues(),
        ...rest,
        ...(AgeOptions.find((ageOption) => ageOption.value === age) && {
          age,
        }),
        ...(regionsList?.find(
          (regionOption) => regionOption.region === location,
        ) && { location, city, clientDetails: { location, city } }),
        email: data.email,
      });
    },
    onError: () => {
      searchClientAnywhereByPhone();
    },
    retry: false,
  });

  const {
    data: fetchedUserData,
    isFetching: isEmailSearchFetching,
    refetch: searchByEmail,
  } = useGetUserFromEmail({
    email: emailToSearch.value,
    organisation: watch('organisation'),
    onSuccess: (fetchedData: NewEmailUser | ExistingEmailUser) => {
      if (fetchedData.message.length) {
        setValue('orgMessageHandlerActive', true);
      }
      checkUserWithDifferentNumber();
    },
    retry: false,
  });

  const handleSearchByPhone = () => {
    if (watch('phone')) {
      searchByPhone();
    }
  };
  const handleSearchByEmail = () => {
    if (emailToSearch.value && !emailToSearch.errors) {
      searchByEmail();
    }
  };

  const isPhoneSearchLoading = isRefetching || isInitialLoading;

  const isEmailSearchLoading = isEmailSearchFetching;

  return (
    <GridItem xs={9} md={2} sm={2} lg={2}>
      <Box
        css={{
          padding: '$4',
          minHeight: '92vh',
          height: '100%',
          background: '$gray25',
          border: '1px solid $gray150',
        }}
      >
        <Typography size="lg" css={{ marginBottom: '$1' }}>
          Client overview
        </Typography>
        <Typography color="gray" size="sm">
          Fetch client details by entering their phone number or email
        </Typography>
        <Flex
          direction="column"
          align="center"
          gap="4"
          css={{ marginTop: '$4' }}
        >
          <Flex direction="column" align="center" css={{ width: '100%' }}>
            <ClientPhoneNumberInput />

            {!isEditing && (
              <Button
                variant="ghost"
                onClick={handleSearchByPhone}
                isLoading={isPhoneSearchLoading}
                disabled={
                  Boolean(errors.organisation) ||
                  !watch('organisation') ||
                  Boolean(errors.phone) ||
                  !(watch('phone') || watch('email')) ||
                  Boolean(watch('id')) ||
                  isEmailSearchLoading ||
                  isEditing
                }
                startIcon={<IoSearchOutline />}
              >
                Search
              </Button>
            )}
          </Flex>
          <AnimatePresence>
            {!watch('id') &&
            watch('previousCallWithDifferentOrganisationHandlerActive') ? (
              <ClientDifferentOrganisationHandler
                userDetails={userDifferentOrgDetails}
              />
            ) : null}
          </AnimatePresence>
          <AnimatePresence>
            {!watch('id') &&
            watch('previousCallWithDifferentNumberHandlerActive') &&
            watch('phone') &&
            watch('phone') !== userDetails?.phone ? (
              <ClientNewPhoneHandler userDetails={userDetails} />
            ) : null}
          </AnimatePresence>

          {!isEditing && <Typography color="gray">or</Typography>}
          <Flex direction="column" align="center" css={{ width: '100%' }}>
            <Label htmlFor="search-email" css={{ width: '100%' }}>
              Email
              <Input
                defaultValue={isEditing ? watch('email') : emailToSearch.value}
                css={{ margin: 0, width: '100%', mb: '$1' }}
                data-cy="search-email-input"
                id="search-email"
                disabled={watch([
                  'orgMessageHandlerActive',
                  'orgSelected',
                  'id',
                ]).some(Boolean)}
                onChange={(e) => {
                  setEmailToSearch((prev) => ({
                    ...prev,
                    value: e.target.value,
                  }));
                }}
                onBlur={() => {
                  setEmailToSearch((prev) => ({
                    ...prev,
                    touched: true,
                  }));
                }}
                helperText={
                  emailToSearch.errors ? emailToSearch?.errorMessage : ''
                }
              />
            </Label>
            {!isEditing && (
              <Button
                variant="ghost"
                onClick={handleSearchByEmail}
                isLoading={isEmailSearchLoading}
                disabled={
                  Boolean(errors.organisation) ||
                  !watch('organisation') ||
                  Boolean(errors.phone) ||
                  !watch('phone') ||
                  emailToSearch.errors ||
                  !emailToSearch.value ||
                  Boolean(watch('id')) ||
                  isPhoneSearchLoading ||
                  isEditing
                }
                startIcon={<IoSearchOutline />}
              >
                Search
              </Button>
            )}
          </Flex>

          <AnimatePresence>
            {watch('orgMessageHandlerActive') && fetchedUserData ? (
              <OrganisationMessageHandlerV2 fetchedData={fetchedUserData} />
            ) : null}
          </AnimatePresence>
        </Flex>
      </Box>
    </GridItem>
  );
};

export default FetchClientDetailsForm;
