import { RiskLevels } from '../constants/Risk';
import {
  EscalationFormTypes,
  OutgoingFormTypes,
  SessionCallStatus,
  SessionCallTypes,
  TestFormTypes,
} from '../constants/Session';
import {
  CaseNoteFormMeetingData,
  AssessmentPayload,
  OrganisationListItem,
} from './Session';

export type CaseNoteFormTypesV2 = {
  id?: string;
  userId: string;
  email?: string;
  sessionId: string;
  meetingData: CaseNoteFormMeetingData | null;
  phone: string;
  originalPhone: string; // used in keeping track when ranger updates phone
  name?: string;
  organisation?: number;
  employeeId?: string;
  age?: string;
  clientCreatedOn?: string;
  gender?: string | null;
  isDependent: boolean;
  dependentId?: string;
  isMinor: boolean;
  tenure: string;
  location?: string;
  city?: string;
  landmark?: string;
  clientPosition?: string;
  coachingOrClinicalEnabled: boolean;
  issue: string;
  secondaryIssue: string[];
  callReason: string;
  callDescription: string;
  rangerIntervention: string;
  caseNotes: string;
  risk: (typeof RiskLevels)[keyof typeof RiskLevels];
  clientAnonymous: boolean;
  orgAnonymous: boolean;
  doesNotWantToBookSession: boolean;
  followUpSession: boolean;
  assessment: AssessmentPayload[];
  startTime: Date;
  timeLimit: Date;
  endTime: Date;
  isChild?: boolean;
  orgMessageHandlerActive: boolean;
  previousCallWithDifferentNumberHandlerActive: boolean;
  previousCallWithDifferentOrganisationHandlerActive: boolean;
  orgSelected: boolean;
  selectedOrgDetails?: OrganisationListItem;
  callType: SessionCallTypes;
  callStatus?: SessionCallStatus;
  callAttempts?: number;
  escalationCallType?: EscalationFormTypes;
  outgoingCallType?: OutgoingFormTypes;
  cispCallType?: string;
  testCallType?: TestFormTypes;
  clientDetails?: {
    // Added to keep track of client having these details or not, so can disable/enable location & city fields
    location?: string;
    city?: string;
  };
};

export type EditSessionFormTypesV2 = Pick<
  CaseNoteFormTypesV2,
  | 'phone'
  | 'email'
  | 'name'
  | 'age'
  | 'organisation'
  | 'location'
  | 'city'
  | 'landmark'
  | 'clientPosition'
  | 'employeeId'
  | 'tenure'
  | 'issue'
  | 'secondaryIssue'
  | 'risk'
  | 'sessionId'
  | 'assessment'
  | 'callAttempts'
  | 'escalationCallType'
  | 'outgoingCallType'
  | 'cispCallType'
  | 'testCallType'
>;

export const IncomingCallDefaultValues: Partial<CaseNoteFormTypesV2> = {
  id: '',
  userId: '',
  phone: '',
  name: '',
  callReason: '',
  callDescription: '',
  rangerIntervention: '',
  clientAnonymous: false,
  orgAnonymous: false,
  doesNotWantToBookSession: false,
  assessment: [],
  meetingData: null,
  tenure: '',
  age: '',
  isDependent: false,
  isMinor: false,
  orgMessageHandlerActive: false,
  orgSelected: false,
  followUpSession: false,
  callType: SessionCallTypes.INCOMING_CALL,
};

export const OutgoingCallDefaultValues: Partial<CaseNoteFormTypesV2> = {
  ...IncomingCallDefaultValues,
  callType: SessionCallTypes.OUTGOING_CALL,
  callStatus: SessionCallStatus.NOT_ANSWERED,
  callAttempts: 1,
};

export const EscalationsCallDefaultValues: Partial<CaseNoteFormTypesV2> = {
  ...IncomingCallDefaultValues,
  callType: SessionCallTypes.ESCALATIONS_CHECKINS_CALL,
  callStatus: SessionCallStatus.NOT_ANSWERED,
  callAttempts: 1,
};

export const MultiPurposeCallDefaultValues: Partial<CaseNoteFormTypesV2> = {
  phone: '',
  name: '',
  email: '',
};
