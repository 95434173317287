import { useState } from 'react';
import { IoArrowDown } from 'react-icons/io5';
import { PrsentingIssues } from '../../../shared/constants/PresentingIssues';
import { RiskLevels } from '../../../shared/constants/Risk';
import {
  SessionCallStatus,
  SessionCallTypes,
} from '../../../shared/constants/Session';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import { CaseNote } from '../../../shared/types/Session';
import { shouldShowField } from '../../../utilities/common/Session';
import { useDownloadCaseNoteAttachment } from '../../../utilities/hooks/queryHooks/caseNotes/UseDownloadCaseNoteAttachment';
import { useUpdateSession } from '../../../utilities/hooks/queryHooks/caseNotes/UseUpdateSession';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  TextArea,
  Tooltip,
  Typography,
} from '../../styledComponents';

export interface ICallLogDetailsProps {
  id?: string;
  clientId?: string;
  sessionType?: 0 | 1; // n
  status?: string; // n
  issue?: string;
  secondaryIssue?: string[];
  risk?: (typeof RiskLevels)[keyof typeof RiskLevels];
  callReason: string;
  callDescription: string;
  rangerIntervention: string;
  caseNotes: string;
  caseNotesRaw?: CaseNote[]; // n
  startTime?: Date;
  endTime?: Date;
  meetingConsent?: 'yes' | 'no'; // n
  responderName?: string;
  callType?: SessionCallTypes;
  callStatus?: SessionCallStatus;
}

const CallLogDetails = ({
  sessionData,
  isConfirmingDetails = false,
}: {
  sessionData: ICallLogDetailsProps;
  isConfirmingDetails?: boolean;
}) => {
  // For Carenav attachments
  const caseNote = sessionData?.caseNotesRaw?.at(-1); // picking last note added during creation
  const attachment = caseNote?.attachments?.[0];

  const [updatedNote, setUpdatedNote] = useState(sessionData?.caseNotes ?? '');

  const { mutate: updateSession } = useUpdateSession();

  const { mutate: downloadAttachment, isLoading } =
    useDownloadCaseNoteAttachment();

  const shouldShow = (field: keyof CaseNoteFormTypesV2) =>
    shouldShowField({
      field,
      callStatus: sessionData.callStatus,
      callType: sessionData.callType,
    });

  return (
    <>
      <Heading size="sm" css={{ fontWeight: '600', mt: '$4', mb: '$3' }}>
        Call log details
      </Heading>
      {shouldShow('issue') ? (
        <Box css={{ mb: '$1' }}>
          <Typography color="gray600">Presenting issue</Typography>
          <Typography css={{ lineHeight: '$1', whiteSpace: 'pre-line' }}>
            {sessionData.sessionType === 1
              ? sessionData.issue || 'N/A'
              : PrsentingIssues.flatMap((category) => category.options).find(
                  (issue) => issue.value === sessionData.issue,
                )?.label || 'N/A'}
          </Typography>
        </Box>
      ) : null}
      {shouldShow('secondaryIssue') ? (
        <Box css={{ mb: '$1' }}>
          <Typography color="gray600">Secondary Presenting issue</Typography>
          <Typography css={{ lineHeight: '$1', whiteSpace: 'pre-line' }}>
            {sessionData?.secondaryIssue?.join(', ') ?? 'N/A'}
          </Typography>
        </Box>
      ) : null}
      {shouldShow('callReason') && sessionData?.callReason ? (
        <Box css={{ mb: '$1' }}>
          <Typography color="gray600">Reason for calling</Typography>
          <Typography css={{ lineHeight: '$1', whiteSpace: 'pre-line' }}>
            {sessionData.callReason}
          </Typography>
        </Box>
      ) : null}
      {shouldShow('callDescription') && sessionData?.callDescription ? (
        <Box css={{ mb: '$1' }}>
          <Typography color="gray600">Descriptions of the call</Typography>
          <Typography css={{ lineHeight: '$1', whiteSpace: 'pre-line' }}>
            {sessionData.callDescription}
          </Typography>
        </Box>
      ) : null}
      {shouldShow('rangerIntervention') && sessionData?.rangerIntervention ? (
        <Box css={{ mb: '$1' }}>
          <Typography color="gray600">
            Intervention conducted by ranger
          </Typography>
          <Typography css={{ lineHeight: '$1', whiteSpace: 'pre-line' }}>
            {sessionData?.rangerIntervention}
          </Typography>
        </Box>
      ) : null}
      <Typography color="gray600">Note/Remarks</Typography>
      {sessionData.sessionType === 1 && sessionData.status === '0' ? (
        <TextArea
          id="care-nav-notes"
          value={updatedNote}
          onChange={(event) => setUpdatedNote(event.target.value)}
        />
      ) : (
        <Typography
          css={{
            lineHeight: '$1',
            whiteSpace: 'pre-line',
          }}
        >
          {sessionData?.caseNotes ?? 'N/A'}
        </Typography>
      )}
      {!isConfirmingDetails && attachment ? (
        <>
          <Typography color="gray600">Attachment</Typography>
          <Flex justify="start" align="center" gap="1">
            <Typography
              style={{
                maxWidth: '30%',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {attachment?.fileName}
            </Typography>
            <Tooltip label="Click to download file">
              <IconButton
                type="button"
                disabled={isLoading}
                onClick={() => {
                  if (attachment && sessionData?.id) {
                    downloadAttachment({
                      attachment,
                      sessionId: sessionData?.id,
                    });
                  }
                }}
              >
                <IoArrowDown size={16} />
              </IconButton>
            </Tooltip>
          </Flex>
        </>
      ) : null}
      {!isConfirmingDetails && sessionData?.responderName ? (
        <Flex justify="end" css={{ mt: '$2' }}>
          <Typography size="xs" color="gray500">
            {sessionData?.responderName}
          </Typography>
        </Flex>
      ) : null}
      {!isConfirmingDetails &&
      sessionData.sessionType === 1 &&
      sessionData.status === '0' ? (
        <Button
          onClick={() => {
            if (!sessionData?.id || !sessionData?.sessionType) return;

            updateSession({
              status: '1',
              clientId: sessionData.clientId,
              sessionId: sessionData?.id,
              sessionType: `${sessionData.sessionType}`,
              issue: sessionData.issue || '',
              caseNotes: updatedNote || '',
              startTime: sessionData?.startTime
                ? new Date(sessionData?.startTime).toISOString()
                : new Date().toISOString(),
              endTime: sessionData?.endTime
                ? new Date(sessionData?.endTime).toISOString()
                : new Date().toISOString(),
              risk: sessionData.risk,
              meetingConsent: sessionData.meetingConsent || 'no',
            });
          }}
        >
          Mark as done
        </Button>
      ) : null}
    </>
  );
};

export default CallLogDetails;
