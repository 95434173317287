import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import LabelWithAsterik from '../../components/LabelWithAsterik/LabelWithAsterik';
import { Box, Flex, Label, Typography } from '../../styledComponents';
import { useFormConfigsContext } from '../FormConfigsProvider/FormConfigsProvider';

const ClientPhoneNumberInput = () => {
  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext<CaseNoteFormTypesV2>();
  const { isEditing } = useFormConfigsContext();

  return (
    <Box>
      <Label htmlFor="phone">
        <LabelWithAsterik label="Phone number" />
      </Label>
      <Flex
        justify="between"
        align="center"
        gap={3}
        css={{
          width: '100%',
          flexGrow: 1,
          border: '1px solid $gray300',
          borderRadius: '0.5rem',
          mt: '$1',
          mb: '$2',
          pr: '$1',
          backgroundColor: watch('id') ? '$gray100' : 'inherit',
        }}
      >
        <Box css={{ flexGrow: 1 }}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'This is required.',
              },
              minLength: {
                value: 8,
                message: 'Phone number should have a minimum of 8 digits.',
              },
            }}
            render={({ field: { ref, ...rest } }) => (
              <PhoneInput
                disabled={!!watch('id') && !isEditing}
                placeholder=""
                inputProps={{
                  ref,
                  required: true,
                }}
                inputClass="phone-input"
                country="sg"
                inputStyle={{ height: '44px' }}
                {...rest}
              />
            )}
          />
        </Box>
      </Flex>
      <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
        {errors.phone?.message?.toString()}
      </Typography>
      {isEditing &&
      !errors?.phone &&
      watch('phone') &&
      watch('originalPhone') &&
      watch('originalPhone') !== watch('phone') ? (
        <Typography
          color="darkRed"
          size="xs"
          css={{ mb: '$2', textAlign: 'justify' }}
        >
          Warning: Updating the phone number will <b>replace</b> all previous
          call records with the new number. Proceed with caution.
          <br />
          <Typography
            role="button"
            tabIndex={0}
            onClick={() => setValue('phone', watch('originalPhone'))}
            css={{
              display: 'inline',
              '@hover': {
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                },
              },
            }}
          >
            <b>Click here</b>
          </Typography>
          &nbsp;to revert to the previous phone number.
        </Typography>
      ) : null}
    </Box>
  );
};

export default ClientPhoneNumberInput;
