import { AnimationSpeeds } from '../../../shared/constants/Animations';
import MotionBox from '../MotionBox/MotionBox';

const FormSectionAnimatedBox = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <MotionBox
    initial={{
      marginTop: 0,
      height: 0,
      opacity: 0,
    }}
    animate={{
      height: 'auto',
      marginTop: '2rem',
      opacity: 1,
      transition: {
        height: {
          duration: 0.4,
        },
        opacity: {
          duration: AnimationSpeeds.Medium,
          delay: 0.3,
        },
      },
    }}
    exit={{
      marginTop: 0,
      height: 0,
      opacity: 0,
      transition: {
        height: {
          duration: AnimationSpeeds.Medium,
          delay: 0.3,
        },
        opacity: {
          duration: AnimationSpeeds.Medium,
        },
      },
    }}
  >
    {children}
  </MotionBox>
);

export default FormSectionAnimatedBox;
