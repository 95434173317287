import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { AnimationSpeeds } from '../../../../shared/constants/Animations';
import { AgeOptions } from '../../../../shared/constants/Session';
import {
  ExistingEmailUser,
  NewEmailUser,
} from '../../../../shared/types/FetchByEmailUserData';
import { useGetOrganisationsList } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import { useGetRegionsList } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetRegionsList';
import { Flex, Typography } from '../../../styledComponents';
import { DomainWarningV2 } from './DomainWarningV2';
import { OrgMessageItemV2 } from './OrgMessageItemV2';
import { CaseNoteFormTypesV2 } from '../../../../shared/types/CaseNoteFormV2';

const MotionFlex = motion(Flex);

export const OrganisationMessageHandlerV2 = ({
  fetchedData,
}: {
  fetchedData: NewEmailUser | ExistingEmailUser;
}) => {
  const { getValues, reset, watch } = useFormContext<CaseNoteFormTypesV2>();

  const { data: regionsList } = useGetRegionsList();

  const { data: organisationsList } = useGetOrganisationsList();

  const onSelectOrg = (selectedOrgId: number) => {
    if ('userId' in fetchedData) {
      const scholarLocation = fetchedData.userDependent?.id
        ? fetchedData.userDependent?.region
        : fetchedData.profile?.country;

      const regionToFind = regionsList?.find(
        (region) => region.countryCode === scholarLocation,
      )?.region;

      reset({
        ...getValues(),
        location: regionToFind,
        isChild: fetchedData.userDependent.isChild,
        isMinor:
          fetchedData.userDependent.isChild ||
          (fetchedData?.profile?.age === 'NA' &&
            fetchedData.userDependent.isChild),
        name: fetchedData.name,
        userId: fetchedData.userId,
        organisation: selectedOrgId,
        tenure: fetchedData.profile?.misc?.tenure,
        isDependent: !!fetchedData.userDependent?.id,
        dependentId: fetchedData.userDependent?.id,
        coachingOrClinicalEnabled:
          fetchedData?.addOns?.coachEnabled ||
          fetchedData?.addOns?.clinicalEnabled,
        ...(AgeOptions.find(
          (age) => age.value === fetchedData.profile?.age,
        ) && { age: fetchedData.profile?.age }),
        orgSelected: true,
        orgMessageHandlerActive: false,
        clientCreatedOn: fetchedData?.profile?.createdAt,
        gender: fetchedData?.profile?.gender,
        employeeId: fetchedData?.organisationUserInfo?.employeeId ?? '',
        clientDetails: { location: regionToFind, city: '' },
      });
    } else {
      reset({
        ...getValues(),
        organisation: selectedOrgId,
        orgSelected: true,
        orgMessageHandlerActive: false,
      });
    }
  };

  const currentOrg = watch('organisation');
  const orgForDomain = organisationsList?.find((org) => org.id === currentOrg);

  return (
    <MotionFlex
      direction="column"
      gap="3"
      css={{
        backgroundColor: '$gray75',
        padding: '0 1rem',
        fontSize: '$3',
        br: '$2',
        width: '100%',
      }}
      initial={{
        marginBottom: 0,
        padding: 0,
        height: 0,
        opacity: 0,
      }}
      animate={{
        height: 'auto',
        marginBottom: '2rem',
        padding: '1rem',
        opacity: 1,
        transition: {
          height: {
            duration: 0.4,
          },
          opacity: {
            duration: AnimationSpeeds.Medium,
            delay: 0.3,
          },
        },
      }}
      exit={{
        marginTop: 0,
        height: 0,
        opacity: 0,
        transition: {
          height: {
            duration: AnimationSpeeds.Fast,
            delay: 0.3,
          },
          opacity: {
            duration: AnimationSpeeds.Medium,
          },
        },
      }}
    >
      {orgForDomain ? <DomainWarningV2 domain={orgForDomain.domain} /> : null}

      <Typography size="lg" css={{ color: '$gray700' }}>
        Select to Proceed
      </Typography>
      {fetchedData.message.map((message) => {
        let orgToFind: string | undefined;

        if (message.status === 'NO_ORG') {
          orgToFind = organisationsList?.find(
            (org) => org.id === message.orgId,
          )?.name;
        } else if (message.status === 'DEPENDENT') {
          orgToFind = organisationsList?.find(
            (org) => +org.id === message.orgId,
          )?.name;
        } else {
          orgToFind = fetchedData.members.find(
            (org) => org.id === message.orgId,
          )?.name;
        }

        if (orgToFind) {
          return (
            <OrgMessageItemV2
              key={JSON.stringify(message)}
              status={message.status}
              orgName={orgToFind}
              email={watch('email')}
              onSelect={() => onSelectOrg(message.orgId)}
            />
          );
        }

        return null;
      })}
    </MotionFlex>
  );
};
