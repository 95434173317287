import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { PathConfig } from '../../../config/PathConfig';
import { CaseNoteFormEvent } from '../../../shared/constants/events/CaseNoteForm';
import { RiskLevels } from '../../../shared/constants/Risk';
import {
  SessionCallStatus,
  SessionCallTypes,
  SessionStatus,
} from '../../../shared/constants/Session';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import { ValueOf } from '../../../shared/types/Common';
import { useUpdateSessionV2 } from '../../../utilities/hooks/queryHooks/caseNotes/UseUpdateSession';
import { useCreateClientAndSession } from '../../../utilities/hooks/queryHooks/client/UseCreateClient';
import useAuth from '../../../utilities/hooks/UseAuth';
import useTracking from '../../../utilities/hooks/UseTracking';
import Modal from '../../components/modal/Modal';
import { Box } from '../../styledComponents';
import CallerDetails from '../SessionDetails/CallerDetails';
import CallLogDetails from '../SessionDetails/CallLogDetails';
import EngagementDetails from '../SessionDetails/EngagementDetails';
import RiskAssessmentDetails from '../SessionDetails/RiskAssessmentDetails';

const ConfirmEventLabels = {
  [RiskLevels.Low]: 'confirm_low',
  [RiskLevels.Medium]: 'confirm_medium',
  [RiskLevels.High]: 'confirm_high',
  [RiskLevels.HighCritical]: 'confirm_high_crc',
  [RiskLevels.Other]: 'confirm_other',
} satisfies Record<
  ValueOf<typeof RiskLevels>,
  CaseNoteFormEvent['EventLabelType'][number]
>;

const ConfirmationModalV2 = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { track } = useTracking<CaseNoteFormEvent>();

  const { watch, getValues } = useFormContext<CaseNoteFormTypesV2>();

  const {
    id: clientId,
    sessionId,
    userId,
    name,
    organisation,
    phone,
    email,
    issue,
    secondaryIssue,
    risk,
    employeeId,
    tenure,
    age,
    location,
    city,
    landmark,
    clientPosition,
    callType,
    callStatus,
    isDependent,
    startTime,
    endTime,
    cispCallType,
    testCallType,
    callDescription,
    callReason,
    rangerIntervention,
    caseNotes,
    followUpSession,
    assessment,
    clientAnonymous,
    callAttempts,
    escalationCallType,
    outgoingCallType,
  } = watch();

  const skipNoteCreationForNotAnsweredCalls =
    [
      SessionCallTypes.OUTGOING_CALL,
      SessionCallTypes.ESCALATIONS_CHECKINS_CALL,
    ].includes(watch('callType')) &&
    watch('callStatus') === SessionCallStatus.NOT_ANSWERED;

  const { isLoading: isUpdateSessionLoading, mutate: mutateUpdateSession } =
    useUpdateSessionV2({
      onSuccess: () => {
        navigate(`${PathConfig.responder}/${PathConfig.callLogs}`);
      },
      skipNoteCreation: skipNoteCreationForNotAnsweredCalls,
    });

  const meetingData = watch('meetingData');

  const updateSession = () => {
    track('mark_complete', {
      eventAction: 'click',
      eventCategory: 'confirm_submit',
      eventLabel: ConfirmEventLabels[watch('risk') || RiskLevels.Low],
    });

    // As sessionId gets changed asynchrounsly in success calls,
    // so here fetching it again to get latest value
    const { sessionId: sesionIdLatest } = getValues();

    if (clientId) {
      mutateUpdateSession({
        sessionId: sesionIdLatest,
        clientId,
        startTime: startTime.toISOString(),
        endTime: new Date().toISOString(),
        risk,
        callDescription,
        callReason,
        rangerIntervention,
        caseNotes,
        assessment,
        issue,
        secondaryIssue,
        meetingConsent: !followUpSession ? 'no' : 'yes',
        status: SessionStatus.COMPLETED,
        sessionType: '0',
        callAttempts,
        callStatus,
        escalationCallType,
        outgoingCallType,
        cispCallType,
        clientPosition,
        testCallType,
      });
    }
  };

  const {
    isLoading: isCreateClientAndSessionLoading,
    mutate: mutateClientAndSession,
  } = useCreateClientAndSession({
    onSessionCreateSuccess: () => updateSession(),
    sessionCallType: watch('callType'),
    apiVersion: 2,
  });

  const handleSubmit = () => {
    /**
     * Case 1: Session already created
     * Occurs when Ranger clicked on Follow Up Session -> Book Now, Where before redirecting
     * on maverick platform we create client and session
     */
    if (watch('sessionId')) {
      updateSession();
    } else {
      /**
       * Case 2: User does not exist, will create client and session
       * Occurs when Ranger clicked on Follow Up Session -> Book Now, Where before redirecting
       * on maverick platform we create client and session
       */

      /**
       * User already exists
       * Only create session
       *
       * Does not exist
       */

      const nonNullValues = Object.fromEntries(
        Object.entries({
          name,
          email,
          phone,
          location,
          city,
          landmark,
          organisation,
          tenure,
          age,
          employeeId,
        }).filter(([_, v]) => !!v),
      );
      mutateClientAndSession(nonNullValues);
    }
  };

  return (
    <Modal
      title="Complete this session"
      subtitle="Please go through what you’ve written as you won’t be able to change this later."
      open={open ?? true}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      confirmLoading={isUpdateSessionLoading || isCreateClientAndSessionLoading}
    >
      <Box
        css={{
          br: '$2',
          backgroundColor: '$gray50',
          p: '$3',
          border: '1px solid $gray200',
          zIndex: '$max',
        }}
      >
        <EngagementDetails
          sessionData={{
            escalationCallType,
            outgoingCallType,
            callAttempts,
            callStatus,
            callType,
          }}
        />
        <CallerDetails
          sessionData={{
            organisation,
            phone,
            email,
            name,
            risk,
            employeeId,
            tenure,
            age,
            location,
            city,
            landmark,
            clientPosition,
            callType,
            isDependent,
            startTime,
            endTime,
            cispCallType,
            testCallType,
            callStatus,
          }}
        />
        <RiskAssessmentDetails
          sessionData={{
            risk,
            userId,
            meetingData,
            followUpSession,
            sessionQuestionResponses: assessment,
            organisation,
            callType,
            callStatus,
            clientAnonymous,
          }}
        />

        <CallLogDetails
          sessionData={{
            id: sessionId,
            clientId,
            callDescription,
            callReason,
            rangerIntervention,
            caseNotes,
            issue,
            secondaryIssue,
            risk,
            callType,
            callStatus,
            startTime,
            endTime,
            responderName: user?.name,
          }}
          isConfirmingDetails
        />
      </Box>
    </Modal>
  );
};

export default ConfirmationModalV2;
