import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';

import ApiConfig from '../../../../config/ApiConfig';
import { doPost } from '../../../../shared/service/NetworkClient';
import { CaseNoteFormTypes } from '../../../../shared/types/CaseNoteForm';
import { ClientInfoV2 } from '../../../../shared/types/Client';
import { getOrganisation } from '../../../common/FormValidatorRegex';
import { useCreateSession } from '../caseNotes/UseCreateSession';
import { SessionCallTypes } from '../../../../shared/constants/Session';

interface ICreateClientAndSession {
  onClientCreateSuccess?: (data: {
    clientId: string;
    userId: string;
    email: string;
  }) => void;
  onSessionCreateSuccess?: (sessionId: string) => void;
  sessionCallType?: SessionCallTypes;
  apiVersion?: 1 | 2;
}

export const useCreateClientAndSession = (
  configs?: ICreateClientAndSession,
) => {
  const { apiVersion = 1 } = (configs ?? {}) as ICreateClientAndSession;

  const { getValues, setValue, watch } = useFormContext<CaseNoteFormTypes>();

  const onSessionSuccess = (sessionId: string) => {
    setValue('sessionId', sessionId);

    configs?.onSessionCreateSuccess?.(sessionId);
  };

  const { isLoading: createSessionLoading, mutate: mutateSession } =
    useCreateSession(onSessionSuccess);

  const clientMutation = useMutation(
    (clientData: Partial<ClientInfoV2>) =>
      doPost(
        apiVersion === 2
          ? ApiConfig.api.clients.addV2 // V2 for updating existing client details (upsert)
          : ApiConfig.api.clients.add, // V1 update not allowed once client details entry is created
        {
          ...clientData,
          organisation: getOrganisation(clientData.organisation),
          ...(clientData.employeeId && {
            organisationData: { employeeId: clientData.employeeId },
          }),
        },
      ).then((res) => res.data.data),
    {
      onSuccess: (data) => {
        const { userId } = watch();

        setValue('id', data.clientId);
        setValue('email', data.email);

        // If different than existing one then only update userId
        // this is to avoid get client details call after form gets marked as completed/cancelled
        if (data?.userId && userId !== data?.userId?.toString()) {
          setValue('userId', data.userId?.toString());
        }

        configs?.onClientCreateSuccess?.(data);

        mutateSession({
          clientId: data.clientId,
          startTime: getValues('startTime') || new Date(),
          callType: configs?.sessionCallType,
        });
      },
      onError: (e) => {
        const error = e as AxiosResponse;
        if (
          'data' in error &&
          error.data.error.message === 'Client already exists'
        ) {
          console.error('Error - This client already exists.');
        } else {
          toast.error('An error occurred while creating the client.');
        }
      },
    },
  );

  return {
    ...clientMutation,
    isLoading: clientMutation.isLoading || createSessionLoading,
  };
};
