import { useFormContext } from 'react-hook-form';
import { CaseNoteFormTypesV2 } from '../../../../shared/types/CaseNoteFormV2';
import { getDisplayDateTime } from '../../../../utilities/common/Date';
import { useClientNotes } from '../../../../utilities/hooks/queryHooks/client/UseClientNotes';
import InfoCard from '../../../components/InfoCard/InfoCard';
import UnOrderedList from '../../../components/UnOrderedList/UnOrderedList';
import { Flex, Typography } from '../../../styledComponents';

const ClientNotesCard = () => {
  const { watch } = useFormContext<CaseNoteFormTypesV2>();
  const { userId } = watch();

  // --------------- NETWORK CALLS ---------------
  const { data: notes } = useClientNotes(userId);

  if (!userId || userId === 'null') return null;

  return (
    <Flex direction="column" gap="2">
      <Typography size="md">Provider notes</Typography>

      {notes?.length ? (
        notes?.map((note, idx) => {
          const key = `user-${userId}-note-${idx}`;

          return (
            <InfoCard
              key={key}
              content={note?.note}
              subContent={
                <UnOrderedList
                  items={[
                    { label: note?.providerName ?? '' },
                    {
                      label: note?.updatedAt
                        ? getDisplayDateTime(new Date(note?.updatedAt))
                        : '',
                    },
                  ]}
                  liCss={{ maxWidth: '50%' }}
                />
              }
            />
          );
        })
      ) : (
        <InfoCard
          content={
            <Typography size="xs">No notes available right now.</Typography>
          }
        />
      )}
    </Flex>
  );
};

export default ClientNotesCard;
