import { ComponentProps, forwardRef } from 'react';

import { styled, VariantProps } from '../../stitches.config';
import { Box } from './Box';
import { Typography } from './Typography';

export const InputBase = styled('input', {
  appearance: 'none',
  borderWidth: '0',
  boxSizing: 'border-box',
  fontFamily: 'inherit',
  outline: 'none',
  width: '100%',
  '&:disabled': {
    color: '$gray500',
    cursor: 'not-allowed',
  },
});

export const InputContainer = styled('div', {
  margin: '0.5rem 0',
  transition: 'border-color 0.2s linear',
  backgroundColor: 'White',
  display: 'flex',
  alignItems: 'center',
  gap: '$3',
  variants: {
    size: {
      sm: {
        padding: '0.25rem 0.5rem',
        fontSize: '0.75em',
      },
      md: {
        padding: '0.5rem 0.5rem',
        fontSize: '1em',
      },
      lg: {
        padding: '0.5rem 0.75rem',
        fontSize: '1.25em',
      },
    },
    variant: {
      flat: {
        borderBottomWidth: '2px',
        borderBottomColor: '$gray300',
        borderBottomStyle: 'solid',
        '&:focus-within': {
          borderBottomColor: '$gray500',
        },
      },
      unstyled: {
        border: 'none',
        margin: 0,
      },
      default: {
        border: '1px solid $gray300',
        borderRadius: '0.5rem',
        padding: '$2',
      },
    },

    disabled: {
      true: {
        backgroundColor: '$gray100',
        cursor: 'not-allowed',
      },
    },
  },

  defaultVariants: {
    size: 'md',
    variant: 'default',
  },
});

export type InputProps = ComponentProps<typeof InputBase> &
  VariantProps<typeof InputContainer> & {
    startElement?: JSX.Element;
    endElement?: JSX.Element;
    helperText?: string;
  };
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      variant = 'default',
      size,
      css,
      disabled,
      startElement,
      endElement,
      helperText,
      ...otherProps
    },
    ref,
  ) => (
    <Box
      css={{
        margin: '0.5rem 0 1rem',
        ...css,
      }}
    >
      <InputContainer variant={variant} size={size} disabled={disabled}>
        {startElement}
        <InputBase ref={ref} {...otherProps} disabled={disabled} />
        {endElement}
      </InputContainer>
      {helperText && (
        <Typography color="darkRed" size="xs">
          {helperText}
        </Typography>
      )}
    </Box>
  ),
);

export const HelperText = styled(Typography, {
  fontSize: '$1',
});
