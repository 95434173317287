import { AnimatePresence } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import {
  CispDefaultTypes,
  IntellectOrganisationId,
  NotesMaxCharacters,
  NotesMinCharacters,
  SessionCallTypes,
  TestFormTypes,
  TestFormTypesLabels,
} from '../../../shared/constants/Session';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import { ClientInfoV2 } from '../../../shared/types/Client';
import { ComboboxOption } from '../../../shared/types/SelectTypes';
import { convertIntoComboboxOptions } from '../../../utilities/common/ConvertIntoOptions';
import {
  emailRegex,
  noWhiteSpaceRegex,
} from '../../../utilities/common/FormValidatorRegex';
import { useGetCispTypesList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetCispTypesList';
import { useGetOrganisationsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import { useSearchUserDetailsByPhone } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetUserFromNumber';
import useDebounce from '../../../utilities/hooks/UseDebounce';
import { useEmailDomainValidator } from '../../../utilities/hooks/UseEmailDomainValidator';
import FormSection from '../../components/formSection/FormSection';
import InfoCard from '../../components/InfoCard/InfoCard';
import LabelWithAsterik from '../../components/LabelWithAsterik/LabelWithAsterik';
import {
  Box,
  Button,
  Combobox,
  Flex,
  Grid,
  Input,
  Label,
  TextArea,
  Typography,
} from '../../styledComponents';
import { ChipButton } from '../../styledComponents/ChipButton';
import { GridItem } from '../../styledComponents/GridItem';
import ClientDifferentOrganisationHandler from '../ClientDetails/ClientDiscrepancies/ClientDifferentOrganisationHandler';
import { useFormConfigsContext } from '../FormConfigsProvider/FormConfigsProvider';

const CommonFormCard = () => {
  const {
    register,
    control,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
  } = useFormContext<CaseNoteFormTypesV2>();
  const { isEditing } = useFormConfigsContext();

  const { callType, testCallType, cispCallType, organisation, phone } = watch();

  const { changeEmailDomain, validateEmail, selectedDomain, ValidOrgDomains } =
    useEmailDomainValidator();

  const { data: organisationsList, dataUpdatedAt } = useGetOrganisationsList();
  const { data: CispTypes } = useGetCispTypesList();
  const debouncedPhone = useDebounce(
    phone && phone?.length >= 8 ? phone : '',
    700,
  );

  const {
    data: userDifferentOrgDetails,
    refetch: searchClientAnywhereByPhone,
  } = useSearchUserDetailsByPhone({
    phone: debouncedPhone,
    onComplete: (data: ClientInfoV2) => {
      // If org is different than show a warning msg
      if (data.organisation) {
        if (data.organisation !== getValues().organisation) {
          setValue('previousCallWithDifferentOrganisationHandlerActive', true);
        } else {
          setValue('previousCallWithDifferentOrganisationHandlerActive', false);
        }
      }

      if (data.email) {
        setValue('email', data.email);
      }

      if (data.name) {
        setValue('name', data.name);
      }
    },
    onError: () => {
      setValue('previousCallWithDifferentOrganisationHandlerActive', false);
    },
    retry: false,
  });

  const isTestCall = callType === SessionCallTypes.TEST_CALL;
  const disableOrgInTestCall =
    isTestCall &&
    testCallType &&
    [TestFormTypes.INTERNAL_QUALITY, TestFormTypes.WEEKLY_PLATFORM].includes(
      testCallType,
    );

  const isCispCall = callType === SessionCallTypes.CISP_CALL;

  const PhoneGridSize = isCispCall ? 2 : 8;

  const CispTypeOptions = useMemo(() => {
    const NewTypes =
      CispTypes?.filter((type) => !CispDefaultTypes.includes(type)) ?? [];

    const types = [...CispDefaultTypes, ...NewTypes];

    return types.map((item) => ({
      label: item,
      value: item,
    })) as ComboboxOption[];
  }, [CispTypes]);

  useEffect(() => {
    if (!organisation) return;

    const organisationDetails = organisationsList?.find(
      (org) => org.id === organisation,
    );
    if (organisation && debouncedPhone) {
      setValue('selectedOrgDetails', organisationDetails);
      searchClientAnywhereByPhone();
    }
  }, [
    setValue,
    organisation,
    organisationsList,
    searchClientAnywhereByPhone,
    debouncedPhone,
  ]);

  useEffect(() => {
    if (disableOrgInTestCall) {
      setValue('organisation', IntellectOrganisationId);
      clearErrors('organisation');
    } else if (!isEditing) {
      setValue('organisation', undefined);
    }
  }, [disableOrgInTestCall, setValue, clearErrors, isEditing]);

  return (
    <FormSection
      title="Requester information"
      subtitle="Enter your client information to get started with the session"
      css={{ my: '0', zIndex: '$4' }}
      defaultOpen
    >
      <Grid columns={8} gap="2">
        {isTestCall ? (
          <GridItem xs={8} sm={8} md={8} lg={8}>
            <Label htmlFor="testCallType">
              <LabelWithAsterik label="Test type" />
              <Flex gap="2" wrap="wrap" css={{ my: '$2' }}>
                {(
                  Object.entries(TestFormTypesLabels) as [
                    TestFormTypes,
                    string,
                  ][]
                )?.map((label, idx) => {
                  const key = `test-type-${idx}`;

                  const [labelValue, labelKey] = label;

                  return (
                    <ChipButton
                      key={key}
                      selected={testCallType === labelValue}
                      onClick={() => setValue('testCallType', labelValue)}
                      size="sm"
                    >
                      {labelKey}
                    </ChipButton>
                  );
                })}
              </Flex>
            </Label>
          </GridItem>
        ) : null}
        {isCispCall ? (
          <GridItem xs={6} sm={6} md={6} lg={6}>
            <Label htmlFor="name" css={{ width: '100%' }}>
              <LabelWithAsterik label="Name" />
              <Input
                defaultValue={watch('name')}
                data-cy="name-input"
                id="name"
                {...register('name', {
                  required: true,
                  pattern: {
                    value: noWhiteSpaceRegex,
                    message: 'No trailing or leading spaces.',
                  },
                })}
                helperText={
                  errors.name?.type === 'required'
                    ? 'This is required.'
                    : errors.name?.message?.toString()
                }
                disabled={!!watch('id') || isEditing}
              />
            </Label>
          </GridItem>
        ) : null}
        <GridItem
          xs={PhoneGridSize}
          sm={PhoneGridSize}
          md={PhoneGridSize}
          lg={PhoneGridSize}
        >
          <Label htmlFor="phone" css={{ width: '100%' }}>
            <LabelWithAsterik label="Phone number" />
            <Flex
              justify="between"
              align="center"
              css={{
                width: '100%',
                margin: '0.5rem 0 $2',
                flexGrow: 1,
                border: '1px solid $gray300',
                borderRadius: '0.5rem',
                backgroundColor: watch('id') ? '$gray100' : 'inherit',
              }}
            >
              <Box css={{ flexGrow: 1 }}>
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'This is required.',
                    },
                    minLength: {
                      value: 8,
                      message:
                        'Phone number should have a minimum of 8 digits.',
                    },
                  }}
                  render={({ field: { ref, ...rest } }) => (
                    <PhoneInput
                      disabled={!!watch('id') || isEditing}
                      placeholder=""
                      inputProps={{
                        ref,
                        required: true,
                      }}
                      inputClass="phone-input"
                      country="sg"
                      inputStyle={{ height: '44px' }}
                      {...rest}
                    />
                  )}
                />
              </Box>
            </Flex>
          </Label>
        </GridItem>

        <GridItem xs={8} sm={8} md={8} lg={8}>
          <Label htmlFor="organisation" css={{ width: '100%' }}>
            <LabelWithAsterik label="Organisation" />
            <Controller
              name="organisation"
              defaultValue={watch('organisation')}
              control={control}
              render={({ field: { onChange, value, ...rest } }) => (
                <Combobox
                  key={`${dataUpdatedAt}-${watch('organisation')}`}
                  options={convertIntoComboboxOptions(
                    organisationsList ?? [],
                    'name',
                    'id',
                  )}
                  selectedValue={value?.toString()}
                  onOptionSelect={(newOption) =>
                    onChange(newOption ? parseInt(newOption, 10) : newOption)
                  }
                  disabled={
                    !organisationsList ||
                    disableOrgInTestCall ||
                    !!watch('id') ||
                    isEditing
                  }
                  {...rest}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: 'This is required.',
                },
              }}
            />
            <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
              {errors.organisation?.message?.toString()}
            </Typography>
            <AnimatePresence>
              {watch('previousCallWithDifferentOrganisationHandlerActive') ? (
                <ClientDifferentOrganisationHandler
                  userDetails={userDifferentOrgDetails}
                />
              ) : null}
            </AnimatePresence>
          </Label>
        </GridItem>
        {isCispCall ? (
          <GridItem xs={8} sm={8} md={8} lg={8}>
            <Label htmlFor="email" css={{ width: '100%' }}>
              <LabelWithAsterik label="Email" />
              <Input
                defaultValue={watch('email')}
                css={{ margin: 0, width: '100%', mb: '$2' }}
                data-cy="email-input"
                id="email"
                {...register('email', {
                  pattern: {
                    value: emailRegex,
                    message: 'Please enter a valid email.',
                  },
                })}
                onChange={(e) => {
                  setValue('email', e.target.value, { shouldTouch: true });
                }}
                onBlur={() => validateEmail(true)}
                helperText={errors.email?.message?.toString()}
                disabled={!!watch('id') || isEditing}
              />
            </Label>
            {ValidOrgDomains?.length ? (
              <Flex gap="2" css={{ mb: '$2' }}>
                {ValidOrgDomains?.map((dm) => (
                  <Button
                    key={dm}
                    variant={selectedDomain === dm ? 'filled' : 'outlined'}
                    onClick={() => changeEmailDomain(dm)}
                    size="sm"
                  >
                    {dm}
                  </Button>
                ))}
              </Flex>
            ) : null}
          </GridItem>
        ) : null}
        {callType === SessionCallTypes.CISP_CALL ? (
          <>
            <GridItem xs={8} sm={8} md={8} lg={8}>
              <Label htmlFor="name" css={{ width: '100%' }}>
                <LabelWithAsterik label="CISP type" />

                <Controller
                  name="cispCallType"
                  defaultValue={cispCallType}
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <Combobox
                      key="cispCallType"
                      options={[...CispTypeOptions]}
                      selectedValue={value}
                      onOptionSelect={(newValue?: string) => {
                        onChange(newValue);
                      }}
                      createable
                      onCreate={(e) => setValue('cispCallType', e.toString())}
                      createablePlaceholder="Add new Cisp Call Type - "
                      shouldOverrideOnChange
                      {...rest}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: 'This is required.',
                    },
                  }}
                />
                <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
                  {errors.cispCallType?.message?.toString()}
                </Typography>
              </Label>
            </GridItem>
            <GridItem xs={8} sm={8} md={8} lg={8}>
              <Label htmlFor="name" css={{ width: '100%' }}>
                Position (optional)
                <Input
                  defaultValue={watch('clientPosition')}
                  data-cy="position-input"
                  id="position"
                  {...register('clientPosition', {
                    pattern: {
                      value: noWhiteSpaceRegex,
                      message: 'No trailing or leading spaces.',
                    },
                  })}
                  helperText={
                    errors.clientPosition?.type === 'required'
                      ? 'This is required.'
                      : errors.clientPosition?.message?.toString()
                  }
                />
              </Label>
            </GridItem>
          </>
        ) : null}
        {!isEditing && (
          <GridItem xs={8} sm={8} md={8} lg={8}>
            <Flex justify="between" css={{ mb: '$2' }}>
              <Label htmlFor="case-note-text">Note</Label>
              <Typography size="sm">
                {watch('caseNotes')?.length}/{NotesMaxCharacters}
              </Typography>
            </Flex>
            <TextArea
              data-cy="text-area-call-description"
              size="md"
              {...register('caseNotes', {
                minLength: {
                  value: NotesMinCharacters,
                  message: `Please enter a minimum of ${NotesMinCharacters} characters.`,
                },
                maxLength: {
                  value: NotesMaxCharacters,
                  message: `Characters should not exceed more than ${NotesMaxCharacters} characters.`,
                },
              })}
            />
            <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
              {errors.caseNotes?.message?.toString()}
            </Typography>
          </GridItem>
        )}
        {isCispCall ? (
          <GridItem xs={8} sm={8} md={8} lg={8}>
            <InfoCard
              content={
                <Typography>
                  Inform caller, crisis specialist will call back within{' '}
                  <b>1 hour</b>
                </Typography>
              }
            />
          </GridItem>
        ) : null}
      </Grid>
    </FormSection>
  );
};

export default CommonFormCard;
