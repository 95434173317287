import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { NoOrganisationCode } from '../../../../shared/constants/Session';
import { doGet } from '../../../../shared/service/NetworkClient';
import { OrganisationListItem } from '../../../../shared/types/Session';

export const useGetOrganisationsList = () =>
  useQuery<OrganisationListItem[]>(
    [QueryKeys.getOrganisationsList],
    () =>
      doGet(ApiConfig.api.clients.getOrganisationsList).then((res) => [
        {
          name: 'Did not disclose',
          id: NoOrganisationCode,
          eapNote: [],
          eapNoteCheatSheet: [],
          domain: [],
          credits: [],
        } satisfies OrganisationListItem,

        ...res.data.data,
      ]),
    {
      onError: () => {
        toast.error(
          'An error occurred while getting the list of organisations.',
        );
      },
      // Org lists do not change frequently, this helps when the request is fetched multiple times
      cacheTime: 25 * 60 * 1000, // 25 minutes
      staleTime: 20 * 60 * 1000, // 20 minutes
    },
  );
