import { Placement, VirtualElement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import { ReactNode, useEffect, useRef, useState } from 'react';
import useOutsideClickHandler from '../../../utilities/hooks/useOutsideClickHandler';
import MotionBox from '../MotionBox/MotionBox';

interface IPopperProps {
  anchorEl: Element | VirtualElement | null;
  handleClose: () => void;
  children: ReactNode;
  placement?: Placement;
}
const Popper = ({
  anchorEl,
  handleClose,
  children,
  placement = 'left',
}: IPopperProps) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const popperRef = useRef<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(anchorEl, popperElement, {
    placement,
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          boundary: 'clippingParents',
          rootBoundary: 'viewport',
          padding: 10,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'bottom'],
        },
      },
    ],
  });

  useOutsideClickHandler(popperRef, () => {
    handleClose();
  });

  useEffect(() => {
    if (anchorEl) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [anchorEl]);

  return (
    anchorEl && (
      <div className="z-30">
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}
          css={{
            position: 'fixed',
            inset: 0,
          }}
        />
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}
          ref={(node: HTMLDivElement) => {
            setPopperElement(node as HTMLDivElement);
            popperRef.current = node;
          }}
          style={styles.popper}
          css={{ zIndex: '$max', position: 'absolute' }}
          {...attributes.popper}
        >
          {children}
        </MotionBox>
      </div>
    )
  );
};

export default Popper;
