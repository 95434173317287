import {
  EscalationFormTypes,
  EscalationFormTypesLabels,
  OutgoingFormTypes,
  OutgoingFormTypesLabels,
  SessionCallStatus,
  SessionCallTypes,
} from '../../../shared/constants/Session';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import { shouldShowField } from '../../../utilities/common/Session';
import { Box, Grid, Heading, Typography } from '../../styledComponents';

export interface IEngagementDetailsProps {
  callAttempts?: number;
  callStatus?: SessionCallStatus;
  callType?: SessionCallTypes;
  escalationCallType?: EscalationFormTypes;
  outgoingCallType?: OutgoingFormTypes;
}

const EngagementDetails = ({
  sessionData,
}: {
  sessionData: IEngagementDetailsProps;
}) => {
  const {
    callAttempts,
    callStatus,
    callType,
    escalationCallType,
    outgoingCallType,
  } = sessionData;

  const isEscalationCall = callType === SessionCallTypes.OUTGOING_CALL;
  const isOutgoingCall = callType === SessionCallTypes.OUTGOING_CALL;

  const shouldShow = (field: keyof CaseNoteFormTypesV2) =>
    shouldShowField({ field, callStatus, callType });

  if (!isEscalationCall && !isOutgoingCall) return null;

  return (
    <>
      <Heading size="sm" css={{ fontWeight: '600' }}>
        Engagement Details
      </Heading>
      <Grid gapX="6" gapY="4" columns={4} css={{ p: '$4' }}>
        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Call status
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {callStatus === SessionCallStatus.ANSWERED
              ? 'Answered'
              : 'Not answered'}
          </Typography>
        </Box>
        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            No. of attempts
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {callAttempts || 'No information'}
          </Typography>
        </Box>
        <Box>
          {shouldShow('escalationCallType') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                Escalation type
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {EscalationFormTypesLabels?.[
                  escalationCallType as EscalationFormTypes
                ] || 'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>
        <Box>
          {shouldShow('outgoingCallType') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                Outgoing type
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {OutgoingFormTypesLabels?.[
                  outgoingCallType as OutgoingFormTypes
                ] || 'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>
      </Grid>
    </>
  );
};

export default EngagementDetails;
