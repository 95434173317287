import { isArray } from 'lodash';

/**
 * To check given value is valid or not
 * Note: only use this when you want to keep/allow 0(zero) otherwise you can directly use Boolean
 * @param value
 * @returns
 */
export const isValidValue = (value: any) =>
  value !== null &&
  value !== undefined &&
  value !== '' &&
  !(isArray(value) && value.length === 0);
