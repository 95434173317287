import { motion } from 'framer-motion';
import { Controller, useFormContext } from 'react-hook-form';
import { AnimationSpeeds } from '../../../../shared/constants/Animations';
import { CareNavForm } from '../../../../shared/types/CareNavForm';
import { ClientInfoV2 } from '../../../../shared/types/Client';
import { convertIntoComboboxOptions } from '../../../../utilities/common/ConvertIntoOptions';
import { noWhiteSpaceRegex } from '../../../../utilities/common/FormValidatorRegex';
import { useGetOrganisationsList } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import { useGetUserFromEmail } from '../../../../utilities/hooks/queryHooks/caseNotes/UseGetUserFromEmail';
import {
  Box,
  Button,
  Combobox,
  Flex,
  Input,
  Label,
} from '../../../styledComponents';
import { getClientCreateWarning } from './CreateClientWarning';

const MotionFlex = motion(Flex);

export const CreateClientForm = ({
  onSubmit,
  isCreateClientLoading,
}: {
  onSubmit: (
    payload: Partial<ClientInfoV2>,
  ) => Promise<{ clientId: string; userId: string; email: string }>;
  isCreateClientLoading: boolean;
}) => {
  const {
    watch,
    control,
    formState: { errors },
    register,
    reset,
  } = useFormContext<CareNavForm>();

  const { data: organisationsList, dataUpdatedAt } = useGetOrganisationsList();

  const { refetch, isFetching } = useGetUserFromEmail({
    email: watch('email'),
    organisation: null,
    onSuccess: (data) => {
      if ('email' in data) {
        reset({
          ...watch(),
          email: data.email,
          userData: { ...data },
          showCreateClientHandler: false,
          membershipData: [],
          newUser: { name: '', organisation: undefined },
          message: [],
        });
      }
    },
  });

  const onCreateClick = async () => {
    const [email, newUser] = watch(['email', 'newUser']);

    if (newUser.organisation) {
      const response = await onSubmit({
        email,
        ...newUser,
        phone: '',
        tenure: '',
        age: '',
      });
      if (response && response.clientId) {
        refetch();
      }
    }
  };

  return (
    <MotionFlex
      direction="column"
      gap="3"
      css={{
        backgroundColor: '$gray50',
        padding: '0 1rem',
        fontSize: '$3',
        br: '$2',
        zIndex: '$2',
        position: 'relative',
      }}
      initial={{
        marginBottom: 0,
        padding: 0,
        height: 0,
        opacity: 0,
      }}
      animate={{
        height: 'auto',
        marginBottom: '2rem',
        padding: '1rem',
        opacity: 1,
        transition: {
          height: {
            duration: 0.4,
          },
          opacity: {
            duration: AnimationSpeeds.Medium,
            delay: 0.3,
          },
        },
      }}
      exit={{
        marginTop: 0,
        height: 0,
        opacity: 0,
        transition: {
          height: {
            duration: AnimationSpeeds.Fast,
            delay: 0.3,
          },
          opacity: {
            duration: AnimationSpeeds.Medium,
          },
        },
      }}
    >
      <Box css={{ color: '$gray700', fontWeight: 'bold' }}>
        {getClientCreateWarning({
          memberships: watch('membershipData'),
          messages: watch('message'),
        })}
        Please enter their information below and click Create
      </Box>
      <Flex direction="column" gap="1">
        <Label htmlFor="name">
          Name
          <Input
            defaultValue={watch('newUser.name')}
            data-cy="name-input"
            id="name"
            {...register('newUser.name', {
              required: true,
              pattern: {
                value: noWhiteSpaceRegex,
                message: 'No trailing or leading spaces.',
              },
            })}
            helperText={
              errors.newUser?.name?.type === 'required'
                ? 'This is required.'
                : errors.newUser?.name?.message?.toString()
            }
          />
        </Label>
        <Label htmlFor="newUser.organisation">
          Organisation
          <Controller
            name="newUser.organisation"
            // @ts-ignore
            defaultValue={
              watch('membershipData')?.length === 1
                ? watch('membershipData')?.[0]?.id
                : watch('newUser.organisation')
            }
            control={control}
            render={({ field: { onChange, value, ...rest } }) => (
              <Combobox
                key={`${dataUpdatedAt}-${watch('newUser.organisation')}`}
                options={convertIntoComboboxOptions(
                  organisationsList ?? [],
                  'name',
                  'id',
                )}
                selectedValue={value?.toString()}
                onOptionSelect={(newOption) =>
                  onChange(newOption ? parseInt(newOption, 10) : newOption)
                }
                disabled={!organisationsList}
                {...rest}
              />
            )}
            rules={{
              required: {
                value: true,
                message: 'This is required.',
              },
            }}
          />
        </Label>

        <Button
          isLoading={isFetching || isCreateClientLoading}
          css={{ alignSelf: 'flex-end' }}
          onClick={onCreateClick}
          disabled={
            !watch(['newUser.name', 'newUser.organisation']).every(Boolean) &&
            ['newUser.name', 'newUser.organisation'].every(
              (val) => val in errors === false,
            )
          }
        >
          Create
        </Button>
      </Flex>
    </MotionFlex>
  );
};
