import { createContext, Dispatch, SetStateAction } from 'react';

interface IFormConfigsContext {
  isEditing?: boolean;
  setIsEditing?: Dispatch<SetStateAction<boolean>>;
  isConfirmationModalShowing?: boolean;
  setIsConfirmationModalShowing?: Dispatch<SetStateAction<boolean>>;
  confirmButtonEnabled?: boolean;
  updateButtonEnabled?: boolean;
  bookNowButtonEnabled?: boolean;
  bookNowButtonTooltipLabel: string;
}

const FormConfigsContext = createContext<IFormConfigsContext>(
  {} as IFormConfigsContext,
);

export default FormConfigsContext;
