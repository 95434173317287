import { CSS } from '@stitches/react';
import { Flex, Typography } from '../../styledComponents';

const NoDataFoundCard = ({ msg, css = {} }: { msg: string; css?: CSS }) => (
  <Flex
    justify="center"
    gap="1"
    css={{
      backgroundColor: '$gray75',
      br: '$2',
      p: '$2',
      border: '1px solid $gray200',
      color: '$gray600',
      fontSize: '$2',
      ...css,
    }}
  >
    <Typography size="xs" color="gray">
      {msg}
    </Typography>
  </Flex>
);

export default NoDataFoundCard;
