import { CSS } from '@stitches/react';
import 'react-phone-input-2/lib/bootstrap.css';
import { UserECPRelationsMapping } from '../../../../shared/constants/ECP';
import UnOrderedList from '../../../components/UnOrderedList/UnOrderedList';
import { Flex, Typography } from '../../../styledComponents';

const EmergencyContactCard = ({
  name,
  relation,
  phone,
  css,
}: {
  name: string;
  relation: string;
  phone: string;
  css?: CSS;
}) => (
  <Flex
    align="center"
    justify="between"
    css={{
      backgroundColor: '$gray75',
      br: '$2',
      p: '$2',
      border: '1px solid $gray200',
      color: '$gray600',
      fontSize: '$2',
      ...(css ?? {}),
    }}
  >
    <UnOrderedList
      items={[
        { label: name },
        {
          label: (
            <Typography size="xs" color="gray">
              {UserECPRelationsMapping?.[relation]}
            </Typography>
          ),
        },
      ]}
      ulCss={{ width: '50%' }}
      liCss={{ maxWidth: '50%' }}
    />

    <Typography size="xs" color="gray">
      +{phone}
    </Typography>
  </Flex>
);

export default EmergencyContactCard;
