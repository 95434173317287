import * as SwitchPrimitive from '@radix-ui/react-switch';
import { CSS, styled } from '@stitches/react';
import { theme } from '../../stitches.config';
import { Flex } from './Flex';
import { Label } from './Label';

const SwitchRoot = styled(SwitchPrimitive.Root, {
  all: 'unset',
  width: 42,
  height: 25,
  backgroundColor: theme.colors.gray600,
  borderRadius: '9999px',
  position: 'relative',
  boxShadow: '0 2px 2px rgba(0,0,0,0.4)',
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  '&:focus': { boxShadow: '0 0 0 2px black' },
  '&[data-state="checked"]': { backgroundColor: 'black' },
});

const SwitchThumb = styled(SwitchPrimitive.Thumb, {
  display: 'block',
  width: 21,
  height: 21,
  backgroundColor: 'white',
  borderRadius: '9999px',
  boxShadow: '0 2px 2px rgba(0,0,0,0.2)',
  transition: 'transform 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  '&[data-state="checked"]': { transform: 'translateX(19px)' },
});

type SwitchProps = SwitchPrimitive.SwitchProps & {
  label?: string;
  css?: CSS;
};

export const Switch = ({ label, name, css, ...restProps }: SwitchProps) => (
  <form>
    <Flex align="center" gap="2" css={css}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <SwitchRoot name={name} {...restProps}>
        <SwitchThumb />
      </SwitchRoot>
    </Flex>
  </form>
);
