import { format, parseISO } from 'date-fns';
import { useMemo } from 'react';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { SessionEvent } from '../../../shared/constants/events/SessionDetail';
import {
  CaseNoteTypes,
  SessionCallTypesColorVariants,
  SessionCallTypesLabels,
  SessionStatus,
  SessionStatusColorVariants,
  SessionStatusLabels,
} from '../../../shared/constants/Session';
import { useGetSessionData } from '../../../utilities/hooks/queryHooks/session/UseGetSessionData';
import useTracking from '../../../utilities/hooks/UseTracking';
import Container from '../../components/container/Container';
import LoadingView from '../../components/loadingView/LoadingView';
import UnOrderedList from '../../components/UnOrderedList/UnOrderedList';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Tag,
  Tooltip,
} from '../../styledComponents';
import CallerDetails, { ICallerDetailsProps } from './CallerDetails';
import CallLogDetails, { ICallLogDetailsProps } from './CallLogDetails';
import Comments from './Comments';
import RiskAssessmentDetails, {
  IRiskAssessmentDetailsProps,
} from './RiskAssessmentDetails';
import { AssessmentPayload } from '../../../shared/types/Session';
import EngagementDetails, {
  IEngagementDetailsProps,
} from './EngagementDetails';

const SessionDetails = () => {
  const { sessionId } = useParams();
  const { track } = useTracking<SessionEvent>();
  const onSessionDataFetchSuccess = () => {
    track('view_detail', {
      eventAction: 'click',
      eventCategory: 'view_detail',
      eventLabel: 'view_detail',
    });
  };

  const { data: sessionData, isSuccess: sessionDataSuccess } =
    useGetSessionData(sessionId, true, onSessionDataFetchSuccess);

  const startTime = sessionData?.startTime
    ? parseISO(sessionData?.startTime ?? '')
    : undefined;
  const endTime = sessionData?.endTime
    ? parseISO(sessionData?.endTime ?? '')
    : undefined;

  const CallerDetailsValues = useMemo(() => {
    const values: ICallerDetailsProps = {
      name: sessionData?.client?.name,
      organisation: sessionData?.client?.organisation,
      phone: sessionData?.client?.phone,
      email: sessionData?.client?.email,
      risk: sessionData?.risk,
      employeeId: sessionData?.client?.employeeId,
      tenure: sessionData?.client?.tenure,
      age: sessionData?.client?.age,
      location: sessionData?.client?.location,
      city: sessionData?.client?.city,
      landmark: sessionData?.client?.landmark,
      clientPosition: sessionData?.clientPosition,
      callType: sessionData?.callType,
      callStatus: sessionData?.callStatus,
      isDependent: sessionData?.client?.isDependent,
      cispCallType: sessionData?.cispCallType,
      testCallType: sessionData?.testCallType,
      startTime,
      endTime,
    };
    return values;
  }, [sessionData, startTime, endTime]);

  const CallLogValues = useMemo(() => {
    const values: ICallLogDetailsProps = {
      id: sessionData?.id,
      clientId: sessionData?.clientId,
      sessionType: sessionData?.sessionType,
      status: sessionData?.status,
      issue: sessionData?.issue,
      secondaryIssue: sessionData?.secondaryIssue,
      risk: sessionData?.risk,

      callType: sessionData?.callType,
      callStatus: sessionData?.callStatus,
      callReason:
        sessionData?.caseNotes?.find(
          (item) => item?.noteType === CaseNoteTypes.CALL_REASON,
        )?.note ?? '',
      callDescription:
        sessionData?.caseNotes?.find(
          (item) => item?.noteType === CaseNoteTypes.CALL_DESCRIPTION,
        )?.note ?? '',
      rangerIntervention:
        sessionData?.caseNotes?.find(
          (item) => item?.noteType === CaseNoteTypes.RANGER_INTERVENTION,
        )?.note ?? '',
      caseNotesRaw:
        sessionData?.caseNotes?.filter(
          (item) => item?.noteType === CaseNoteTypes.NOTE,
        ) ?? [],
      caseNotes:
        sessionData?.caseNotes
          ?.filter((item) => item?.noteType === CaseNoteTypes.NOTE)
          ?.at(-1)?.note ?? '', // picking the recent one
      startTime,
      endTime,
      meetingConsent: sessionData?.meetingConsent,
      responderName: sessionData?.responderName,
    };

    return values;
  }, [sessionData, endTime, startTime]);

  const RiskAssessmentValues = useMemo(() => {
    const values: IRiskAssessmentDetailsProps = {
      userId: sessionData?.client?.userId,
      organisation: sessionData?.client?.organisation,
      meetingData: {
        type: sessionData?.meetingType,
        data: sessionData?.meeting,
      },
      callType: sessionData?.callType,
      callStatus: sessionData?.callStatus,
      clientAnonymous: false,
      followUpSession: false,
      sessionQuestionResponses:
        sessionData?.sessionQuestionResponses as unknown as AssessmentPayload[],
    };

    return values;
  }, [sessionData]);

  const EngagementDetailsValues = useMemo(() => {
    const values: IEngagementDetailsProps = {
      callAttempts: sessionData?.callAttempts,
      callType: sessionData?.callType,
      callStatus: sessionData?.callStatus,
      escalationCallType: sessionData?.escalationCallType,
      outgoingCallType: sessionData?.outgoingCallType,
    };

    return values;
  }, [sessionData]);

  if (!sessionDataSuccess) {
    return <LoadingView />;
  }

  return (
    <Container>
      <Box
        css={{
          br: '$2',
          backgroundColor: '$gray50',
          p: '$5',
          border: '1px solid $gray200',
        }}
      >
        <Heading size="lg" css={{ fontWeight: '600' }}>
          <Flex gap="2" align="center">
            {sessionData.client.name ?? 'Anonymous'}
            {sessionData?.callType ? (
              <Tag
                variant={SessionCallTypesColorVariants[sessionData.callType]}
              >
                {SessionCallTypesLabels[sessionData.callType]}
              </Tag>
            ) : null}
            {sessionData?.status ? (
              <Tag
                variant={
                  SessionStatusColorVariants[
                    sessionData.status as SessionStatus
                  ]
                }
              >
                {SessionStatusLabels[sessionData.status as SessionStatus]}
              </Tag>
            ) : null}
          </Flex>
        </Heading>
        <UnOrderedList
          items={[
            {
              label: `Session on ${startTime ? format(startTime, 'dd MMM yy') : 'N/A'}`,
            },
            {
              label: `${startTime ? format(startTime, 'h:mm aaa') : 'N/A'} to ${endTime ? format(endTime, 'h:mm aaa') : 'N/A'}`,
              endIcon: !endTime ? (
                <Tooltip label="This session was not completed properly.">
                  <IconButton size="sm" css={{ color: '$darkRed' }}>
                    <IoInformationCircleOutline />
                  </IconButton>
                </Tooltip>
              ) : undefined,
            },
          ]}
        />
        <EngagementDetails sessionData={EngagementDetailsValues} />
        <CallerDetails sessionData={CallerDetailsValues} />
        <RiskAssessmentDetails sessionData={RiskAssessmentValues} />
        <CallLogDetails sessionData={CallLogValues} />
      </Box>
      <Comments
        showCommentButton={
          !(sessionData.sessionType === 1 && sessionData.status === '0')
        }
        callType={sessionData?.callType}
      />
    </Container>
  );
};

export default SessionDetails;
