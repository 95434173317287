import { FormProvider, useForm } from 'react-hook-form';
import { HiOutlineClock } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
import {
  NoOrganisationCode,
  SessionCallStatus,
} from '../../../shared/constants/Session';
import {
  CaseNoteFormTypesV2,
  OutgoingCallDefaultValues,
} from '../../../shared/types/CaseNoteFormV2';
import { CaseNoteSession } from '../../../shared/types/Session';
import { useGetSessionData } from '../../../utilities/hooks/queryHooks/session/UseGetSessionData';
import CallInformation from '../../app-components/CallInformation/CallInformation';
import ClientDetails from '../../app-components/ClientDetails/ClientDetails';
import FetchClientDetailsForm from '../../app-components/ClientDetails/FetchClientDetailsForm';
import FollowUpSessionDetails from '../../app-components/FollowUpSessionDetails/FollowUpSessionDetails';
import FormConfirmationV2 from '../../app-components/FormConfirmationV2/FormConfirmationV2';
import OrganisationDetails from '../../app-components/OrganisationDetails/OrganisationDetails';
import PreventNavigationWrapper from '../../app-components/PreventNavigationWrapper/PreventNavigationWrapper';
import RiskAssessmentCaseNotesDetails from '../../app-components/RiskAssessment/RiskAssessmentCaseNotesDetails';
import LoadingView from '../../components/loadingView/LoadingView';
import { Flex, Heading } from '../../styledComponents';
import { Grid } from '../../styledComponents/Grid';
import { GridItem } from '../../styledComponents/GridItem';
import CaseNoteTimer from '../caseNotesForm/CaseNoteTimer';
import FormConfigsProvider from '../../app-components/FormConfigsProvider/FormConfigsProvider';

// Listening on these values, if any of these changes then navigation will be prevented
const preventNavigationDefaultValues = {
  id: OutgoingCallDefaultValues.id,
  userId: OutgoingCallDefaultValues.userId,
  phone: OutgoingCallDefaultValues.phone,
  name: OutgoingCallDefaultValues.name,
  callReason: OutgoingCallDefaultValues.callReason,
  callDescription: OutgoingCallDefaultValues.callDescription,
  rangerIntervention: OutgoingCallDefaultValues.rangerIntervention,
  organisation: undefined,
  callType: OutgoingCallDefaultValues.callType,
  callStatus: OutgoingCallDefaultValues.callStatus,
  callAttempts: OutgoingCallDefaultValues.callAttempts,
  outgoingCallType: undefined,
};

const OutgoingCallFormBase = () => {
  const { sessionId } = useParams();

  const methods = useForm<CaseNoteFormTypesV2>({
    defaultValues: { ...OutgoingCallDefaultValues, startTime: new Date() },
    mode: 'onTouched',
  });

  const onSessionDetailFetchSuccess = (data: CaseNoteSession) => {
    const { location = '', city = '' } = data?.client ?? {};

    methods.reset({
      ...data.client,
      originalPhone: data?.client?.phone ?? '',
      organisation: +(data.client.organisation || NoOrganisationCode),
      issue: data.issue,
      secondaryIssue: data.secondaryIssue,
      risk: data.risk,
      sessionId: data.id,
      assessment: data.sessionQuestionResponses,
      callStatus: data.callStatus,
      callAttempts: data.callAttempts,
      escalationCallType: data.escalationCallType,
      outgoingCallType: data.outgoingCallType,
      clientDetails: { location, city },
    });
  };
  const { data: sessionData, isLoading: isSessionDetailsLoading } =
    useGetSessionData(
      sessionId,
      Boolean(sessionId),
      onSessionDetailFetchSuccess,
    );

  if (sessionId && (isSessionDetailsLoading || !sessionData)) {
    return <LoadingView />;
  }

  const { callStatus } = methods.watch();

  return (
    <FormProvider {...methods}>
      <FormConfigsProvider>
        <Grid columns={9}>
          <GridItem xs={9} md={7} sm={7} lg={7}>
            <Flex direction="column" gap="4" css={{ padding: '$6' }}>
              <Flex justify="between" align="center">
                <Flex align="center" justify="center" gap="2">
                  <Heading size="md">Outgoing call</Heading>
                  {!sessionId && (
                    <Flex align="center" gap="1">
                      <HiOutlineClock size={18} />
                      <CaseNoteTimer size="md" />
                    </Flex>
                  )}
                </Flex>
              </Flex>
              <CallInformation />
              <OrganisationDetails />
              {callStatus === SessionCallStatus.ANSWERED ? (
                <>
                  <RiskAssessmentCaseNotesDetails />
                  <FollowUpSessionDetails />
                </>
              ) : null}
              <FormConfirmationV2 />
            </Flex>
          </GridItem>

          {!methods.watch('userId') ? (
            <FetchClientDetailsForm />
          ) : (
            <ClientDetails />
          )}

          <PreventNavigationWrapper
            defaultValues={preventNavigationDefaultValues}
          />
        </Grid>
      </FormConfigsProvider>
    </FormProvider>
  );
};

export default OutgoingCallFormBase;
