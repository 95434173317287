import {
  RiskColorVariants,
  RiskLabels,
  RiskLevels,
} from '../../../shared/constants/Risk';
import {
  AgeOptions,
  SessionCallStatus,
  SessionCallTypes,
  TenureOptions,
  TestFormTypes,
  TestFormTypesLabels,
} from '../../../shared/constants/Session';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import { ValueOf } from '../../../shared/types/Common';
import { getDisplayDate, getDisplayTime } from '../../../utilities/common/Date';
import { shouldShowField } from '../../../utilities/common/Session';
import { useGetOrganisationsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import { Box, Grid, Heading, Tag, Typography } from '../../styledComponents';

export interface ICallerDetailsProps {
  organisation?: number;
  phone?: string;
  email?: string;
  name?: string;
  risk?: (typeof RiskLevels)[keyof typeof RiskLevels];
  employeeId?: string;
  tenure?: string;
  age?: string;
  location?: string;
  city?: string;
  landmark?: string;
  clientPosition?: string;
  callType?: SessionCallTypes;
  isDependent?: boolean;
  startTime?: Date;
  endTime?: Date;
  cispCallType?: string;
  testCallType?: TestFormTypes;
  callStatus?: SessionCallStatus;
}

const CallerDetails = ({
  sessionData,
}: {
  sessionData: ICallerDetailsProps;
}) => {
  const {
    name,
    organisation,
    phone,
    email,
    risk,
    employeeId,
    tenure,
    age,
    location,
    city,
    landmark,
    clientPosition,
    callType,
    callStatus,
    isDependent,
    startTime,
    endTime,
    cispCallType,
    testCallType,
  } = sessionData;

  const { isSuccess: organisationsListSuccess, data: organisationsList } =
    useGetOrganisationsList();

  const isTestCall = callType === SessionCallTypes.TEST_CALL;

  const shouldShow = (field: keyof CaseNoteFormTypesV2) =>
    shouldShowField({ field, callStatus, callType });

  return (
    <>
      <Heading size="sm" css={{ fontWeight: '600' }}>
        Caller details
      </Heading>
      <Grid gapX="6" gapY="4" columns={4} css={{ p: '$4' }}>
        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Company
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {organisationsListSuccess && organisationsList?.length ? (
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {organisationsList?.find((org) => org.id === organisation)
                  ?.name ?? 'No information'}
              </Typography>
            ) : (
              'No information'
            )}
          </Typography>
        </Box>

        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Phone number
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {phone}
          </Typography>
        </Box>

        <Box>
          {shouldShow('email') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                Email
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {email ?? 'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>
        <Box>
          {shouldShow('risk') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                Risk assessment
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                <Tag
                  size="sm"
                  variant={
                    RiskColorVariants[
                      risk as unknown as ValueOf<typeof RiskLevels>
                    ]
                  }
                >
                  {RiskLabels[risk as unknown as ValueOf<typeof RiskLevels>]}
                </Tag>
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>

        <Box>
          {!isTestCall ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                Client name
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {name || 'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>

        <Box>
          {shouldShow('tenure') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                Tenure
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {TenureOptions.find(
                  (tenureOption) => tenureOption.value === tenure,
                )?.label ?? 'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>

        <Box>
          {shouldShow('employeeId') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                Employee/Staff ID
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {employeeId || 'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>
        <Box>
          {shouldShow('clientPosition') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                Position
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {clientPosition || 'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>

        <Box>
          {shouldShow('age') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                Age
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {AgeOptions.find((ageOption) => ageOption.value === age)
                  ?.label ?? 'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>
        <Box>
          {shouldShow('location') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                Location
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {location || 'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>
        <Box>
          {shouldShow('city') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                City
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {city || 'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>
        <Box>
          {shouldShow('landmark') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                Landmark
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {landmark || 'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>
        <Box>
          {shouldShow('cispCallType') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                CISP Type
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {cispCallType ?? 'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>
        <Box>
          {shouldShow('testCallType') ? (
            <>
              <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
                Test Type
              </Typography>
              <Typography size="xs" css={{ fontWeight: '500' }}>
                {TestFormTypesLabels?.[testCallType as TestFormTypes] ??
                  'No information'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>

        <Box>
          <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
            Call Time
          </Typography>
          <Typography size="xs" css={{ fontWeight: '500' }}>
            {startTime ? getDisplayDate(startTime) : ''}{' '}
            {startTime ? getDisplayTime(startTime) : ''} {' - '}
            {endTime ? getDisplayTime(endTime) : ''}
          </Typography>
        </Box>
        {isDependent && (
          <Box>
            <Typography size="xs" color="gray600" css={{ mb: '$1' }}>
              Client Type
            </Typography>
            <Tag size="sm" variant="lavender">
              Dependent
            </Tag>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default CallerDetails;
