import { CreditPoolTypes, ProviderRole } from '../../shared/constants/Credits';
import { CreditPool, UserCreditPool } from '../../shared/types/Credits';

/**
 * Common function to give plan summary in an array
 * @param credits
 * @returns {string[]}
 */
export const GenerateActivePlanSummary = (credits: CreditPool[]) => {
  const summary = credits?.map((pool) => {
    const heading =
      CreditPoolTypes.find((item) => item.type === pool.type)?.label || '';
    let type = '';
    let globalCredits = '';
    let userCredits = '';

    if (pool.credits === null && pool.creditPerUser === null) type = 'UNL';
    else if (pool.credits === null && pool.creditPerUser !== null) {
      userCredits = pool?.creditPerUser ? `${pool?.creditPerUser}x` : '';
      type = 'USR';
    } else if (pool.credits !== null && pool.creditPerUser !== null) {
      globalCredits = pool?.credits ? `${pool?.credits}` : '';
      userCredits = pool?.creditPerUser ? `${pool?.creditPerUser}x` : '';
      type = 'HYD';
    } else if (pool.credits !== null && pool.creditPerUser === null) {
      globalCredits = pool?.credits ? `${pool?.credits}` : '';
      type = 'Pool';
    }

    const obj = {
      credits: `${globalCredits ? `${globalCredits} / ` : ''}${
        userCredits ? `${userCredits} / ` : ''
      }${type}`,
      label: heading,
    };

    return obj;
  });

  return summary;
};

export const GetCreditLabel = (type: ProviderRole) => {
  const foundType = CreditPoolTypes.find(
    (creditType) => creditType.type === type,
  );
  return foundType ? foundType.label : 'Unknown';
};

export const GenerateUserActivePlanSummary = (
  userCredits: UserCreditPool[],
) => {
  const summary = userCredits?.map((uc) => {
    const label = GetCreditLabel(uc?.type ?? '');
    let credits = '';

    if (uc.type === ProviderRole.CAREOFFSITE) {
      credits = 'Same as Care';
    } else if (uc.tier === '1') {
      credits = 'UNL';
    } else if (uc.credit === null) {
      credits = 'Pool';
    } else {
      credits = `${uc.credit}X`;
    }

    return {
      label,
      credits,
    };
  });

  return summary;
};
