import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import ApiConfig from '../../../../config/ApiConfig';
import { doPost } from '../../../../shared/service/NetworkClient';
import { SupportRequestFormTypes } from '../../../../shared/types/SupportRequest';

export const useCreateSupportRequest = (onSuccess: () => void) =>
  useMutation(
    (payload: SupportRequestFormTypes) =>
      doPost(ApiConfig.api.system.supportRequest, payload).then(
        (res) => res.data.data,
      ),
    {
      onSuccess,
      onError: () => {
        toast.error('An error occurred while creating the support request.');
      },
    },
  );
