import { VirtualElement } from '@popperjs/core';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { IoCloseOutline } from 'react-icons/io5';
import PhoneInput from 'react-phone-input-2';
import { SupportRequestType } from '../../../shared/constants/SupportRequest';
import { SupportRequestFormTypes } from '../../../shared/types/SupportRequest';
import { convertIntoComboboxOptions } from '../../../utilities/common/ConvertIntoOptions';
import { useGetOrganisationsList } from '../../../utilities/hooks/queryHooks/caseNotes/UseGetOrganisationsList';
import { useCreateSupportRequest } from '../../../utilities/hooks/queryHooks/system/UseCreateSupportRequest';
import Popper from '../../components/Popper/Popper';
import {
  Box,
  Button,
  Combobox,
  Flex,
  IconButton,
  Label,
  TextArea,
  Typography,
} from '../../styledComponents';
import { ChipButton } from '../../styledComponents/ChipButton';
import { onlyWhiteSpaceRegex } from '../../../utilities/common/FormValidatorRegex';

const SupportRequest = ({
  anchorEl,
  handleClose,
}: {
  anchorEl: Element | VirtualElement | null;
  handleClose: () => void;
}) => {
  const {
    watch,
    setValue,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<SupportRequestFormTypes>({
    defaultValues: {
      requestType: SupportRequestType.SUPPORT,
      issue: '',
      organisationId: '',
      organisationName: '',
      phone: '',
    },
    mode: 'all',
  });

  const handleSuccess = () => {
    toast.success('Ticket created successfully.');
    reset();
    handleClose();
  };

  const { data: organisationsList, dataUpdatedAt } = useGetOrganisationsList();
  const { mutate: createSupportRequest, isLoading } =
    useCreateSupportRequest(handleSuccess);

  const { requestType, organisationId } = watch();

  useEffect(() => {
    const orgName = organisationsList?.find(
      (org) => org.id === +organisationId,
    );

    setValue('organisationName', orgName?.name ?? '');
  }, [organisationId, organisationsList, setValue]);

  const compulsoryValuesFilled = () => {
    const validationFields: (keyof SupportRequestFormTypes)[] = [];

    switch (requestType) {
      case SupportRequestType.CARE_NAVIGATOR:
        validationFields.push(
          ...([
            'issue',
            'organisationId',
            'organisationName',
            'phone',
          ] as (keyof SupportRequestFormTypes)[]),
        );
        break;
      case SupportRequestType.SUPPORT:
      case SupportRequestType.TECH:
      default:
        validationFields.push(
          ...(['issue'] as (keyof SupportRequestFormTypes)[]),
        );
        break;
    }

    return (
      watch(validationFields).every(
        (value) => value !== null && value !== undefined && value !== '',
      ) && validationFields.every((field) => !errors[field])
    );
  };

  if (!anchorEl) return null;

  return (
    <Popper
      anchorEl={anchorEl}
      handleClose={() => {
        handleClose();
      }}
      placement="auto-end"
    >
      <Flex
        direction="column"
        css={{
          width: '24vw',
          backgroundColor: '#fff',
          border: '1px solid $gray175',
          borderRadius: '$4',
          p: '$4',
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        }}
      >
        <Flex justify="between" align="center" css={{ mb: '$4' }}>
          <Typography size="lg" css={{ fontWeight: 500 }}>
            Describe the issue
          </Typography>
          <IconButton type="button" onClick={handleClose}>
            <IoCloseOutline size={26} />
          </IconButton>
        </Flex>
        <Flex justify="between" align="center" css={{ mb: '$4' }}>
          <Label htmlFor="organisation">
            Type *
            <Flex gap="2" wrap="wrap" css={{ marginTop: '$2' }}>
              <ChipButton
                key="call-status-not-answered"
                selected={requestType === SupportRequestType.SUPPORT}
                onClick={() =>
                  setValue('requestType', SupportRequestType.SUPPORT)
                }
                size="sm"
              >
                Customer Support
              </ChipButton>
              <ChipButton
                key="call-status-answered"
                selected={requestType === SupportRequestType.CARE_NAVIGATOR}
                onClick={() =>
                  setValue('requestType', SupportRequestType.CARE_NAVIGATOR)
                }
                size="sm"
              >
                Care Navigator
              </ChipButton>
              <ChipButton
                key="call-status-answered"
                selected={requestType === SupportRequestType.TECH}
                onClick={() => setValue('requestType', SupportRequestType.TECH)}
                size="sm"
              >
                Tech
              </ChipButton>
            </Flex>
          </Label>
        </Flex>
        {requestType === SupportRequestType.CARE_NAVIGATOR ? (
          <>
            <Box>
              <Label htmlFor="organisation" css={{ width: '100%' }}>
                Organisation *
                <Controller
                  name="organisationId"
                  defaultValue={watch('organisationId')}
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <Combobox
                      key={`${dataUpdatedAt}-${watch('organisationId')}`}
                      options={convertIntoComboboxOptions(
                        organisationsList ?? [],
                        'name',
                        'id',
                      )}
                      selectedValue={value?.toString()}
                      onOptionSelect={(newOption) => onChange(newOption)}
                      {...rest}
                    />
                  )}
                  rules={{
                    required: {
                      value: requestType === SupportRequestType.CARE_NAVIGATOR,
                      message: 'This is required.',
                    },
                  }}
                />
                <Typography color="darkRed" size="xs">
                  {errors.organisationId?.message?.toString()}
                </Typography>
              </Label>
            </Box>
            <Box>
              <Label htmlFor="phone">Phone number *</Label>
              <Flex
                justify="between"
                align="center"
                gap={3}
                css={{
                  width: '100%',
                  flexGrow: 1,
                  border: '1px solid $gray300',
                  borderRadius: '0.5rem',
                  mt: '$1',
                  mb: '$4',
                  pr: '$1',
                }}
              >
                <Box css={{ flexGrow: 1 }}>
                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: {
                        value:
                          requestType === SupportRequestType.CARE_NAVIGATOR,
                        message: 'This is required.',
                      },
                      minLength: {
                        value: 8,
                        message:
                          'Phone number should have a minimum of 8 digits.',
                      },
                    }}
                    render={({ field: { ref, ...rest } }) => (
                      <PhoneInput
                        placeholder=""
                        inputProps={{
                          ref,
                          required: true,
                        }}
                        inputClass="phone-input"
                        country="sg"
                        inputStyle={{ height: '44px' }}
                        {...rest}
                      />
                    )}
                  />
                </Box>
              </Flex>
            </Box>
          </>
        ) : null}
        <Box>
          <Flex justify="between" css={{ mb: '$2' }}>
            <Label htmlFor="case-note-text">Issue/Remarks *</Label>
          </Flex>
          <TextArea
            data-cy="text-area-call-description"
            size="md"
            rows={6}
            {...register('issue', {
              required: { value: true, message: 'This is required' },
              pattern: {
                value: onlyWhiteSpaceRegex,
                message: 'This cannot be only whitespace..',
              },
            })}
          />
          <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
            {errors.issue?.message?.toString()}
          </Typography>
        </Box>
        <Flex direction="column" gap="2">
          <Typography size="xs" color="gray" css={{ textAlign: 'center' }}>
            We will share this to relevant channels where tech team can help to
            resolve this issue
          </Typography>
          <Button
            isLoading={isLoading}
            onClick={() =>
              createSupportRequest({
                ...watch(),
                issue: watch('issue').trim(),
              })
            }
            disabled={!compulsoryValuesFilled()}
          >
            Send
          </Button>
        </Flex>
      </Flex>
    </Popper>
  );
};

export default SupportRequest;
