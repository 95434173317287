import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';
import ApiConfig from '../../../../config/ApiConfig';
import { ClientPresentingIssue } from '../../../../shared/types/Client';

export const useClientPresentingIssue = (userId?: string) =>
  useQuery<ClientPresentingIssue>(
    [QueryKeys.getClientPresentingIssue, userId],
    () =>
      doGet(
        ApiConfig.api.clients.clientUserPresentingIssue.replace(
          '{{userId}}',
          userId || '',
        ),
      ).then((res) => res.data.data),
    { enabled: !!userId && userId !== 'null' },
  );
