import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CaseNoteFormEvent } from '../../../shared/constants/events/CaseNoteForm';
import { useCancelSession } from '../../../utilities/hooks/queryHooks/caseNotes/UseCancelSession';
import { useCreateClientAndSession } from '../../../utilities/hooks/queryHooks/client/UseCreateClient';
import useTracking from '../../../utilities/hooks/UseTracking';
import Modal from '../../components/modal/Modal';
import { TextArea } from '../../styledComponents';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';

const CancellationModalV2 = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const { getValues, watch } = useFormContext<CaseNoteFormTypesV2>();
  const { track } = useTracking<CaseNoteFormEvent>();

  const [reason, setReason] = useState<string>('');

  const cancelSession = useCancelSession();

  const onSessionCreateSuccess = (sessionId: string) => {
    track('cancel_session', {
      eventAction: 'click',
      eventCategory: 'cancel_session',
      eventLabel: 'cancel_confirm',
    });
    cancelSession.mutate({ sessionId, reason });
  };

  const createClientAndSession = useCreateClientAndSession({
    onSessionCreateSuccess,
    sessionCallType: watch('callType'),
    apiVersion: 2,
  });

  const handleSubmit = () => {
    const sId = getValues('sessionId');
    if (!sId) {
      // User does not exist, will create client and session
      const {
        name,
        email,
        phone,
        location,
        city,
        landmark,
        organisation,
        tenure,
        age,
        employeeId,
      } = getValues();
      const nonNullValues = Object.fromEntries(
        Object.entries({
          name,
          email,
          phone,
          location,
          city,
          landmark,
          organisation,
          tenure,
          age,
          employeeId,
        }).filter(([_, v]) => !!v),
      );
      createClientAndSession.mutate(nonNullValues);
    } else {
      track('cancel_session', {
        eventAction: 'click',
        eventCategory: 'cancel_session',
        eventLabel: 'cancel_confirm',
      });
      cancelSession.mutate({ sessionId: sId, reason });
    }
  };
  return (
    <Modal
      title="We're sorry to see you go."
      subtitle="Let us know what went wrong and where can we improve."
      confirmButtonColor="red"
      confirmButtonText="Continue"
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    >
      <TextArea
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
          setReason(event.target.value)
        }
        value={reason}
        size="lg"
        css={{ height: '25vh' }}
      />
    </Modal>
  );
};

export default CancellationModalV2;
