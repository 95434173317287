import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import ApiConfig from '../../../../config/ApiConfig';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';
import {
  ClientCountryCitiesList,
  ClientRegionsList,
} from '../../../../shared/types/Session';

export const useGetRegionsList = ({
  keepDidNotDisclose = true,
}: {
  keepDidNotDisclose?: boolean;
} = {}) =>
  useQuery<ClientRegionsList[]>(
    [QueryKeys.getRegionsList],
    () =>
      doGet(ApiConfig.api.clients.getClientRegions).then((res) => {
        const regions = [];
        if (keepDidNotDisclose) {
          regions.push({
            region: 'Did not disclose',
            translation: 'Did not disclose',
            countryCode: 'NA',
            ageMin: 12,
            ageMax: 21,
          });
        }
        regions.push(...(res?.data?.data ?? []));

        return regions;
      }),
    {
      onError: () => {
        toast.error('An error occurred while getting the list of locations.');
      },
      refetchOnWindowFocus: false,
    },
  );

export const useGetCountryCities = (countryCode: string) =>
  useQuery<ClientCountryCitiesList[]>(
    [QueryKeys.getCountryCitiesList, countryCode],
    () =>
      doGet(
        ApiConfig.api.clients.getCountryCities.replace(
          '{{countryCode}}',
          countryCode,
        ),
      ).then((res) => res?.data?.data ?? []),
    {
      enabled: Boolean(countryCode),
      onError: () => {
        toast.error('An error occurred while getting the list of cities.');
      },
      refetchOnWindowFocus: false,
      // cache handled on backend
      cacheTime: 0,
      staleTime: 0,
    },
  );
