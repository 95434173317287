import { styled } from '@stitches/react';
import { useCombobox } from 'downshift';
import React, { useMemo, useState } from 'react';
import { Noop } from 'react-hook-form';
import { ComboboxOption } from '../../shared/types/SelectTypes';
import { InputContainer } from './Input';
import { Menu, MenuItem } from './Menu';
import { Tag } from './Tag';
import { Flex } from './Flex';

const RemoveButton = styled('button', {
  background: 'transparent',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
});

interface MultiSelectComboBoxProps {
  options: ComboboxOption[];
  selectedOptions?: string[];
  onChange: (items: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  onBlur?: Noop;
  limitTags?: number;
}

export const MultiSelectCombobox = React.forwardRef<
  HTMLInputElement,
  MultiSelectComboBoxProps
>(
  (
    {
      options,
      placeholder,
      name,
      onBlur,
      selectedOptions = [],
      onChange,
      limitTags,
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState('');

    const { isOpen, getMenuProps, getInputProps, reset } = useCombobox({
      items: options.filter(
        (item) =>
          item.label.toLowerCase().includes(inputValue.toLowerCase()) &&
          !selectedOptions?.includes(item.value),
      ),
      onInputValueChange: ({ inputValue: newInputValue }) =>
        setInputValue(newInputValue || ''),
    });

    const handleSelect = (item: string) => {
      if (limitTags && selectedOptions?.length >= limitTags) return;

      if (!selectedOptions.includes(item)) {
        onChange([...selectedOptions, item]); // Update parent state
      }
      reset();
    };

    const handleRemove = (item: string) => {
      onChange(selectedOptions.filter((i) => i !== item));
    };

    const OptionsMap = useMemo(
      () =>
        options?.reduce(
          (result: Record<string, string>, item: ComboboxOption) => {
            // eslint-disable-next-line no-param-reassign
            result[item.value] = item.label;

            return result;
          },
          {},
        ),
      [options],
    );

    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <InputContainer css={{ maxWidth: '100%' }}>
          <Flex
            id="chips-container"
            wrap="wrap"
            gap="1"
            css={{ width: '100%' }}
          >
            {selectedOptions?.map((item) => (
              <Tag
                key={item}
                css={{
                  backgroundColor: '$gray',
                  color: '#fff',
                  padding: '4px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                {OptionsMap?.[item] || item}
                <RemoveButton onClick={() => handleRemove(item)}>
                  x
                </RemoveButton>
              </Tag>
            ))}
            <input
              ref={ref}
              {...getInputProps({ name, onBlur }, { suppressRefError: true })}
              placeholder={placeholder || 'Search...'}
              style={{
                flex: 1,
                border: 'none',
                outline: 'none',
                width: '100%',
              }}
            />
          </Flex>
        </InputContainer>

        <Menu
          css={{
            position: 'absolute',
            width: '100%',
            marginTop: '-0.5rem',
            maxHeight: '35rem',
            overflowY: 'auto',
            ...(!(isOpen && options?.length) && {
              display: 'none',
            }),
          }}
          {...getMenuProps({}, { suppressRefError: true })}
        >
          {isOpen &&
            options
              .filter(
                (item) =>
                  item.label.toLowerCase().includes(inputValue.toLowerCase()) &&
                  !selectedOptions.includes(item.value),
              )
              .map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => handleSelect(option.value)}
                >
                  {option.label}
                </MenuItem>
              ))}
        </Menu>
      </div>
    );
  },
);
