const LabelWithAsterik = ({
  label,
  hideAsterik = false,
}: {
  label: string;
  hideAsterik?: boolean;
}) => (
  <>
    {label}
    {!hideAsterik ? (
      <>
        &nbsp;<span style={{ color: 'red' }}>*</span>
      </>
    ) : null}
  </>
);

export default LabelWithAsterik;
