import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import ApiConfig from '../../../../config/ApiConfig';
import { doPost } from '../../../../shared/service/NetworkClient';
import { CaseNoteTypes } from '../../../../shared/constants/Session';

/**
 * Used in old form incomin-call-old
 * @param onSuccess
 * @returns
 */
export const useCreateComment = (onSuccess: () => void) =>
  useMutation(
    ({ sessionId, note }: { sessionId: string; note: string }) =>
      doPost(
        ApiConfig.api.sessions.addNote.replace('{{sessionId}}', sessionId),
        {
          note,
        },
      ).then((res) => res.data.data),
    {
      onSuccess,
      onError: () => {
        toast.error('An error occurred while adding the comment.');
      },
    },
  );

/**
 * Used in new forms
 * @param onSuccess
 * @returns
 */
export const useCreateCommentV2 = (onSuccess: () => void) =>
  useMutation(
    ({ sessionId, note }: { sessionId: string; note: string }) =>
      doPost(
        ApiConfig.api.sessions.addNoteBulk.replace('{{sessionId}}', sessionId),
        {
          notes: [{ note, noteType: CaseNoteTypes.COMMENT }],
        },
      ).then((res) => res.data.data),
    {
      onSuccess,
      onError: () => {
        toast.error('An error occurred while adding the comment.');
      },
    },
  );
