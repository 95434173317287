import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import FormConfigsContext from '../../../shared/context/FormConfigsContext';
import { CaseNoteFormTypesV2 } from '../../../shared/types/CaseNoteFormV2';
import {
  NoOrganisationCode,
  SessionCallStatus,
  SessionCallTypes,
} from '../../../shared/constants/Session';
import { isValidValue } from '../../../utilities/common/Common';
import { RiskLevels } from '../../../shared/constants/Risk';

const IncomingCallMandatoryFields: (keyof CaseNoteFormTypesV2)[] = [
  'phone',
  'issue',
  'secondaryIssue',
  'callReason',
  'callDescription',
  'rangerIntervention',
  'risk',
];

const IncomingCallMandatoryFieldsForUpdate: (keyof CaseNoteFormTypesV2)[] = [
  'phone',
  'issue',
  'secondaryIssue',
  'risk',
];

const FollowUpSessionMandatoryFields: (keyof CaseNoteFormTypesV2)[] = [
  'phone',
  'email',
  'name',
];

const BookNowMandatoryFields: (keyof CaseNoteFormTypesV2)[] = [
  'phone',
  'email',
  'name',
  'organisation',
];

const FormConfigsProvider = ({ children }: { children: React.ReactNode }) => {
  const { sessionId } = useParams();
  const {
    watch,
    formState: { errors },
  } = useFormContext<CaseNoteFormTypesV2>();

  const [isEditing, setIsEditing] = useState(Boolean(sessionId));
  const [isConfirmationModalShowing, setIsConfirmationModalShowing] =
    useState(false);

  useEffect(() => {
    setIsEditing(Boolean(sessionId));
  }, [sessionId]);

  const areRiskAssessmentQuestionConditionsMet = () => {
    if (
      [RiskLevels.High, RiskLevels.HighCritical].some(
        (r) => r === watch('risk'),
      )
    ) {
      return watch('assessment')
        .filter((answer) => answer.isRequired)
        .every((answer) => answer.response);
    }

    return true;
  };

  const getIsRiskAssessmentFilled = () => {
    const { callType, callStatus } = watch();
    /**
     * In escation & outgoing call if call is not answered then we do not ask risk details
     * also in cisp and test calls we do not ask risk details
     */
    if (
      ([
        SessionCallTypes.OUTGOING_CALL,
        SessionCallTypes.ESCALATIONS_CHECKINS_CALL,
      ].includes(callType) &&
        callStatus === SessionCallStatus.NOT_ANSWERED) ||
      [SessionCallTypes.CISP_CALL, SessionCallTypes.TEST_CALL].includes(
        callType,
      )
    ) {
      return true;
    }

    return areRiskAssessmentQuestionConditionsMet();
  };

  const compulsoryValuesFilled = (isBookingSession = false) => {
    const { callType, callStatus } = watch();

    const validationFields: (keyof CaseNoteFormTypesV2)[] = [];
    /**
     * List of optional fields for error validation.
     * These fields are not mandatory, but if a value is provided, it will be validated.
     */
    const optionalErrorFields: (keyof CaseNoteFormTypesV2)[] = [];

    switch (callType) {
      case SessionCallTypes.ESCALATIONS_CHECKINS_CALL:
        if (callStatus === SessionCallStatus.ANSWERED) {
          validationFields.push(
            ...IncomingCallMandatoryFields,
            'callStatus',
            'callAttempts',
            'escalationCallType',
          );
        } else {
          validationFields.push(
            ...([
              'callStatus',
              'callAttempts',
              'phone',
            ] as (keyof CaseNoteFormTypesV2)[]),
          );
        }
        break;
      case SessionCallTypes.OUTGOING_CALL:
        if (callStatus === SessionCallStatus.ANSWERED) {
          validationFields.push(
            ...IncomingCallMandatoryFields,
            'callStatus',
            'callAttempts',
            'escalationCallType',
            'outgoingCallType',
          );
        } else {
          validationFields.push(
            ...([
              'callStatus',
              'callAttempts',
              'phone',
            ] as (keyof CaseNoteFormTypesV2)[]),
          );
        }
        break;
      case SessionCallTypes.CISP_CALL:
        validationFields.push(
          ...([
            'name',
            'phone',
            'email',
            'cispCallType',
          ] as (keyof CaseNoteFormTypesV2)[]),
        );
        optionalErrorFields.push(
          ...(['caseNotes'] as (keyof CaseNoteFormTypesV2)[]),
        );
        break;
      case SessionCallTypes.TEST_CALL:
        validationFields.push(
          ...(['testCallType', 'phone'] as (keyof CaseNoteFormTypesV2)[]),
        );
        optionalErrorFields.push(
          ...(['caseNotes'] as (keyof CaseNoteFormTypesV2)[]),
        );
        break;
      case SessionCallTypes.INCOMING_CALL:
      default:
        validationFields.push(...IncomingCallMandatoryFields);
        break;
    }

    if (isBookingSession) {
      validationFields.push(...BookNowMandatoryFields);
    }

    return (
      watch(validationFields).every(isValidValue) &&
      [...validationFields, ...optionalErrorFields].every(
        (field) => !errors[field],
      )
    );
  };

  const compulsoryValuesFilledForUpdate = () => {
    const { callType, callStatus } = watch();

    const validationFields: (keyof CaseNoteFormTypesV2)[] = [];

    switch (callType) {
      case SessionCallTypes.ESCALATIONS_CHECKINS_CALL:
        if (callStatus === SessionCallStatus.ANSWERED) {
          validationFields.push(
            ...IncomingCallMandatoryFieldsForUpdate,
            'callStatus',
            'callAttempts',
            'escalationCallType',
          );
        } else {
          validationFields.push(
            ...([
              'callStatus',
              'callAttempts',
              'phone',
            ] as (keyof CaseNoteFormTypesV2)[]),
          );
        }
        break;
      case SessionCallTypes.OUTGOING_CALL:
        if (callStatus === SessionCallStatus.ANSWERED) {
          validationFields.push(
            ...IncomingCallMandatoryFieldsForUpdate,
            'callStatus',
            'callAttempts',
            'escalationCallType',
            'outgoingCallType',
          );
        } else {
          validationFields.push(
            ...([
              'callStatus',
              'callAttempts',
              'phone',
            ] as (keyof CaseNoteFormTypesV2)[]),
          );
        }
        break;
      case SessionCallTypes.CISP_CALL:
        validationFields.push(
          ...([
            'name',
            'phone',
            'cispCallType',
          ] as (keyof CaseNoteFormTypesV2)[]),
        );
        break;
      case SessionCallTypes.TEST_CALL:
        validationFields.push(
          ...(['testCallType', 'phone'] as (keyof CaseNoteFormTypesV2)[]),
        );
        break;
      case SessionCallTypes.INCOMING_CALL:
      default:
        validationFields.push(...IncomingCallMandatoryFieldsForUpdate);
        break;
    }

    return (
      watch(validationFields).every(isValidValue) &&
      validationFields.every((field) => !errors[field])
    );
  };

  const orgOptionalValuesFilledOrAnonymous =
    watch(['organisation']).every(Boolean) &&
    ['organisation'].every((val) => val in errors === false);

  const isNewUser = !watch('id');
  const isDependent = watch('isDependent');
  const hasRequiredFields =
    watch(FollowUpSessionMandatoryFields).every(isValidValue) &&
    FollowUpSessionMandatoryFields.every((val) => !(val in errors));
  const hasLocationDetails =
    isNewUser && !isDependent
      ? Boolean(watch('location')) && Boolean(watch('city'))
      : true;

  const followUpSessionValidations = watch('followUpSession') // Validation applies only when followUpSession is true
    ? hasRequiredFields && hasLocationDetails
    : true; // If no follow up session needed then

  const anonymous: boolean = watch('orgAnonymous');
  const userIsFromUnknownOrg = watch('organisation') === NoOrganisationCode;

  const canBookWhenDependent = useCallback(() => {
    if (watch('id')) return true;
    if (watch('userId') && watch('coachingOrClinicalEnabled')) return true;
    return false;
  }, [watch]);

  const confirmButtonEnabled =
    compulsoryValuesFilled() &&
    followUpSessionValidations &&
    orgOptionalValuesFilledOrAnonymous &&
    getIsRiskAssessmentFilled();

  const updateButtonEnabled =
    compulsoryValuesFilledForUpdate() &&
    areRiskAssessmentQuestionConditionsMet();

  const bookNowButtonEnabled =
    compulsoryValuesFilled(true) &&
    hasLocationDetails &&
    areRiskAssessmentQuestionConditionsMet() &&
    !anonymous &&
    !userIsFromUnknownOrg &&
    !(watch('isDependent') && !canBookWhenDependent());

  const bookNowButtonTooltipLabel: string = useMemo(() => {
    if (anonymous) {
      return 'Cannot book session as client does not want to share further information.';
    }
    if (userIsFromUnknownOrg) {
      return 'Cannot book session as user is not a part of an organisation.';
    }
    if (watch('isDependent') && !canBookWhenDependent()) {
      return 'Cannot book a session as this dependent user is ineligible to book sessions.';
    }
    if (bookNowButtonEnabled) {
      return 'Click to schedule a session for the client.';
    }

    return 'Client information is necessary to book a session.';
  }, [
    anonymous,
    bookNowButtonEnabled,
    canBookWhenDependent,
    userIsFromUnknownOrg,
    watch,
  ]);

  const FormConfigsValues = useMemo(
    () => ({
      isEditing,
      setIsEditing,
      isConfirmationModalShowing,
      setIsConfirmationModalShowing,
      confirmButtonEnabled,
      updateButtonEnabled,
      bookNowButtonEnabled,
      bookNowButtonTooltipLabel,
    }),
    [
      isEditing,
      setIsEditing,
      isConfirmationModalShowing,
      setIsConfirmationModalShowing,
      confirmButtonEnabled,
      updateButtonEnabled,
      bookNowButtonEnabled,
      bookNowButtonTooltipLabel,
    ],
  );

  return (
    <FormConfigsContext.Provider value={FormConfigsValues}>
      {children}
    </FormConfigsContext.Provider>
  );
};

export default FormConfigsProvider;

// Create a custom hook to use the context easily
export const useFormConfigsContext = () => useContext(FormConfigsContext);
