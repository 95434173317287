import { useClientRisks } from '../../../../utilities/hooks/queryHooks/client/UseClientRisks';
import InfoCard from '../../../components/InfoCard/InfoCard';
import UnOrderedList from '../../../components/UnOrderedList/UnOrderedList';
import { Flex, Typography } from '../../../styledComponents';
import { ClientRiskLevelLabelMapping } from '../../../../shared/constants/Client';
import { getDisplayDateTime } from '../../../../utilities/common/Date';

const ClientRiskAssessmentsCard = ({ userId }: { userId?: string }) => {
  // --------------- NETWORK CALLS ---------------
  const { data: risks } = useClientRisks(userId);

  if (!userId || userId === 'null') return null;

  return (
    <Flex direction="column" gap="2">
      <Typography size="md">Risk</Typography>

      {risks?.length ? (
        risks?.map((note, idx: any) => {
          const key = `user-${userId}-note-${idx}`;

          return (
            <InfoCard
              key={key}
              content={ClientRiskLevelLabelMapping?.[note?.risk] ?? ''}
              subContent={
                <UnOrderedList
                  items={[
                    { label: note?.providerName ?? '' },
                    {
                      label: note?.updatedAt
                        ? getDisplayDateTime(new Date(note?.updatedAt))
                        : '',
                    },
                  ]}
                  liCss={{ maxWidth: '50%' }}
                />
              }
            />
          );
        })
      ) : (
        <InfoCard
          content={
            <Typography size="xs">No risk available right now.</Typography>
          }
        />
      )}
    </Flex>
  );
};

export default ClientRiskAssessmentsCard;
