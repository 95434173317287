export const UserECPRelations = {
  Partner: '0',
  Parent: '1',
  Guardian: '2',
  Sibling: '3',
  Relative: '4',
  Other: '5',
};

export const UserECPRelationsMapping = {
  [UserECPRelations.Partner]: 'Partner',
  [UserECPRelations.Parent]: 'Parent',
  [UserECPRelations.Guardian]: 'Guardian',
  [UserECPRelations.Sibling]: 'Sibling',
  [UserECPRelations.Relative]: 'Relative',
  [UserECPRelations.Other]: 'Other',
};
