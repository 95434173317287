import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../../../shared/constants/QueryKeys';
import { doGet } from '../../../../shared/service/NetworkClient';
import ApiConfig from '../../../../config/ApiConfig';
import { ClientProviderNote } from '../../../../shared/types/Client';

export const useClientNotes = (userId?: string) =>
  useQuery<ClientProviderNote[]>(
    [QueryKeys.getClientNotes, userId],
    () =>
      doGet(
        ApiConfig.api.clients.clientUserNotes.replace(
          '{{userId}}',
          userId || '',
        ),
      ).then((res) => res.data.data),
    { enabled: !!userId && userId !== 'null' },
  );
