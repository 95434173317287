import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';

import { SessionMaxDurationBeforeWarning } from '../../../shared/constants/Session';
import { useTimer } from '../../../utilities/hooks/UseTimer';
import { Heading } from '../../styledComponents';
import { HeadingSizeVariants } from '../../styledComponents/Heading';

const CaseNoteTimer = ({ size = 'lg' }: { size?: HeadingSizeVariants }) => {
  const [timeAlertShown, setTimeAlertShown] = useState<boolean>(false);
  const { hours, minutes, timePassed } = useTimer();

  useEffect(() => {
    if (
      hours * 60 + minutes >= SessionMaxDurationBeforeWarning &&
      !timeAlertShown
    ) {
      toast.error(
        `${SessionMaxDurationBeforeWarning} minutes have passed. You can now mark this session as High risk.`,
        {
          icon: '⚠️',
          duration: 30000,
          position: 'top-center',
          style: {
            minWidth: '50vw',
          },
        },
      );
      setTimeAlertShown(true);
    }
  }, [hours, minutes, timeAlertShown]);

  return (
    <Heading
      size={size}
      css={{
        textAlign: 'end',
        color:
          hours * 60 + minutes >= SessionMaxDurationBeforeWarning
            ? '$darkRed'
            : 'initial',
      }}
    >
      {timePassed}
    </Heading>
  );
};

export default CaseNoteTimer;
