import { Controller, useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { ClientECP } from '../../../../shared/types/Client';
import { noWhiteSpaceRegex } from '../../../../utilities/common/FormValidatorRegex';
import {
  Box,
  Flex,
  Input,
  Label,
  Select,
  Typography,
} from '../../../styledComponents';
import { UserECPRelations } from '../../../../shared/constants/ECP';
import LabelWithAsterik from '../../../components/LabelWithAsterik/LabelWithAsterik';

interface EmergencyContactsInlineFormProps {
  index: number;
}

const EmergencyContactsInlineForm = ({
  index,
}: EmergencyContactsInlineFormProps) => {
  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useFormContext<{
    contacts: ClientECP[];
  }>();

  return (
    <Flex direction="column">
      <Label
        htmlFor="name"
        css={{
          width: '100%',
          marginBottom: 0,
        }}
      >
        <LabelWithAsterik label="Emergency contact name" />
        <Input
          defaultValue={watch(`contacts.${index}.name`)}
          id="name"
          {...register(`contacts.${index}.name`, {
            required: true,
            pattern: {
              value: noWhiteSpaceRegex,
              message: 'No trailing or leading spaces.',
            },
          })}
          helperText={
            errors.contacts?.[index]?.name?.type === 'required'
              ? 'This is required.'
              : errors.contacts?.[index]?.message?.toString()
          }
          css={{ marginBottom: 0 }}
        />
      </Label>
      <Label htmlFor="phone">
        <LabelWithAsterik label="Phone number" />
        <Box
          css={{
            border: '1px solid $gray300',
            borderRadius: '0.5rem',
            mt: '$2',
            mb: '$2',
            backgroundColor: '$gray100',
          }}
        >
          <Controller
            name={`contacts.${index}.contact`}
            control={control}
            rules={{
              required: true,
              minLength: {
                value: 8,
                message: 'Phone number should have a minimum of 8 digits.',
              },
            }}
            render={({ field: { ref, ...rest } }) => (
              <PhoneInput
                placeholder=""
                inputProps={{
                  ref,
                }}
                inputClass="phone-input"
                country="sg"
                {...rest}
                inputStyle={{ height: '44px' }}
              />
            )}
          />
        </Box>
      </Label>
      <Label htmlFor="relation">
        Relationship
        <Controller
          name={`contacts.${index}.relation`}
          defaultValue={watch(`contacts.${index}.relation`)}
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              options={Object.entries(UserECPRelations).map(([label, val]) => ({
                label,
                value: val,
              }))}
              selected={value}
              onChange={onChange}
              {...rest}
              css={{ background: '#fff', height: '44px' }}
            />
          )}
        />
        <Typography color="darkRed" size="xs" css={{ mb: '$2' }}>
          {errors.contacts?.[index]?.message?.toString()}
        </Typography>
      </Label>
    </Flex>
  );
};

export default EmergencyContactsInlineForm;
