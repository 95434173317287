import {
  SessionCallStatus,
  SessionCallTypes,
} from '../../shared/constants/Session';
import { CaseNoteFormTypesV2 } from '../../shared/types/CaseNoteFormV2';

export const shouldShowField = ({
  field,
  callType,
  callStatus,
}: {
  field: keyof CaseNoteFormTypesV2;
  callType?: SessionCallTypes;
  callStatus?: SessionCallStatus;
}) => {
  const isEscalationCall = callType === SessionCallTypes.OUTGOING_CALL;
  const isOutgoingCall = callType === SessionCallTypes.OUTGOING_CALL;
  const isOutboundCallAnswered =
    isEscalationCall || isOutgoingCall
      ? callStatus === SessionCallStatus.ANSWERED
      : true;

  const isCispCall = callType === SessionCallTypes.CISP_CALL;
  const isTestCall = callType === SessionCallTypes.TEST_CALL;
  const isForMultiPurposeCall = isCispCall || isTestCall;

  switch (field) {
    case 'email':
      return !isTestCall;

    case 'risk':
    case 'tenure':
    case 'employeeId':
    case 'age':
    case 'location':
    case 'city':
    case 'landmark':
    case 'callReason':
    case 'callDescription':
    case 'rangerIntervention':
    case 'issue':
    case 'secondaryIssue':
      return !isForMultiPurposeCall && isOutboundCallAnswered;

    case 'escalationCallType':
      return isOutboundCallAnswered;

    case 'outgoingCallType':
      return isOutboundCallAnswered;

    case 'clientPosition':
    case 'cispCallType':
      return isCispCall;

    case 'testCallType':
      return isTestCall;

    default:
      return true;
  }
};
