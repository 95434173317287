import { useGetSessionsListV2 } from '../../../../utilities/hooks/queryHooks/callLogs/useGetSessionsListV2';
import { Flex, Typography } from '../../../styledComponents';
import CallLogInfoCard from './CallLogInfoCard';

const ClientCallLogsCard = ({ phone }: { phone: string }) => {
  const { data: sessions } = useGetSessionsListV2({
    pageSize: 5,
    phone,
  });

  return (
    <Flex direction="column" gap="2">
      <Typography size="md">Call logs</Typography>
      {sessions?.sessions?.map((session, idx) => {
        const key = `call-logs-${phone}-${idx}`;

        return <CallLogInfoCard key={key} session={session} />;
      })}
    </Flex>
  );
};

export default ClientCallLogsCard;
