import { CSS } from '@stitches/react';
import { Flex, Typography } from '../../styledComponents';

interface InfoCardProps {
  content: string | JSX.Element;
  subContent?: string | JSX.Element;
  css?: CSS;
  clickable?: boolean;
  onClick?: () => void;
}

const InfoCard = ({
  content,
  subContent,
  css,
  clickable = false,
  onClick,
}: InfoCardProps) => (
  <Flex
    direction="column"
    gap="1"
    css={{
      backgroundColor: '$gray75',
      br: '$2',
      p: '$2',
      border: '1px solid $gray200',
      color: '$gray600',
      fontSize: '$2',
      ...(clickable && {
        '@hover': {
          '&:hover': {
            filter: 'brightness(95%)',
            cursor: 'pointer',
          },
        },
      }),
      ...(css ?? {}),
    }}
    role="button"
    tabIndex={0}
    onClick={() => clickable && onClick?.()}
  >
    {content}

    {subContent ? (
      <Typography size="xs" color="gray">
        {subContent}
      </Typography>
    ) : null}
  </Flex>
);

export default InfoCard;
